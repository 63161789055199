/**======================================================================
Template Name: Wedash Bootstrap 5 Admin Template
Author: Techneinfosys
Support: [support link]
File: style.css
=================================================================================== */
/*!
   * Bootstrap  v5.2.3 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
:root {
  --bs-blue: #51459d;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #ff3a6e;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffa21d;
  --bs-green: #6fd943;
  --bs-teal: #20c997;
  --bs-cyan: #3ec9d6;
  --bs-white: #ffffff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #51459d;
  --bs-secondary: #6c757d;
  --bs-success: #6fd943;
  --bs-info: #3ec9d6;
  --bs-warning: #ffa21d;
  --bs-danger: #ff3a6e;
  --bs-light: #f8f9fa;
  --bs-dark: #1c232f;
  --bs-primary-rgb: 81, 69, 157;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 111, 217, 67;
  --bs-info-rgb: 62, 201, 214;
  --bs-warning-rgb: 255, 162, 29;
  --bs-danger-rgb: 255, 58, 110;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 28, 35, 47;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 128, 129, 145;
  --bs-body-bg-rgb: 34, 36, 44;
  --bs-font-sans-serif: "Open Sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #808191;
  --bs-body-bg: #22242c;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #3E3F4A;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 10px;
  --bs-border-radius-sm: 8px;
  --bs-border-radius-lg: 15px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #51459d;
  --bs-link-hover-color: #51459d;
  --bs-code-color: #e83e8c;
  --bs-highlight-bg: #fcf8e3; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: #3E3F4A;
  border: 0;
  border-top: 1px solid;
  opacity: 1; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #ffffff; }

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.25rem; } }

h2, .h2 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.75rem; } }

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.5rem; } }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.9375rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 80%; }

mark, .mark {
  padding: 0.2em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: none; }
  a:hover {
    color: var(--bs-link-hover-color);
    text-decoration: underline; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 87.5%;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 8px; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 80%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #22242c;
  border: 1px solid #3E3F4A;
  border-radius: 10px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color: #808191;
  --bs-table-bg: transparent;
  --bs-table-border-color: #3E3F4A;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #808191;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #808191;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #808191;
  --bs-table-hover-bg: rgba(81, 69, 157, 0.02);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.9rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: 2px solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.3rem 0.3rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #212529;
  --bs-table-bg: #dcdaeb;
  --bs-table-border-color: #c9c8d8;
  --bs-table-striped-bg: #d3d1e1;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #c9c8d8;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #d8d6e7;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #212529;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cfd0d2;
  --bs-table-striped-bg: #d8dadc;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #cfd0d2;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #dedfe1;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #212529;
  --bs-table-bg: #e2f7d9;
  --bs-table-border-color: #cfe2c7;
  --bs-table-striped-bg: #d8edd0;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #cfe2c7;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #def3d5;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #212529;
  --bs-table-bg: #d8f4f7;
  --bs-table-border-color: #c6dfe2;
  --bs-table-striped-bg: #cfeaed;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #c6dfe2;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #d4f0f3;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #212529;
  --bs-table-bg: #ffecd2;
  --bs-table-border-color: #e9d8c1;
  --bs-table-striped-bg: #f4e2ca;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e9d8c1;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #fbe8cf;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #212529;
  --bs-table-bg: #ffd8e2;
  --bs-table-border-color: #e9c6d0;
  --bs-table-striped-bg: #f4cfd9;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e9c6d0;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #fbd4de;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #212529;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #e3e4e5;
  --bs-table-striped-bg: #edeef0;
  --bs-table-striped-color: #212529;
  --bs-table-active-bg: #e3e4e5;
  --bs-table-active-color: #212529;
  --bs-table-hover-bg: #f4f5f6;
  --bs-table-hover-color: #212529;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #1c232f;
  --bs-table-border-color: #333944;
  --bs-table-striped-bg: #272e39;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #333944;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #212733;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.575rem + 2px);
  padding-bottom: calc(0.575rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.09375rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.76563rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #6c757d; }

.form-control, .custom-select,
.dataTable-selector,
.dataTable-input {
  display: block;
  width: 100%;
  padding: 0.575rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #808191;
  background-color: #22242c;
  background-clip: padding-box;
  border: 2px solid #3E3F4A;
  appearance: none;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .custom-select,
    .dataTable-selector,
    .dataTable-input {
      transition: none; } }
  .form-control[type="file"], .custom-select[type="file"],
  .dataTable-selector[type="file"],
  .dataTable-input[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]), .custom-select[type="file"]:not(:disabled):not([readonly]),
    .dataTable-selector[type="file"]:not(:disabled):not([readonly]),
    .dataTable-input[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus, .custom-select:focus,
  .dataTable-selector:focus,
  .dataTable-input:focus {
    color: #808191;
    background-color: #22242c;
    border-color: #51459d;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(81, 69, 157, 0.25); }
  .form-control::-webkit-date-and-time-value, .custom-select::-webkit-date-and-time-value,
  .dataTable-selector::-webkit-date-and-time-value,
  .dataTable-input::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder, .custom-select::placeholder,
  .dataTable-selector::placeholder,
  .dataTable-input::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .custom-select:disabled,
  .dataTable-selector:disabled,
  .dataTable-input:disabled {
    background-color: #2b2d38;
    opacity: 1; }
  .form-control::file-selector-button, .custom-select::file-selector-button,
  .dataTable-selector::file-selector-button,
  .dataTable-input::file-selector-button {
    padding: 0.575rem 1rem;
    margin: -0.575rem -1rem;
    margin-inline-end: 1rem;
    color: #808191;
    background-color: #22242c;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 2px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button, .custom-select::file-selector-button,
      .dataTable-selector::file-selector-button,
      .dataTable-input::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button, .custom-select:hover:not(:disabled):not([readonly])::file-selector-button,
  .dataTable-selector:hover:not(:disabled):not([readonly])::file-selector-button,
  .dataTable-input:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #20222a; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.575rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #808191;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 4px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 4px; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 8px; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control, textarea.custom-select,
textarea.dataTable-selector,
textarea.dataTable-input {
  min-height: calc(1.5em + 1.15rem + 4px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 4px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 4px); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.15rem + 4px);
  padding: 0.575rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 6px; }
  .form-control-color::-webkit-color-swatch {
    border-radius: 6px; }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + 4px); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + 4px); }

.form-select {
  display: block;
  width: 100%;
  padding: 0.575rem 1rem 0.575rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #808191;
  background-color: #22242c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 2px solid #3E3F4A;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #51459d;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(81, 69, 157, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 1rem;
    background-image: none; }
  .form-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #808191; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
  border-radius: 4px; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: 8px; }

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.75em; }

.form-check-reverse {
  padding-right: 1.75em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.75em;
    margin-left: 0; }

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.125em;
  vertical-align: top;
  background-color: #22242c;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-check-input {
      transition: none; } }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #51459d;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(81, 69, 157, 0.25); }
  .form-check-input:checked {
    background-color: #51459d;
    border-color: #51459d; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #51459d;
    border-color: #51459d;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2351459d'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #22242c, 0 0 0 0.2rem rgba(81, 69, 157, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #22242c, 0 0 0 0.2rem rgba(81, 69, 157, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #51459d;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #bbb6df; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #22242c;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #51459d;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #bbb6df; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #22242c;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control, .form-floating > .custom-select,
  .form-floating > .dataTable-selector,
  .form-floating > .dataTable-input,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.6rem + 4px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 1rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 2px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control, .form-floating > .custom-select,
  .form-floating > .dataTable-selector,
  .form-floating > .dataTable-input,
  .form-floating > .form-control-plaintext {
    padding: 1rem 1rem; }
    .form-floating > .form-control::placeholder, .form-floating > .custom-select::placeholder,
    .form-floating > .dataTable-selector::placeholder,
    .form-floating > .dataTable-input::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .custom-select:focus,
    .form-floating > .dataTable-selector:focus,
    .form-floating > .dataTable-input:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .custom-select:not(:placeholder-shown),
    .form-floating > .dataTable-selector:not(:placeholder-shown),
    .form-floating > .dataTable-input:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill, .form-floating > .custom-select:-webkit-autofill,
    .form-floating > .dataTable-selector:-webkit-autofill,
    .form-floating > .dataTable-input:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label, .form-floating > .custom-select:focus ~ label, .form-floating > .dataTable-selector:focus ~ label, .form-floating > .dataTable-input:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .custom-select:not(:placeholder-shown) ~ label,
  .form-floating > .dataTable-selector:not(:placeholder-shown) ~ label,
  .form-floating > .dataTable-input:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label, .form-floating > .custom-select:-webkit-autofill ~ label, .form-floating > .dataTable-selector:-webkit-autofill ~ label, .form-floating > .dataTable-input:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 2px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .input-group > .custom-select,
  .input-group > .dataTable-selector,
  .input-group > .dataTable-input,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus, .input-group > .custom-select:focus,
  .input-group > .dataTable-selector:focus,
  .input-group > .dataTable-input:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.575rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #808191;
  text-align: center;
  white-space: nowrap;
  background-color: #22242c;
  border: 2px solid #3E3F4A;
  border-radius: 6px; }

.input-group-lg > .form-control, .input-group-lg > .custom-select,
.input-group-lg > .dataTable-selector,
.input-group-lg > .dataTable-input,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 8px; }

.input-group-sm > .form-control, .input-group-sm > .custom-select,
.input-group-sm > .dataTable-selector,
.input-group-sm > .dataTable-input,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 4px; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .custom-select,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .dataTable-selector,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .dataTable-input,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .custom-select,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .dataTable-selector,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .dataTable-input,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .custom-select,
.input-group > .form-floating:not(:first-child) > .dataTable-selector,
.input-group > .form-floating:not(:first-child) > .dataTable-input,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #6fd943; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(111, 217, 67, 0.9);
  border-radius: 10px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .custom-select:valid,
.was-validated .dataTable-selector:valid,
.was-validated .dataTable-input:valid, .form-control.is-valid, .is-valid.custom-select,
.is-valid.dataTable-selector,
.is-valid.dataTable-input {
  border-color: #6fd943;
  padding-right: calc(1.5em + 1.15rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%236fd943' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2875rem) center;
  background-size: calc(0.75em + 0.575rem) calc(0.75em + 0.575rem); }
  .was-validated .form-control:valid:focus, .was-validated .custom-select:valid:focus,
  .was-validated .dataTable-selector:valid:focus,
  .was-validated .dataTable-input:valid:focus, .form-control.is-valid:focus, .is-valid.custom-select:focus,
  .is-valid.dataTable-selector:focus,
  .is-valid.dataTable-input:focus {
    border-color: #6fd943;
    box-shadow: 0 0 0 0.2rem rgba(111, 217, 67, 0.25); }

.was-validated textarea.form-control:valid, .was-validated textarea.custom-select:valid,
.was-validated textarea.dataTable-selector:valid,
.was-validated textarea.dataTable-input:valid, textarea.form-control.is-valid, textarea.is-valid.custom-select,
textarea.is-valid.dataTable-selector,
textarea.is-valid.dataTable-input {
  padding-right: calc(1.5em + 1.15rem);
  background-position: top calc(0.375em + 0.2875rem) right calc(0.375em + 0.2875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #6fd943; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: calc(0.75em + 2.8625rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%236fd943' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 1rem center, center right 2rem;
    background-size: 16px 12px, calc(0.75em + 0.575rem) calc(0.75em + 0.575rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #6fd943;
    box-shadow: 0 0 0 0.2rem rgba(111, 217, 67, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.15rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #6fd943; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #6fd943; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 217, 67, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #6fd943; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .was-validated .input-group > .custom-select:not(:focus):valid,
.was-validated .input-group > .dataTable-selector:not(:focus):valid,
.was-validated .input-group > .dataTable-input:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .input-group > .custom-select:not(:focus).is-valid,
.input-group > .dataTable-selector:not(:focus).is-valid,
.input-group > .dataTable-input:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff3a6e; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(255, 58, 110, 0.9);
  border-radius: 10px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .custom-select:invalid,
.was-validated .dataTable-selector:invalid,
.was-validated .dataTable-input:invalid, .form-control.is-invalid, .is-invalid.custom-select,
.is-invalid.dataTable-selector,
.is-invalid.dataTable-input {
  border-color: #ff3a6e;
  padding-right: calc(1.5em + 1.15rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff3a6e' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff3a6e' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2875rem) center;
  background-size: calc(0.75em + 0.575rem) calc(0.75em + 0.575rem); }
  .was-validated .form-control:invalid:focus, .was-validated .custom-select:invalid:focus,
  .was-validated .dataTable-selector:invalid:focus,
  .was-validated .dataTable-input:invalid:focus, .form-control.is-invalid:focus, .is-invalid.custom-select:focus,
  .is-invalid.dataTable-selector:focus,
  .is-invalid.dataTable-input:focus {
    border-color: #ff3a6e;
    box-shadow: 0 0 0 0.2rem rgba(255, 58, 110, 0.25); }

.was-validated textarea.form-control:invalid, .was-validated textarea.custom-select:invalid,
.was-validated textarea.dataTable-selector:invalid,
.was-validated textarea.dataTable-input:invalid, textarea.form-control.is-invalid, textarea.is-invalid.custom-select,
textarea.is-invalid.dataTable-selector,
textarea.is-invalid.dataTable-input {
  padding-right: calc(1.5em + 1.15rem);
  background-position: top calc(0.375em + 0.2875rem) right calc(0.375em + 0.2875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ff3a6e; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: calc(0.75em + 2.8625rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff3a6e' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff3a6e' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-position: right 1rem center, center right 2rem;
    background-size: 16px 12px, calc(0.75em + 0.575rem) calc(0.75em + 0.575rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #ff3a6e;
    box-shadow: 0 0 0 0.2rem rgba(255, 58, 110, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.15rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ff3a6e; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #ff3a6e; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 58, 110, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #ff3a6e; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .input-group > .custom-select:not(:focus):invalid,
.was-validated .input-group > .dataTable-selector:not(:focus):invalid,
.was-validated .input-group > .dataTable-input:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .input-group > .custom-select:not(:focus).is-invalid,
.input-group > .dataTable-selector:not(:focus).is-invalid,
.input-group > .dataTable-input:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 1.3rem;
  --bs-btn-padding-y: 0.575rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #808191;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 2px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 6px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    text-decoration: none;
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #51459d;
  --bs-btn-border-color: #51459d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #453b85;
  --bs-btn-hover-border-color: #41377e;
  --bs-btn-focus-shadow-rgb: 107, 97, 172;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #41377e;
  --bs-btn-active-border-color: #3d3476;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #51459d;
  --bs-btn-disabled-border-color: #51459d; }

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d; }

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6fd943;
  --bs-btn-border-color: #6fd943;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5eb839;
  --bs-btn-hover-border-color: #59ae36;
  --bs-btn-focus-shadow-rgb: 133, 223, 95;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #59ae36;
  --bs-btn-active-border-color: #53a332;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6fd943;
  --bs-btn-disabled-border-color: #6fd943; }

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3ec9d6;
  --bs-btn-border-color: #3ec9d6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #35abb6;
  --bs-btn-hover-border-color: #32a1ab;
  --bs-btn-focus-shadow-rgb: 91, 209, 220;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #32a1ab;
  --bs-btn-active-border-color: #2f97a1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3ec9d6;
  --bs-btn-disabled-border-color: #3ec9d6; }

.btn-warning {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ffa21d;
  --bs-btn-border-color: #ffa21d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d98a19;
  --bs-btn-hover-border-color: #cc8217;
  --bs-btn-focus-shadow-rgb: 255, 176, 63;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cc8217;
  --bs-btn-active-border-color: #bf7a16;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ffa21d;
  --bs-btn-disabled-border-color: #ffa21d; }

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ff3a6e;
  --bs-btn-border-color: #ff3a6e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d9315e;
  --bs-btn-hover-border-color: #cc2e58;
  --bs-btn-focus-shadow-rgb: 255, 88, 132;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cc2e58;
  --bs-btn-active-border-color: #bf2c53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ff3a6e;
  --bs-btn-disabled-border-color: #ff3a6e; }

.btn-light {
  --bs-btn-color: #212529;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #212529;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 216, 217, 219;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa; }

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1c232f;
  --bs-btn-border-color: #1c232f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3e444e;
  --bs-btn-hover-border-color: #333944;
  --bs-btn-focus-shadow-rgb: 62, 68, 78;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #494f59;
  --bs-btn-active-border-color: #333944;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1c232f;
  --bs-btn-disabled-border-color: #1c232f; }

.btn-outline-primary {
  --bs-btn-color: #51459d;
  --bs-btn-border-color: #51459d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #51459d;
  --bs-btn-hover-border-color: #51459d;
  --bs-btn-focus-shadow-rgb: 81, 69, 157;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #51459d;
  --bs-btn-active-border-color: #51459d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #51459d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #51459d;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #6fd943;
  --bs-btn-border-color: #6fd943;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #6fd943;
  --bs-btn-hover-border-color: #6fd943;
  --bs-btn-focus-shadow-rgb: 111, 217, 67;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #6fd943;
  --bs-btn-active-border-color: #6fd943;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6fd943;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6fd943;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #3ec9d6;
  --bs-btn-border-color: #3ec9d6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3ec9d6;
  --bs-btn-hover-border-color: #3ec9d6;
  --bs-btn-focus-shadow-rgb: 62, 201, 214;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3ec9d6;
  --bs-btn-active-border-color: #3ec9d6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3ec9d6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3ec9d6;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ffa21d;
  --bs-btn-border-color: #ffa21d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ffa21d;
  --bs-btn-hover-border-color: #ffa21d;
  --bs-btn-focus-shadow-rgb: 255, 162, 29;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ffa21d;
  --bs-btn-active-border-color: #ffa21d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffa21d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffa21d;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #ff3a6e;
  --bs-btn-border-color: #ff3a6e;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ff3a6e;
  --bs-btn-hover-border-color: #ff3a6e;
  --bs-btn-focus-shadow-rgb: 255, 58, 110;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ff3a6e;
  --bs-btn-active-border-color: #ff3a6e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ff3a6e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff3a6e;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #212529;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #212529;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #1c232f;
  --bs-btn-border-color: #1c232f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1c232f;
  --bs-btn-hover-border-color: #1c232f;
  --bs-btn-focus-shadow-rgb: 28, 35, 47;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1c232f;
  --bs-btn-active-border-color: #1c232f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1c232f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1c232f;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #51459d;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #51459d;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #51459d;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 107, 97, 172;
  text-decoration: none; }
  .btn-link:hover, .btn-link:focus-visible {
    text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: 8px; }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.76563rem;
  --bs-btn-border-radius: 4px; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 12rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: #808191;
  --bs-dropdown-bg: #262f3f;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-border-radius: 10px;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: 10px;
  --bs-dropdown-divider-bg: #3E3F4A;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  --bs-dropdown-link-color: #808191;
  --bs-dropdown-link-hover-color: #51459d;
  --bs-dropdown-link-hover-bg: #202835;
  --bs-dropdown-link-active-color: #51459d;
  --bs-dropdown-link-active-bg: #202835;
  --bs-dropdown-link-disabled-color: #6c757d;
  --bs-dropdown-item-padding-x: 1.2rem;
  --bs-dropdown-item-padding-y: 0.325rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: #3E3F4A;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #51459d;
  --bs-dropdown-link-active-bg: #202835;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: 6px; }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -2px; }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.975rem;
  padding-left: 0.975rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -2px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color);
    text-decoration: none; }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #3E3F4A;
  --bs-nav-tabs-border-radius: 10px;
  --bs-nav-tabs-link-hover-border-color: #3E3F4A;
  --bs-nav-tabs-link-active-color: #808191;
  --bs-nav-tabs-link-active-bg: #3E3F4A;
  --bs-nav-tabs-link-active-border-color: #3E3F4A;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: 10px;
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #51459d; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.33594rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 6px;
  --bs-navbar-toggler-focus-width: 0.2rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color);
    text-decoration: none; }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 25px;
  --bs-card-spacer-x: 25px;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0px;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 10px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(10px - 0px);
  --bs-card-cap-padding-y: 25px;
  --bs-card-cap-padding-x: 25px;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #292A33;
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: #808191;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: rgba(0, 0, 0, 0.125);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 10px;
  --bs-accordion-inner-border-radius: 9px;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #808191;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23808191'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23493e8d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #51459d;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(81, 69, 157, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #493e8d;
  --bs-accordion-active-bg: #eeecf5; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%236c757d' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E")) /* rtl: var(--bs-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%236c757d' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E")) */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #51459d;
  --bs-pagination-bg: #1c232f;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #3E3F4A;
  --bs-pagination-border-radius: 10px;
  --bs-pagination-hover-color: #51459d;
  --bs-pagination-hover-bg: #1c232f;
  --bs-pagination-hover-border-color: #3E3F4A;
  --bs-pagination-focus-color: #51459d;
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(81, 69, 157, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #51459d;
  --bs-pagination-active-border-color: #51459d;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #1c232f;
  --bs-pagination-disabled-border-color: #3E3F4A;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    text-decoration: none;
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: 15px; }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.76563rem;
  --bs-pagination-border-radius: 8px; }

.badge {
  --bs-badge-padding-x: 0.5em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 2px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: 0.75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 10px;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.75rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.9375rem 1.25rem; }

.alert-primary {
  --bs-alert-color: #31295e;
  --bs-alert-bg: #dcdaeb;
  --bs-alert-border-color: #cbc7e2; }
  .alert-primary .alert-link {
    color: #27214b; }

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8; }
  .alert-secondary .alert-link {
    color: #34383c; }

.alert-success {
  --bs-alert-color: #438228;
  --bs-alert-bg: #e2f7d9;
  --bs-alert-border-color: #d4f4c7; }
  .alert-success .alert-link {
    color: #366820; }

.alert-info {
  --bs-alert-color: #257980;
  --bs-alert-bg: #d8f4f7;
  --bs-alert-border-color: #c5eff3; }
  .alert-info .alert-link {
    color: #1e6166; }

.alert-warning {
  --bs-alert-color: #996111;
  --bs-alert-bg: #ffecd2;
  --bs-alert-border-color: #ffe3bb; }
  .alert-warning .alert-link {
    color: #7a4e0e; }

.alert-danger {
  --bs-alert-color: #992342;
  --bs-alert-bg: #ffd8e2;
  --bs-alert-border-color: #ffc4d4; }
  .alert-danger .alert-link {
    color: #7a1c35; }

.alert-light {
  --bs-alert-color: #959596;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #777778; }

.alert-dark {
  --bs-alert-color: #11151c;
  --bs-alert-bg: #d2d3d5;
  --bs-alert-border-color: #bbbdc1; }
  .alert-dark .alert-link {
    color: #0e1116; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: #22242c;
  --bs-progress-border-radius: 10px;
  --bs-progress-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #51459d;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: #808191;
  --bs-list-group-bg: #292A33;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 10px;
  --bs-list-group-item-padding-x: 25px;
  --bs-list-group-item-padding-y: 16.66667px;
  --bs-list-group-action-color: #808191;
  --bs-list-group-action-hover-color: #808191;
  --bs-list-group-action-hover-bg: #22232b;
  --bs-list-group-action-active-color: #808191;
  --bs-list-group-action-active-bg: #22232b;
  --bs-list-group-disabled-color: #808191;
  --bs-list-group-disabled-bg: #292A33;
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #22232b;
  --bs-list-group-active-border-color: #22232b;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #31295e;
  background-color: #dcdaeb; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #31295e;
    background-color: #c6c4d4; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #31295e;
    border-color: #31295e; }

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #41464b;
    background-color: #cbccce; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #41464b;
    border-color: #41464b; }

.list-group-item-success {
  color: #438228;
  background-color: #e2f7d9; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #438228;
    background-color: #cbdec3; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #438228;
    border-color: #438228; }

.list-group-item-info {
  color: #257980;
  background-color: #d8f4f7; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #257980;
    background-color: #c2dcde; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #257980;
    border-color: #257980; }

.list-group-item-warning {
  color: #996111;
  background-color: #ffecd2; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #996111;
    background-color: #e6d4bd; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #996111;
    border-color: #996111; }

.list-group-item-danger {
  color: #992342;
  background-color: #ffd8e2; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #992342;
    background-color: #e6c2cb; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #992342;
    border-color: #992342; }

.list-group-item-light {
  color: #959596;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #959596;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #959596;
    border-color: #959596; }

.list-group-item-dark {
  color: #11151c;
  background-color: #d2d3d5; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #11151c;
    background-color: #bdbec0; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #11151c;
    border-color: #11151c; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #ffffff;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 10px;
  opacity: 0.5; }
  .btn-close:hover {
    color: #ffffff;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(81, 69, 157, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.25rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: rgba(0, 0, 0, 0.1);
  --bs-toast-border-radius: 0.25rem;
  --bs-toast-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.25rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #292A33;
  --bs-modal-border-color: rgba(0, 0, 0, 0.2);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 15px;
  --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  --bs-modal-inner-border-radius: 14px;
  --bs-modal-header-padding-x: 1.5625rem;
  --bs-modal-header-padding-y: 1.25rem;
  --bs-modal-header-padding: 1.25rem 1.5625rem;
  --bs-modal-header-border-color: #3E3F4A;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #3E3F4A;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: 0.76563rem;
  --bs-tooltip-color: #ffffff;
  --bs-tooltip-bg: #000000;
  --bs-tooltip-border-radius: 10px;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.76563rem;
  --bs-popover-bg: #ffffff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: rgba(0, 0, 0, 0.2);
  --bs-popover-border-radius: 15px;
  --bs-popover-inner-border-radius: 14px;
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 0.75rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #ffffff;
  --bs-popover-header-bg: #f7f7f7;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #808191;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000; }

.carousel-dark .carousel-caption {
  color: #000000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 360px;
  --bs-offcanvas-height: 33vh;
  --bs-offcanvas-padding-x: 1.25rem;
  --bs-offcanvas-padding-y: 1.25rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #292A33;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --bs-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5); }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(81, 69, 157, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #ffffff !important;
  background-color: RGBA(111, 217, 67, var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #ffffff !important;
  background-color: RGBA(62, 201, 214, var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #ffffff !important;
  background-color: RGBA(255, 162, 29, var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(255, 58, 110, var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #212529 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(28, 35, 47, var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: #51459d !important; }
  .link-primary:hover, .link-primary:focus {
    color: #41377e !important; }

.link-secondary {
  color: #6c757d !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #565e64 !important; }

.link-success {
  color: #6fd943 !important; }
  .link-success:hover, .link-success:focus {
    color: #59ae36 !important; }

.link-info {
  color: #3ec9d6 !important; }
  .link-info:hover, .link-info:focus {
    color: #32a1ab !important; }

.link-warning {
  color: #ffa21d !important; }
  .link-warning:hover, .link-warning:focus {
    color: #cc8217 !important; }

.link-danger {
  color: #ff3a6e !important; }
  .link-danger:hover, .link-danger:focus {
    color: #cc2e58 !important; }

.link-light {
  color: #f8f9fa !important; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb !important; }

.link-dark {
  color: #1c232f !important; }
  .link-dark:hover, .link-dark:focus {
    color: #161c26 !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-body {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-body-rgb), var(--bs-border-opacity)) !important; }

.border-0 {
  --bs-border-width: 0; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -3rem !important; }

.me-n1 {
  margin-right: -0.25rem !important; }

.me-n2 {
  margin-right: -0.5rem !important; }

.me-n3 {
  margin-right: -1rem !important; }

.me-n4 {
  margin-right: -1.5rem !important; }

.me-n5 {
  margin-right: -3rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -3rem !important; }

.ms-n1 {
  margin-left: -0.25rem !important; }

.ms-n2 {
  margin-left: -0.5rem !important; }

.ms-n3 {
  margin-left: -1rem !important; }

.ms-n4 {
  margin-left: -1.5rem !important; }

.ms-n5 {
  margin-left: -3rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-4 {
  font-size: 1.25rem !important; }

.fs-5 {
  font-size: 1rem !important; }

.fs-6 {
  font-size: 0.9375rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -3rem !important; }
  .me-sm-n1 {
    margin-right: -0.25rem !important; }
  .me-sm-n2 {
    margin-right: -0.5rem !important; }
  .me-sm-n3 {
    margin-right: -1rem !important; }
  .me-sm-n4 {
    margin-right: -1.5rem !important; }
  .me-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -3rem !important; }
  .ms-sm-n1 {
    margin-left: -0.25rem !important; }
  .ms-sm-n2 {
    margin-left: -0.5rem !important; }
  .ms-sm-n3 {
    margin-left: -1rem !important; }
  .ms-sm-n4 {
    margin-left: -1.5rem !important; }
  .ms-sm-n5 {
    margin-left: -3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -3rem !important; }
  .me-md-n1 {
    margin-right: -0.25rem !important; }
  .me-md-n2 {
    margin-right: -0.5rem !important; }
  .me-md-n3 {
    margin-right: -1rem !important; }
  .me-md-n4 {
    margin-right: -1.5rem !important; }
  .me-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -3rem !important; }
  .ms-md-n1 {
    margin-left: -0.25rem !important; }
  .ms-md-n2 {
    margin-left: -0.5rem !important; }
  .ms-md-n3 {
    margin-left: -1rem !important; }
  .ms-md-n4 {
    margin-left: -1.5rem !important; }
  .ms-md-n5 {
    margin-left: -3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -3rem !important; }
  .me-lg-n1 {
    margin-right: -0.25rem !important; }
  .me-lg-n2 {
    margin-right: -0.5rem !important; }
  .me-lg-n3 {
    margin-right: -1rem !important; }
  .me-lg-n4 {
    margin-right: -1.5rem !important; }
  .me-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -3rem !important; }
  .ms-lg-n1 {
    margin-left: -0.25rem !important; }
  .ms-lg-n2 {
    margin-left: -0.5rem !important; }
  .ms-lg-n3 {
    margin-left: -1rem !important; }
  .ms-lg-n4 {
    margin-left: -1.5rem !important; }
  .ms-lg-n5 {
    margin-left: -3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -3rem !important; }
  .me-xl-n1 {
    margin-right: -0.25rem !important; }
  .me-xl-n2 {
    margin-right: -0.5rem !important; }
  .me-xl-n3 {
    margin-right: -1rem !important; }
  .me-xl-n4 {
    margin-right: -1.5rem !important; }
  .me-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -3rem !important; }
  .ms-xl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xl-n3 {
    margin-left: -1rem !important; }
  .ms-xl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xl-n5 {
    margin-left: -3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xxl-n3 {
    margin-top: -1rem !important; }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xxl-n5 {
    margin-top: -3rem !important; }
  .me-xxl-n1 {
    margin-right: -0.25rem !important; }
  .me-xxl-n2 {
    margin-right: -0.5rem !important; }
  .me-xxl-n3 {
    margin-right: -1rem !important; }
  .me-xxl-n4 {
    margin-right: -1.5rem !important; }
  .me-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xxl-n3 {
    margin-left: -1rem !important; }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xxl-n5 {
    margin-left: -3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important; }
  .fs-2 {
    font-size: 1.75rem !important; }
  .fs-3 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/**  =====================
      Custom css start
==========================  **/
body {
  min-height: 100vh;
  overflow-x: hidden; }

*:focus {
  outline: none; }

a:hover {
  outline: none;
  text-decoration: none; }

a:not([href]) {
  color: inherit; }

p {
  font-size: 14px; }

b,
strong {
  font-weight: 600; }

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0; }
  .ie-warning p {
    font-size: 17px; }
  .ie-warning h1, .ie-warning .h1 {
    color: #fff; }
  .ie-warning .iew-container {
    min-width: 1024px;
    width: 100%;
    height: 200px;
    background: #fff;
    margin: 50px 0; }
  .ie-warning .iew-download {
    list-style: none;
    padding: 30px 0;
    margin: 0 auto;
    width: 720px; }
    .ie-warning .iew-download > li {
      float: left;
      vertical-align: top; }
      .ie-warning .iew-download > li > a {
        display: block;
        color: #000;
        width: 140px;
        font-size: 15px;
        padding: 15px 0; }
        .ie-warning .iew-download > li > a > div {
          margin-top: 10px; }
        .ie-warning .iew-download > li > a:hover {
          background-color: #eee; }

.btn-page .btn-group .btn {
  margin-right: 0;
  margin-bottom: 0; }

.btn-page .btn {
  margin-right: 5px;
  margin-bottom: 5px; }

.material-icons-two-tone {
  background-color: #808191;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  vertical-align: text-bottom; }
  .material-icons-two-tone.text-white {
    background-color: #fff; }

/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/
.bd-example-row {
  background: #22242c;
  padding: 15px;
  margin-bottom: 15px; }
  .bd-example-row .row > [class^="col-"],
  .bd-example-row .row > .col {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: rgba(86, 61, 124, 0.15);
    border: 1px solid rgba(86, 61, 124, 0.2); }
  .bd-example-row .row + .row {
    margin-top: 1rem; }
  .bd-example-row .flex-items-bottom,
  .bd-example-row .flex-items-middle,
  .bd-example-row .flex-items-top {
    min-height: 6rem;
    background-color: rgba(255, 0, 0, 0.1); }

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1); }

/* Docs examples */
.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0; }
  @media only screen and (max-height: 575px) {
    .bd-example {
      padding: 1.5rem;
      margin-right: 0;
      margin-left: 0;
      border-width: 0.2rem; } }

/* Example modals */
.modal {
  z-index: 1072; }
  .modal .popover,
  .modal .tooltip {
    z-index: 1073; }

.modal-backdrop {
  z-index: 1071; }

.bd-example-modal {
  background-color: #22242c; }
  .bd-example-modal .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block; }
  .bd-example-modal .modal-dialog {
    left: auto;
    margin-right: auto;
    margin-left: auto; }
  .bd-example-modal .offcanvas {
    position: static;
    display: block;
    height: 200px;
    visibility: visible;
    transform: translate(0); }

/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem; }

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px; }
  @media only screen and (max-height: 575px) {
    .bd-content .highlight {
      margin-right: 0;
      margin-left: 0; } }

body.theme-1 {
  background: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%); }
  body.theme-1 .bg-primary {
    background: linear-gradient(141.55deg, #7928ca 3.46%, #ff0080 99.86%), #7928ca !important; }
  body.theme-1 .site-header .drp-language .drp-text,
  body.theme-1 .site-header .dash-head-link > i:not(.nocolor) {
    color: #7928ca; }
  body.theme-1 .site-header .drp-company .theme-avtar {
    background: #e4d4f4;
    color: #7928ca; }
  body.theme-1 .app-sidebar.app-light-sidebar .app-navbar > .nav-item.active > .nav-link, body.theme-1 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:active > .nav-link, body.theme-1 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:focus > .nav-link, body.theme-1 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:hover > .nav-link,
  body.theme-1 .app-sidebar .app-navbar > .nav-item.active > .nav-link,
  body.theme-1 .app-sidebar .app-navbar > .nav-item:active > .nav-link,
  body.theme-1 .app-sidebar .app-navbar > .nav-item:focus > .nav-link,
  body.theme-1 .app-sidebar .app-navbar > .nav-item:hover > .nav-link {
    background: linear-gradient(141.55deg, #7928ca 3.46%, #ff0080 99.86%), #7928ca;
    color: #fff; }
    body.theme-1 .app-sidebar.app-light-sidebar .app-navbar > .nav-item.active > .nav-link i, body.theme-1 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:active > .nav-link i, body.theme-1 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:focus > .nav-link i, body.theme-1 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:hover > .nav-link i,
    body.theme-1 .app-sidebar .app-navbar > .nav-item.active > .nav-link i,
    body.theme-1 .app-sidebar .app-navbar > .nav-item:active > .nav-link i,
    body.theme-1 .app-sidebar .app-navbar > .nav-item:focus > .nav-link i,
    body.theme-1 .app-sidebar .app-navbar > .nav-item:hover > .nav-link i {
      color: #fff; }
  body.theme-1 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item.active > .nav-link, body.theme-1 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:active > .nav-link, body.theme-1 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:focus > .nav-link, body.theme-1 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:hover > .nav-link,
  body.theme-1 .app-sidebar .nav-item > .nav-submenu .nav-item.active > .nav-link,
  body.theme-1 .app-sidebar .nav-item > .nav-submenu .nav-item:active > .nav-link,
  body.theme-1 .app-sidebar .nav-item > .nav-submenu .nav-item:focus > .nav-link,
  body.theme-1 .app-sidebar .nav-item > .nav-submenu .nav-item:hover > .nav-link {
    color: #7928ca; }
  body.theme-1 a:not([class]) {
    color: #7928ca; }
    body.theme-1 a:not([class]):hover {
      color: #7928ca; }
  body.theme-1 .text-primary {
    color: #7928ca !important; }
  body.theme-1 .list-group-item.active {
    background: linear-gradient(141.55deg, #7928ca 3.46%, #ff0080 99.86%), #7928ca !important; }
  body.theme-1 .progress-bar:not([class*="bg-"]) {
    background: #7928ca !important;
    border-color: #7928ca; }
  body.theme-1 .btn-primary {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #7928ca;
    --bs-btn-border-color: #7928ca;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #6722ac;
    --bs-btn-hover-border-color: #6120a2;
    --bs-btn-focus-shadow-rgb: 141, 72, 210;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #6120a2;
    --bs-btn-active-border-color: #5b1e98;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #7928ca;
    --bs-btn-disabled-border-color: #7928ca; }
  body.theme-1 .btn-outline-primary {
    --bs-btn-color: #7928ca;
    --bs-btn-border-color: #7928ca;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #7928ca;
    --bs-btn-hover-border-color: #7928ca;
    --bs-btn-focus-shadow-rgb: 121, 40, 202;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #7928ca;
    --bs-btn-active-border-color: #7928ca;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #7928ca;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #7928ca;
    --bs-gradient: none; }
  body.theme-1 .btn-light-primary {
    background: #e4d4f4;
    color: #7928ca;
    border-color: #e4d4f4; }
    body.theme-1 .btn-light-primary:hover {
      background: #7928ca;
      color: #fff;
      border-color: #7928ca; }
    body.theme-1 .btn-light-primary.focus, body.theme-1 .btn-light-primary:focus {
      background: #7928ca;
      color: #fff;
      border-color: #7928ca; }
    body.theme-1 .btn-light-primary:not(:disabled):not(.disabled).active, body.theme-1 .btn-light-primary:not(:disabled):not(.disabled):active,
    .show > body.theme-1 .btn-light-primary.dropdown-toggle {
      background: #7928ca;
      color: #fff;
      border-color: #7928ca; }
  body.theme-1 .btn-check:active + .btn-light-primary,
  body.theme-1 .btn-check:checked + .btn-light-primary {
    background: #7928ca;
    color: #fff;
    border-color: #7928ca; }
  body.theme-1 .alert-primary {
    --bs-alert-color: #491879;
    --bs-alert-bg: #e4d4f4;
    --bs-alert-border-color: #d7bfef; }
    body.theme-1 .alert-primary .alert-link {
      color: #3a1361; }
  body.theme-1 .badge.bg-light-primary {
    background: #e4d4f4;
    color: #7928ca;
    border-color: #e4d4f4; }
  body.theme-1 .page-item.active .page-link {
    background: #7928ca;
    border-color: #7928ca; }
  body.theme-1 .nav-link {
    color: #7928ca; }
    body.theme-1 .nav-link:hover, body.theme-1 .nav-link:focus {
      color: #7928ca; }
  body.theme-1 .nav-tabs .nav-link {
    background: none; }
  body.theme-1 .nav-pills .nav-link {
    background: none; }
  body.theme-1 .nav-pills .nav-link:focus,
  body.theme-1 .nav-pills .nav-link.active,
  body.theme-1 .nav-pills .show > .nav-link {
    color: #ffffff;
    background: linear-gradient(141.55deg, #7928ca 3.46%, #ff0080 99.86%), #7928ca; }
  body.theme-1 .form-check-input:focus,
  body.theme-1 .form-select:focus,
  body.theme-1 .form-control:focus,
  body.theme-1 .custom-select:focus,
  body.theme-1 .dataTable-selector:focus,
  body.theme-1 .dataTable-input:focus {
    border-color: #7928ca;
    box-shadow: 0 0 0 0.2rem rgba(121, 40, 202, 0.25); }
  body.theme-1 .form-check-input:checked {
    background-color: #7928ca;
    border-color: #7928ca; }
  body.theme-1 .form-range::-webkit-slider-thumb {
    background: #7928ca; }
    body.theme-1 .form-range::-webkit-slider-thumb:active {
      background: #7928ca; }
  body.theme-1 .form-range::-moz-range-thumb {
    background: #7928ca; }
    body.theme-1 .form-range::-moz-range-thumb:active {
      background: #7928ca; }
  body.theme-1 .form-check-input:checked {
    background-color: #7928ca;
    border-color: #7928ca; }
  body.theme-1 .form-check-input[type="checkbox"]:indeterminate {
    background-color: #7928ca;
    border-color: #7928ca; }
  body.theme-1 .form-check .form-check-input.input-primary:checked {
    background-color: #7928ca;
    border-color: #7928ca; }
  body.theme-1 pre[class*="language-"] > code {
    border-left-color: #7928ca;
    background-image: linear-gradient(transparent 50%, rgba(121, 40, 202, 0.04) 50%); }
  body.theme-1 .card .card-header h5:after, body.theme-1 .card .card-header .h5:after,
  body.theme-1 .card .card-header .h5:after {
    background: #7928ca; }
  body.theme-1 .datepicker-cell.selected, body.theme-1 .datepicker-cell.selected:hover {
    background: #7928ca; }

body.theme-2 {
  background: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%); }
  body.theme-2 .bg-primary {
    background: linear-gradient(to right, #2c3e50, #4ca1af), #2c3e50 !important; }
  body.theme-2 .site-header .drp-language .drp-text,
  body.theme-2 .site-header .dash-head-link > i:not(.nocolor) {
    color: #2c3e50; }
  body.theme-2 .site-header .drp-company .theme-avtar {
    background: #d5d8dc;
    color: #2c3e50; }
  body.theme-2 .app-sidebar.app-light-sidebar .app-navbar > .nav-item.active > .nav-link, body.theme-2 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:active > .nav-link, body.theme-2 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:focus > .nav-link, body.theme-2 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:hover > .nav-link,
  body.theme-2 .app-sidebar .app-navbar > .nav-item.active > .nav-link,
  body.theme-2 .app-sidebar .app-navbar > .nav-item:active > .nav-link,
  body.theme-2 .app-sidebar .app-navbar > .nav-item:focus > .nav-link,
  body.theme-2 .app-sidebar .app-navbar > .nav-item:hover > .nav-link {
    background: linear-gradient(to right, #2c3e50, #4ca1af), #2c3e50;
    color: #fff; }
    body.theme-2 .app-sidebar.app-light-sidebar .app-navbar > .nav-item.active > .nav-link i, body.theme-2 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:active > .nav-link i, body.theme-2 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:focus > .nav-link i, body.theme-2 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:hover > .nav-link i,
    body.theme-2 .app-sidebar .app-navbar > .nav-item.active > .nav-link i,
    body.theme-2 .app-sidebar .app-navbar > .nav-item:active > .nav-link i,
    body.theme-2 .app-sidebar .app-navbar > .nav-item:focus > .nav-link i,
    body.theme-2 .app-sidebar .app-navbar > .nav-item:hover > .nav-link i {
      color: #fff; }
  body.theme-2 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item.active > .nav-link, body.theme-2 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:active > .nav-link, body.theme-2 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:focus > .nav-link, body.theme-2 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:hover > .nav-link,
  body.theme-2 .app-sidebar .nav-item > .nav-submenu .nav-item.active > .nav-link,
  body.theme-2 .app-sidebar .nav-item > .nav-submenu .nav-item:active > .nav-link,
  body.theme-2 .app-sidebar .nav-item > .nav-submenu .nav-item:focus > .nav-link,
  body.theme-2 .app-sidebar .nav-item > .nav-submenu .nav-item:hover > .nav-link {
    color: #2c3e50; }
  body.theme-2 a:not([class]) {
    color: #2c3e50; }
    body.theme-2 a:not([class]):hover {
      color: #2c3e50; }
  body.theme-2 .text-primary {
    color: #2c3e50 !important; }
  body.theme-2 .list-group-item.active {
    background: linear-gradient(to right, #2c3e50, #4ca1af), #2c3e50 !important; }
  body.theme-2 .progress-bar:not([class*="bg-"]) {
    background: #2c3e50 !important;
    border-color: #2c3e50; }
  body.theme-2 .btn-primary {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #2c3e50;
    --bs-btn-border-color: #2c3e50;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #253544;
    --bs-btn-hover-border-color: #233240;
    --bs-btn-focus-shadow-rgb: 76, 91, 106;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #233240;
    --bs-btn-active-border-color: #212f3c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #2c3e50;
    --bs-btn-disabled-border-color: #2c3e50; }
  body.theme-2 .btn-outline-primary {
    --bs-btn-color: #2c3e50;
    --bs-btn-border-color: #2c3e50;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #2c3e50;
    --bs-btn-hover-border-color: #2c3e50;
    --bs-btn-focus-shadow-rgb: 44, 62, 80;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #2c3e50;
    --bs-btn-active-border-color: #2c3e50;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #2c3e50;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #2c3e50;
    --bs-gradient: none; }
  body.theme-2 .btn-light-primary {
    background: #d5d8dc;
    color: #2c3e50;
    border-color: #d5d8dc; }
    body.theme-2 .btn-light-primary:hover {
      background: #2c3e50;
      color: #fff;
      border-color: #2c3e50; }
    body.theme-2 .btn-light-primary.focus, body.theme-2 .btn-light-primary:focus {
      background: #2c3e50;
      color: #fff;
      border-color: #2c3e50; }
    body.theme-2 .btn-light-primary:not(:disabled):not(.disabled).active, body.theme-2 .btn-light-primary:not(:disabled):not(.disabled):active,
    .show > body.theme-2 .btn-light-primary.dropdown-toggle {
      background: #2c3e50;
      color: #fff;
      border-color: #2c3e50; }
  body.theme-2 .btn-check:active + .btn-light-primary,
  body.theme-2 .btn-check:checked + .btn-light-primary {
    background: #2c3e50;
    color: #fff;
    border-color: #2c3e50; }
  body.theme-2 .alert-primary {
    --bs-alert-color: #1a2530;
    --bs-alert-bg: #d5d8dc;
    --bs-alert-border-color: #c0c5cb; }
    body.theme-2 .alert-primary .alert-link {
      color: #151e26; }
  body.theme-2 .badge.bg-light-primary {
    background: #d5d8dc;
    color: #2c3e50;
    border-color: #d5d8dc; }
  body.theme-2 .page-item.active .page-link {
    background: #2c3e50;
    border-color: #2c3e50; }
  body.theme-2 .nav-link {
    color: #2c3e50; }
    body.theme-2 .nav-link:hover, body.theme-2 .nav-link:focus {
      color: #2c3e50; }
  body.theme-2 .nav-tabs .nav-link {
    background: none; }
  body.theme-2 .nav-pills .nav-link {
    background: none; }
  body.theme-2 .nav-pills .nav-link:focus,
  body.theme-2 .nav-pills .nav-link.active,
  body.theme-2 .nav-pills .show > .nav-link {
    color: #ffffff;
    background: linear-gradient(to right, #2c3e50, #4ca1af), #2c3e50; }
  body.theme-2 .form-check-input:focus,
  body.theme-2 .form-select:focus,
  body.theme-2 .form-control:focus,
  body.theme-2 .custom-select:focus,
  body.theme-2 .dataTable-selector:focus,
  body.theme-2 .dataTable-input:focus {
    border-color: #2c3e50;
    box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25); }
  body.theme-2 .form-check-input:checked {
    background-color: #2c3e50;
    border-color: #2c3e50; }
  body.theme-2 .form-range::-webkit-slider-thumb {
    background: #2c3e50; }
    body.theme-2 .form-range::-webkit-slider-thumb:active {
      background: #2c3e50; }
  body.theme-2 .form-range::-moz-range-thumb {
    background: #2c3e50; }
    body.theme-2 .form-range::-moz-range-thumb:active {
      background: #2c3e50; }
  body.theme-2 .form-check-input:checked {
    background-color: #2c3e50;
    border-color: #2c3e50; }
  body.theme-2 .form-check-input[type="checkbox"]:indeterminate {
    background-color: #2c3e50;
    border-color: #2c3e50; }
  body.theme-2 .form-check .form-check-input.input-primary:checked {
    background-color: #2c3e50;
    border-color: #2c3e50; }
  body.theme-2 pre[class*="language-"] > code {
    border-left-color: #2c3e50;
    background-image: linear-gradient(transparent 50%, rgba(44, 62, 80, 0.04) 50%); }
  body.theme-2 .card .card-header h5:after, body.theme-2 .card .card-header .h5:after,
  body.theme-2 .card .card-header .h5:after {
    background: #2c3e50; }
  body.theme-2 .datepicker-cell.selected, body.theme-2 .datepicker-cell.selected:hover {
    background: #2c3e50; }

body.theme-3 {
  background: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%); }
  body.theme-3 .bg-primary {
    background: linear-gradient(270deg, #37006F 0%, #8905D3 100%), #8905D3 !important; }
  body.theme-3 .site-header .drp-language .drp-text,
  body.theme-3 .site-header .dash-head-link > i:not(.nocolor) {
    color: #8905D3; }
  body.theme-3 .site-header .drp-company .theme-avtar {
    background: #e7cdf6;
    color: #8905D3; }
  body.theme-3 .app-sidebar.app-light-sidebar .app-navbar > .nav-item.active > .nav-link, body.theme-3 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:active > .nav-link, body.theme-3 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:focus > .nav-link, body.theme-3 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:hover > .nav-link,
  body.theme-3 .app-sidebar .app-navbar > .nav-item.active > .nav-link,
  body.theme-3 .app-sidebar .app-navbar > .nav-item:active > .nav-link,
  body.theme-3 .app-sidebar .app-navbar > .nav-item:focus > .nav-link,
  body.theme-3 .app-sidebar .app-navbar > .nav-item:hover > .nav-link {
    background: linear-gradient(270deg, #37006F 0%, #8905D3 100%), #8905D3;
    color: #fff; }
    body.theme-3 .app-sidebar.app-light-sidebar .app-navbar > .nav-item.active > .nav-link i, body.theme-3 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:active > .nav-link i, body.theme-3 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:focus > .nav-link i, body.theme-3 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:hover > .nav-link i,
    body.theme-3 .app-sidebar .app-navbar > .nav-item.active > .nav-link i,
    body.theme-3 .app-sidebar .app-navbar > .nav-item:active > .nav-link i,
    body.theme-3 .app-sidebar .app-navbar > .nav-item:focus > .nav-link i,
    body.theme-3 .app-sidebar .app-navbar > .nav-item:hover > .nav-link i {
      color: #fff; }
  body.theme-3 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item.active > .nav-link, body.theme-3 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:active > .nav-link, body.theme-3 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:focus > .nav-link, body.theme-3 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:hover > .nav-link,
  body.theme-3 .app-sidebar .nav-item > .nav-submenu .nav-item.active > .nav-link,
  body.theme-3 .app-sidebar .nav-item > .nav-submenu .nav-item:active > .nav-link,
  body.theme-3 .app-sidebar .nav-item > .nav-submenu .nav-item:focus > .nav-link,
  body.theme-3 .app-sidebar .nav-item > .nav-submenu .nav-item:hover > .nav-link {
    color: #8905D3; }
  body.theme-3 a:not([class]) {
    color: #8905D3; }
    body.theme-3 a:not([class]):hover {
      color: #8905D3; }
  body.theme-3 .text-primary {
    color: #8905D3 !important; }
  body.theme-3 .list-group-item.active {
    background: linear-gradient(270deg, #37006F 0%, #8905D3 100%), #8905D3 !important; }
  body.theme-3 .progress-bar:not([class*="bg-"]) {
    background: #8905D3 !important;
    border-color: #8905D3; }
  body.theme-3 .btn-primary {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #8905D3;
    --bs-btn-border-color: #8905D3;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #7404b3;
    --bs-btn-hover-border-color: #6e04a9;
    --bs-btn-focus-shadow-rgb: 155, 43, 218;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #6e04a9;
    --bs-btn-active-border-color: #67049e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #8905D3;
    --bs-btn-disabled-border-color: #8905D3; }
  body.theme-3 .btn-outline-primary {
    --bs-btn-color: #8905D3;
    --bs-btn-border-color: #8905D3;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #8905D3;
    --bs-btn-hover-border-color: #8905D3;
    --bs-btn-focus-shadow-rgb: 137, 5, 211;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #8905D3;
    --bs-btn-active-border-color: #8905D3;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #8905D3;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #8905D3;
    --bs-gradient: none; }
  body.theme-3 .btn-light-primary {
    background: #e7cdf6;
    color: #8905D3;
    border-color: #e7cdf6; }
    body.theme-3 .btn-light-primary:hover {
      background: #8905D3;
      color: #fff;
      border-color: #8905D3; }
    body.theme-3 .btn-light-primary.focus, body.theme-3 .btn-light-primary:focus {
      background: #8905D3;
      color: #fff;
      border-color: #8905D3; }
    body.theme-3 .btn-light-primary:not(:disabled):not(.disabled).active, body.theme-3 .btn-light-primary:not(:disabled):not(.disabled):active,
    .show > body.theme-3 .btn-light-primary.dropdown-toggle {
      background: #8905D3;
      color: #fff;
      border-color: #8905D3; }
  body.theme-3 .btn-check:active + .btn-light-primary,
  body.theme-3 .btn-check:checked + .btn-light-primary {
    background: #8905D3;
    color: #fff;
    border-color: #8905D3; }
  body.theme-3 .alert-primary {
    --bs-alert-color: #52037f;
    --bs-alert-bg: #e7cdf6;
    --bs-alert-border-color: #dcb4f2; }
    body.theme-3 .alert-primary .alert-link {
      color: #420266; }
  body.theme-3 .badge.bg-light-primary {
    background: #e7cdf6;
    color: #8905D3;
    border-color: #e7cdf6; }
  body.theme-3 .page-item.active .page-link {
    background: #8905D3;
    border-color: #8905D3; }
  body.theme-3 .nav-link {
    color: #8905D3; }
    body.theme-3 .nav-link:hover, body.theme-3 .nav-link:focus {
      color: #8905D3; }
  body.theme-3 .nav-tabs .nav-link {
    background: none; }
  body.theme-3 .nav-pills .nav-link {
    background: none; }
  body.theme-3 .nav-pills .nav-link:focus,
  body.theme-3 .nav-pills .nav-link.active,
  body.theme-3 .nav-pills .show > .nav-link {
    color: #ffffff;
    background: linear-gradient(270deg, #37006F 0%, #8905D3 100%), #8905D3; }
  body.theme-3 .form-check-input:focus,
  body.theme-3 .form-select:focus,
  body.theme-3 .form-control:focus,
  body.theme-3 .custom-select:focus,
  body.theme-3 .dataTable-selector:focus,
  body.theme-3 .dataTable-input:focus {
    border-color: #8905D3;
    box-shadow: 0 0 0 0.2rem rgba(137, 5, 211, 0.25); }
  body.theme-3 .form-check-input:checked {
    background-color: #8905D3;
    border-color: #8905D3; }
  body.theme-3 .form-range::-webkit-slider-thumb {
    background: #8905D3; }
    body.theme-3 .form-range::-webkit-slider-thumb:active {
      background: #8905D3; }
  body.theme-3 .form-range::-moz-range-thumb {
    background: #8905D3; }
    body.theme-3 .form-range::-moz-range-thumb:active {
      background: #8905D3; }
  body.theme-3 .form-check-input:checked {
    background-color: #8905D3;
    border-color: #8905D3; }
  body.theme-3 .form-check-input[type="checkbox"]:indeterminate {
    background-color: #8905D3;
    border-color: #8905D3; }
  body.theme-3 .form-check .form-check-input.input-primary:checked {
    background-color: #8905D3;
    border-color: #8905D3; }
  body.theme-3 pre[class*="language-"] > code {
    border-left-color: #8905D3;
    background-image: linear-gradient(transparent 50%, rgba(137, 5, 211, 0.04) 50%); }
  body.theme-3 .card .card-header h5:after, body.theme-3 .card .card-header .h5:after,
  body.theme-3 .card .card-header .h5:after {
    background: #8905D3; }
  body.theme-3 .datepicker-cell.selected, body.theme-3 .datepicker-cell.selected:hover {
    background: #8905D3; }

body.theme-4 {
  background: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%); }
  body.theme-4 .bg-primary {
    background: linear-gradient(141.55deg, rgba(104, 94, 229, 0) 3.46%, #685EE5 99.86%), #584ED2 !important; }
  body.theme-4 .site-header .drp-language .drp-text,
  body.theme-4 .site-header .dash-head-link > i:not(.nocolor) {
    color: #584ED2; }
  body.theme-4 .site-header .drp-company .theme-avtar {
    background: #dedcf6;
    color: #584ED2; }
  body.theme-4 .app-sidebar.app-light-sidebar .app-navbar > .nav-item.active > .nav-link, body.theme-4 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:active > .nav-link, body.theme-4 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:focus > .nav-link, body.theme-4 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:hover > .nav-link,
  body.theme-4 .app-sidebar .app-navbar > .nav-item.active > .nav-link,
  body.theme-4 .app-sidebar .app-navbar > .nav-item:active > .nav-link,
  body.theme-4 .app-sidebar .app-navbar > .nav-item:focus > .nav-link,
  body.theme-4 .app-sidebar .app-navbar > .nav-item:hover > .nav-link {
    background: linear-gradient(141.55deg, rgba(104, 94, 229, 0) 3.46%, #685EE5 99.86%), #584ED2;
    color: #fff; }
    body.theme-4 .app-sidebar.app-light-sidebar .app-navbar > .nav-item.active > .nav-link i, body.theme-4 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:active > .nav-link i, body.theme-4 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:focus > .nav-link i, body.theme-4 .app-sidebar.app-light-sidebar .app-navbar > .nav-item:hover > .nav-link i,
    body.theme-4 .app-sidebar .app-navbar > .nav-item.active > .nav-link i,
    body.theme-4 .app-sidebar .app-navbar > .nav-item:active > .nav-link i,
    body.theme-4 .app-sidebar .app-navbar > .nav-item:focus > .nav-link i,
    body.theme-4 .app-sidebar .app-navbar > .nav-item:hover > .nav-link i {
      color: #fff; }
  body.theme-4 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item.active > .nav-link, body.theme-4 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:active > .nav-link, body.theme-4 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:focus > .nav-link, body.theme-4 .app-sidebar.app-light-sidebar .nav-item > .nav-submenu .nav-item:hover > .nav-link,
  body.theme-4 .app-sidebar .nav-item > .nav-submenu .nav-item.active > .nav-link,
  body.theme-4 .app-sidebar .nav-item > .nav-submenu .nav-item:active > .nav-link,
  body.theme-4 .app-sidebar .nav-item > .nav-submenu .nav-item:focus > .nav-link,
  body.theme-4 .app-sidebar .nav-item > .nav-submenu .nav-item:hover > .nav-link {
    color: #584ED2; }
  body.theme-4 a:not([class]) {
    color: #584ED2; }
    body.theme-4 a:not([class]):hover {
      color: #584ED2; }
  body.theme-4 .text-primary {
    color: #584ED2 !important; }
  body.theme-4 .list-group-item.active {
    background: linear-gradient(141.55deg, rgba(104, 94, 229, 0) 3.46%, #685EE5 99.86%), #584ED2 !important; }
  body.theme-4 .progress-bar:not([class*="bg-"]) {
    background: #584ED2 !important;
    border-color: #584ED2; }
  body.theme-4 .btn-primary {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #584ED2;
    --bs-btn-border-color: #584ED2;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #4b42b3;
    --bs-btn-hover-border-color: #463ea8;
    --bs-btn-focus-shadow-rgb: 113, 105, 217;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #463ea8;
    --bs-btn-active-border-color: #423b9e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #584ED2;
    --bs-btn-disabled-border-color: #584ED2; }
  body.theme-4 .btn-outline-primary {
    --bs-btn-color: #584ED2;
    --bs-btn-border-color: #584ED2;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #584ED2;
    --bs-btn-hover-border-color: #584ED2;
    --bs-btn-focus-shadow-rgb: 88, 78, 210;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #584ED2;
    --bs-btn-active-border-color: #584ED2;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #584ED2;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #584ED2;
    --bs-gradient: none; }
  body.theme-4 .btn-light-primary {
    background: #dedcf6;
    color: #584ED2;
    border-color: #dedcf6; }
    body.theme-4 .btn-light-primary:hover {
      background: #584ED2;
      color: #fff;
      border-color: #584ED2; }
    body.theme-4 .btn-light-primary.focus, body.theme-4 .btn-light-primary:focus {
      background: #584ED2;
      color: #fff;
      border-color: #584ED2; }
    body.theme-4 .btn-light-primary:not(:disabled):not(.disabled).active, body.theme-4 .btn-light-primary:not(:disabled):not(.disabled):active,
    .show > body.theme-4 .btn-light-primary.dropdown-toggle {
      background: #584ED2;
      color: #fff;
      border-color: #584ED2; }
  body.theme-4 .btn-check:active + .btn-light-primary,
  body.theme-4 .btn-check:checked + .btn-light-primary {
    background: #584ED2;
    color: #fff;
    border-color: #584ED2; }
  body.theme-4 .alert-primary {
    --bs-alert-color: #352f7e;
    --bs-alert-bg: #dedcf6;
    --bs-alert-border-color: #cdcaf2; }
    body.theme-4 .alert-primary .alert-link {
      color: #2a2665; }
  body.theme-4 .badge.bg-light-primary {
    background: #dedcf6;
    color: #584ED2;
    border-color: #dedcf6; }
  body.theme-4 .page-item.active .page-link {
    background: #584ED2;
    border-color: #584ED2; }
  body.theme-4 .nav-link {
    color: #584ED2; }
    body.theme-4 .nav-link:hover, body.theme-4 .nav-link:focus {
      color: #584ED2; }
  body.theme-4 .nav-tabs .nav-link {
    background: none; }
  body.theme-4 .nav-pills .nav-link {
    background: none; }
  body.theme-4 .nav-pills .nav-link:focus,
  body.theme-4 .nav-pills .nav-link.active,
  body.theme-4 .nav-pills .show > .nav-link {
    color: #ffffff;
    background: linear-gradient(141.55deg, rgba(104, 94, 229, 0) 3.46%, #685EE5 99.86%), #584ED2; }
  body.theme-4 .form-check-input:focus,
  body.theme-4 .form-select:focus,
  body.theme-4 .form-control:focus,
  body.theme-4 .custom-select:focus,
  body.theme-4 .dataTable-selector:focus,
  body.theme-4 .dataTable-input:focus {
    border-color: #584ED2;
    box-shadow: 0 0 0 0.2rem rgba(88, 78, 210, 0.25); }
  body.theme-4 .form-check-input:checked {
    background-color: #584ED2;
    border-color: #584ED2; }
  body.theme-4 .form-range::-webkit-slider-thumb {
    background: #584ED2; }
    body.theme-4 .form-range::-webkit-slider-thumb:active {
      background: #584ED2; }
  body.theme-4 .form-range::-moz-range-thumb {
    background: #584ED2; }
    body.theme-4 .form-range::-moz-range-thumb:active {
      background: #584ED2; }
  body.theme-4 .form-check-input:checked {
    background-color: #584ED2;
    border-color: #584ED2; }
  body.theme-4 .form-check-input[type="checkbox"]:indeterminate {
    background-color: #584ED2;
    border-color: #584ED2; }
  body.theme-4 .form-check .form-check-input.input-primary:checked {
    background-color: #584ED2;
    border-color: #584ED2; }
  body.theme-4 pre[class*="language-"] > code {
    border-left-color: #584ED2;
    background-image: linear-gradient(transparent 50%, rgba(88, 78, 210, 0.04) 50%); }
  body.theme-4 .card .card-header h5:after, body.theme-4 .card .card-header .h5:after,
  body.theme-4 .card .card-header .h5:after {
    background: #584ED2; }
  body.theme-4 .datepicker-cell.selected, body.theme-4 .datepicker-cell.selected:hover {
    background: #584ED2; }

/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px; }

.p-t-0 {
  padding-top: 0px; }

.p-b-0 {
  padding-bottom: 0px; }

.p-l-0 {
  padding-left: 0px; }

.p-r-0 {
  padding-right: 0px; }

.m-0 {
  margin: 0px; }

.m-t-0 {
  margin-top: 0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-r-0 {
  margin-right: 0px; }

.p-5 {
  padding: 5px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.m-5 {
  margin: 5px; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.p-10 {
  padding: 10px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.m-10 {
  margin: 10px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.p-15 {
  padding: 15px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.m-15 {
  margin: 15px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.p-20 {
  padding: 20px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.m-20 {
  margin: 20px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-25 {
  padding: 25px; }

.p-t-25 {
  padding-top: 25px; }

.p-b-25 {
  padding-bottom: 25px; }

.p-l-25 {
  padding-left: 25px; }

.p-r-25 {
  padding-right: 25px; }

.m-25 {
  margin: 25px; }

.m-t-25 {
  margin-top: 25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-r-25 {
  margin-right: 25px; }

.p-30 {
  padding: 30px; }

.p-t-30 {
  padding-top: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-r-30 {
  padding-right: 30px; }

.m-30 {
  margin: 30px; }

.m-t-30 {
  margin-top: 30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-r-30 {
  margin-right: 30px; }

.p-35 {
  padding: 35px; }

.p-t-35 {
  padding-top: 35px; }

.p-b-35 {
  padding-bottom: 35px; }

.p-l-35 {
  padding-left: 35px; }

.p-r-35 {
  padding-right: 35px; }

.m-35 {
  margin: 35px; }

.m-t-35 {
  margin-top: 35px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-l-35 {
  margin-left: 35px; }

.m-r-35 {
  margin-right: 35px; }

.p-40 {
  padding: 40px; }

.p-t-40 {
  padding-top: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-r-40 {
  padding-right: 40px; }

.m-40 {
  margin: 40px; }

.m-t-40 {
  margin-top: 40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-r-40 {
  margin-right: 40px; }

.p-45 {
  padding: 45px; }

.p-t-45 {
  padding-top: 45px; }

.p-b-45 {
  padding-bottom: 45px; }

.p-l-45 {
  padding-left: 45px; }

.p-r-45 {
  padding-right: 45px; }

.m-45 {
  margin: 45px; }

.m-t-45 {
  margin-top: 45px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-l-45 {
  margin-left: 45px; }

.m-r-45 {
  margin-right: 45px; }

.p-50 {
  padding: 50px; }

.p-t-50 {
  padding-top: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-r-50 {
  padding-right: 50px; }

.m-50 {
  margin: 50px; }

.m-t-50 {
  margin-top: 50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-r-50 {
  margin-right: 50px; }

/*====== Padding , Margin css ends ======*/
/*====== Font-size css starts ======*/
.f-10 {
  font-size: 10px; }

.f-12 {
  font-size: 12px; }

.f-14 {
  font-size: 14px; }

.f-16 {
  font-size: 16px; }

.f-18 {
  font-size: 18px; }

.f-20 {
  font-size: 20px; }

.f-22 {
  font-size: 22px; }

.f-24 {
  font-size: 24px; }

.f-26 {
  font-size: 26px; }

.f-28 {
  font-size: 28px; }

.f-30 {
  font-size: 30px; }

.f-32 {
  font-size: 32px; }

.f-34 {
  font-size: 34px; }

.f-36 {
  font-size: 36px; }

.f-38 {
  font-size: 38px; }

.f-40 {
  font-size: 40px; }

.f-42 {
  font-size: 42px; }

.f-44 {
  font-size: 44px; }

.f-46 {
  font-size: 46px; }

.f-48 {
  font-size: 48px; }

.f-50 {
  font-size: 50px; }

.f-52 {
  font-size: 52px; }

.f-54 {
  font-size: 54px; }

.f-56 {
  font-size: 56px; }

.f-58 {
  font-size: 58px; }

.f-60 {
  font-size: 60px; }

.f-62 {
  font-size: 62px; }

.f-64 {
  font-size: 64px; }

.f-66 {
  font-size: 66px; }

.f-68 {
  font-size: 68px; }

.f-70 {
  font-size: 70px; }

.f-72 {
  font-size: 72px; }

.f-74 {
  font-size: 74px; }

.f-76 {
  font-size: 76px; }

.f-78 {
  font-size: 78px; }

.f-80 {
  font-size: 80px; }

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.fw-100 {
  font-weight: 100; }

.fw-200 {
  font-weight: 200; }

.fw-300 {
  font-weight: 300; }

.fw-400 {
  font-weight: 400; }

.fw-500 {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.fw-700 {
  font-weight: 700; }

.fw-800 {
  font-weight: 800; }

.fw-900 {
  font-weight: 900; }

/*====== Font-weight css ends ======*/
/*====== width, Height css starts ======*/
.width-10 {
  width: 10px; }

.height-10 {
  height: 10px; }

.width-15 {
  width: 15px; }

.height-15 {
  height: 15px; }

.width-20 {
  width: 20px; }

.height-20 {
  height: 20px; }

.width-25 {
  width: 25px; }

.height-25 {
  height: 25px; }

.width-30 {
  width: 30px; }

.height-30 {
  height: 30px; }

.width-35 {
  width: 35px; }

.height-35 {
  height: 35px; }

.width-40 {
  width: 40px; }

.height-40 {
  height: 40px; }

.width-45 {
  width: 45px; }

.height-45 {
  height: 45px; }

.width-50 {
  width: 50px; }

.height-50 {
  height: 50px; }

.width-55 {
  width: 55px; }

.height-55 {
  height: 55px; }

.width-60 {
  width: 60px; }

.height-60 {
  height: 60px; }

.width-65 {
  width: 65px; }

.height-65 {
  height: 65px; }

.width-70 {
  width: 70px; }

.height-70 {
  height: 70px; }

.width-75 {
  width: 75px; }

.height-75 {
  height: 75px; }

.width-80 {
  width: 80px; }

.height-80 {
  height: 80px; }

.width-85 {
  width: 85px; }

.height-85 {
  height: 85px; }

.width-90 {
  width: 90px; }

.height-90 {
  height: 90px; }

.width-95 {
  width: 95px; }

.height-95 {
  height: 95px; }

.width-100 {
  width: 100px; }

.height-100 {
  height: 100px; }

.width-105 {
  width: 105px; }

.height-105 {
  height: 105px; }

.width-110 {
  width: 110px; }

.height-110 {
  height: 110px; }

.width-115 {
  width: 115px; }

.height-115 {
  height: 115px; }

.width-120 {
  width: 120px; }

.height-120 {
  height: 120px; }

.width-125 {
  width: 125px; }

.height-125 {
  height: 125px; }

.width-130 {
  width: 130px; }

.height-130 {
  height: 130px; }

.width-135 {
  width: 135px; }

.height-135 {
  height: 135px; }

.width-140 {
  width: 140px; }

.height-140 {
  height: 140px; }

.width-145 {
  width: 145px; }

.height-145 {
  height: 145px; }

.width-150 {
  width: 150px; }

.height-150 {
  height: 150px; }

/*====== width, Height css ends ======*/
/*====== border-width css starts ======*/
.b-wid-1 {
  border-width: 1px; }

.b-wid-2 {
  border-width: 2px; }

.b-wid-3 {
  border-width: 3px; }

.b-wid-4 {
  border-width: 4px; }

.b-wid-5 {
  border-width: 5px; }

.b-wid-6 {
  border-width: 6px; }

.b-wid-7 {
  border-width: 7px; }

.b-wid-8 {
  border-width: 8px; }

/*====== border-width css ends ======*/
/*====== background starts ======*/
.bg-body {
  background: #22242c !important; }

.bg-light-primary {
  background: #dcdaeb;
  color: #51459d; }

.text-light-primary {
  color: #dcdaeb; }

.icon-svg-primary {
  fill: #dcdaeb;
  stroke: #51459d; }

.material-icons-two-tone.text-primary {
  background-color: #51459d; }

.text-hover-primary:hover {
  color: #51459d !important; }

.bg-light-secondary {
  background: #e2e3e5;
  color: #6c757d; }

.text-light-secondary {
  color: #e2e3e5; }

.icon-svg-secondary {
  fill: #e2e3e5;
  stroke: #6c757d; }

.material-icons-two-tone.text-secondary {
  background-color: #6c757d; }

.text-hover-secondary:hover {
  color: #6c757d !important; }

.bg-light-success {
  background: #e2f7d9;
  color: #6fd943; }

.text-light-success {
  color: #e2f7d9; }

.icon-svg-success {
  fill: #e2f7d9;
  stroke: #6fd943; }

.material-icons-two-tone.text-success {
  background-color: #6fd943; }

.text-hover-success:hover {
  color: #6fd943 !important; }

.bg-light-info {
  background: #d8f4f7;
  color: #3ec9d6; }

.text-light-info {
  color: #d8f4f7; }

.icon-svg-info {
  fill: #d8f4f7;
  stroke: #3ec9d6; }

.material-icons-two-tone.text-info {
  background-color: #3ec9d6; }

.text-hover-info:hover {
  color: #3ec9d6 !important; }

.bg-light-warning {
  background: #ffecd2;
  color: #ffa21d; }

.text-light-warning {
  color: #ffecd2; }

.icon-svg-warning {
  fill: #ffecd2;
  stroke: #ffa21d; }

.material-icons-two-tone.text-warning {
  background-color: #ffa21d; }

.text-hover-warning:hover {
  color: #ffa21d !important; }

.bg-light-danger {
  background: #ffd8e2;
  color: #ff3a6e; }

.text-light-danger {
  color: #ffd8e2; }

.icon-svg-danger {
  fill: #ffd8e2;
  stroke: #ff3a6e; }

.material-icons-two-tone.text-danger {
  background-color: #ff3a6e; }

.text-hover-danger:hover {
  color: #ff3a6e !important; }

.bg-light-light {
  background: #fefefe;
  color: #f8f9fa; }

.text-light-light {
  color: #fefefe; }

.icon-svg-light {
  fill: #fefefe;
  stroke: #f8f9fa; }

.material-icons-two-tone.text-light {
  background-color: #f8f9fa; }

.text-hover-light:hover {
  color: #f8f9fa !important; }

.bg-light-dark {
  background: #d2d3d5;
  color: #1c232f; }

.text-light-dark {
  color: #d2d3d5; }

.icon-svg-dark {
  fill: #d2d3d5;
  stroke: #1c232f; }

.material-icons-two-tone.text-dark {
  background-color: #1c232f; }

.text-hover-dark:hover {
  color: #1c232f !important; }

/*====== background ends ======*/
/*====== border color css starts ======*/
.b-primary {
  border: 1px solid #51459d; }

.border-bottom-primary td {
  border-bottom: 1px solid #51459d; }

.border-bottom-primary th {
  border-bottom: 1px solid #51459d !important; }

.fill-primary {
  fill: #51459d; }

.b-secondary {
  border: 1px solid #6c757d; }

.border-bottom-secondary td {
  border-bottom: 1px solid #6c757d; }

.border-bottom-secondary th {
  border-bottom: 1px solid #6c757d !important; }

.fill-secondary {
  fill: #6c757d; }

.b-success {
  border: 1px solid #6fd943; }

.border-bottom-success td {
  border-bottom: 1px solid #6fd943; }

.border-bottom-success th {
  border-bottom: 1px solid #6fd943 !important; }

.fill-success {
  fill: #6fd943; }

.b-info {
  border: 1px solid #3ec9d6; }

.border-bottom-info td {
  border-bottom: 1px solid #3ec9d6; }

.border-bottom-info th {
  border-bottom: 1px solid #3ec9d6 !important; }

.fill-info {
  fill: #3ec9d6; }

.b-warning {
  border: 1px solid #ffa21d; }

.border-bottom-warning td {
  border-bottom: 1px solid #ffa21d; }

.border-bottom-warning th {
  border-bottom: 1px solid #ffa21d !important; }

.fill-warning {
  fill: #ffa21d; }

.b-danger {
  border: 1px solid #ff3a6e; }

.border-bottom-danger td {
  border-bottom: 1px solid #ff3a6e; }

.border-bottom-danger th {
  border-bottom: 1px solid #ff3a6e !important; }

.fill-danger {
  fill: #ff3a6e; }

.b-light {
  border: 1px solid #f8f9fa; }

.border-bottom-light td {
  border-bottom: 1px solid #f8f9fa; }

.border-bottom-light th {
  border-bottom: 1px solid #f8f9fa !important; }

.fill-light {
  fill: #f8f9fa; }

.b-dark {
  border: 1px solid #1c232f; }

.border-bottom-dark td {
  border-bottom: 1px solid #1c232f; }

.border-bottom-dark th {
  border-bottom: 1px solid #1c232f !important; }

.fill-dark {
  fill: #1c232f; }

/*====== border color css ends ======*/
/*====== text-color, background color css starts ======*/
.bg-facebook {
  background: #3C5A99; }

.text-facebook {
  color: #3C5A99; }

.bg-twitter {
  background: #42C0FB; }

.text-twitter {
  color: #42C0FB; }

.bg-dribbble {
  background: #EC4A89; }

.text-dribbble {
  color: #EC4A89; }

.bg-pinterest {
  background: #BF2131; }

.text-pinterest {
  color: #BF2131; }

.bg-youtube {
  background: #E0291D; }

.text-youtube {
  color: #E0291D; }

.bg-googleplus {
  background: #C73E2E; }

.text-googleplus {
  color: #C73E2E; }

.bg-instagram {
  background: #AA7C62; }

.text-instagram {
  color: #AA7C62; }

.bg-viber {
  background: #7B519D; }

.text-viber {
  color: #7B519D; }

.bg-behance {
  background: #0057ff; }

.text-behance {
  color: #0057ff; }

.bg-dropbox {
  background: #3380FF; }

.text-dropbox {
  color: #3380FF; }

.bg-linkedin {
  background: #0077B5; }

.text-linkedin {
  color: #0077B5; }

.bg-amazon {
  background: #000; }

.text-amazon {
  color: #000; }

/*====== text-color css ends ======*/
.font-hairline {
  font-weight: 100 !important; }

.font-thin {
  font-weight: 200 !important; }

.font-light {
  font-weight: 300 !important; }

.font-normal {
  font-weight: 400 !important; }

.font-medium {
  font-weight: 500 !important; }

.font-semibold {
  font-weight: 600 !important; }

.font-bold {
  font-weight: 700 !important; }

.font-extrabold {
  font-weight: 800 !important; }

.font-black {
  font-weight: 900 !important; }

.text-xs {
  font-size: 0.625rem !important; }

.text-sm {
  font-size: 0.875rem !important; }

.text-md {
  font-size: 0.8125rem !important; }

.text-base {
  font-size: 0.875rem !important; }

.text-lg {
  font-size: 1rem !important; }

.text-xl {
  font-size: 1.125rem !important; }

.text-2xl {
  font-size: 1.25rem !important; }

.text-3xl {
  font-size: 1.5rem !important; }

.text-4xl {
  font-size: 2rem !important; }

.text-5xl {
  font-size: 2.25rem !important; }

.text-6xl {
  font-size: 2.5rem !important; }

.text-7xl {
  font-size: 3rem !important; }

.text-8xl {
  font-size: 4rem !important; }

.text-9xl {
  font-size: 6rem !important; }

.text-10xl {
  font-size: 8rem !important; }

.uppercase {
  text-transform: uppercase !important; }

.lowercase {
  text-transform: lowercase !important; }

.capitalize {
  text-transform: capitalize !important; }

.normal-case {
  text-transform: none !important; }

/*====== more bootstrap colors start ======*/
.bg-blue-100 {
  color: #212529;
  background: #dcdaeb; }

.bg-blue-200 {
  color: #ffffff;
  background: #b9b5d8; }

.bg-blue-300 {
  color: #ffffff;
  background: #978fc4; }

.bg-blue-400 {
  color: #ffffff;
  background: #746ab1; }

.bg-blue-500 {
  color: #ffffff;
  background: #51459d; }

.bg-blue-600 {
  color: #ffffff;
  background: #41377e; }

.bg-blue-700 {
  color: #ffffff;
  background: #31295e; }

.bg-blue-800 {
  color: #ffffff;
  background: #201c3f; }

.bg-blue-900 {
  color: #ffffff;
  background: #100e1f; }

.bg-indigo-100 {
  color: #212529;
  background: #e0cffc; }

.bg-indigo-200 {
  color: #ffffff;
  background: #c29ffa; }

.bg-indigo-300 {
  color: #ffffff;
  background: #a370f7; }

.bg-indigo-400 {
  color: #ffffff;
  background: #8540f5; }

.bg-indigo-500 {
  color: #ffffff;
  background: #6610f2; }

.bg-indigo-600 {
  color: #ffffff;
  background: #520dc2; }

.bg-indigo-700 {
  color: #ffffff;
  background: #3d0a91; }

.bg-indigo-800 {
  color: #ffffff;
  background: #290661; }

.bg-indigo-900 {
  color: #ffffff;
  background: #140330; }

.bg-purple-100 {
  color: #212529;
  background: #e2d9f3; }

.bg-purple-200 {
  color: #ffffff;
  background: #c5b3e6; }

.bg-purple-300 {
  color: #ffffff;
  background: #a98eda; }

.bg-purple-400 {
  color: #ffffff;
  background: #8c68cd; }

.bg-purple-500 {
  color: #ffffff;
  background: #6f42c1; }

.bg-purple-600 {
  color: #ffffff;
  background: #59359a; }

.bg-purple-700 {
  color: #ffffff;
  background: #432874; }

.bg-purple-800 {
  color: #ffffff;
  background: #2c1a4d; }

.bg-purple-900 {
  color: #ffffff;
  background: #160d27; }

.bg-pink-100 {
  color: #212529;
  background: #fad8e8; }

.bg-pink-200 {
  color: #ffffff;
  background: #f6b2d1; }

.bg-pink-300 {
  color: #ffffff;
  background: #f18bba; }

.bg-pink-400 {
  color: #ffffff;
  background: #ed65a3; }

.bg-pink-500 {
  color: #ffffff;
  background: #e83e8c; }

.bg-pink-600 {
  color: #ffffff;
  background: #ba3270; }

.bg-pink-700 {
  color: #ffffff;
  background: #8b2554; }

.bg-pink-800 {
  color: #ffffff;
  background: #5d1938; }

.bg-pink-900 {
  color: #ffffff;
  background: #2e0c1c; }

.bg-red-100 {
  color: #212529;
  background: #ffd8e2; }

.bg-red-200 {
  color: #ffffff;
  background: #ffb0c5; }

.bg-red-300 {
  color: #ffffff;
  background: #ff89a8; }

.bg-red-400 {
  color: #ffffff;
  background: #ff618b; }

.bg-red-500 {
  color: #ffffff;
  background: #ff3a6e; }

.bg-red-600 {
  color: #ffffff;
  background: #cc2e58; }

.bg-red-700 {
  color: #ffffff;
  background: #992342; }

.bg-red-800 {
  color: #ffffff;
  background: #66172c; }

.bg-red-900 {
  color: #ffffff;
  background: #330c16; }

.bg-orange-100 {
  color: #212529;
  background: #ffe5d0; }

.bg-orange-200 {
  color: #212529;
  background: #fecba1; }

.bg-orange-300 {
  color: #ffffff;
  background: #feb272; }

.bg-orange-400 {
  color: #ffffff;
  background: #fd9843; }

.bg-orange-500 {
  color: #ffffff;
  background: #fd7e14; }

.bg-orange-600 {
  color: #ffffff;
  background: #ca6510; }

.bg-orange-700 {
  color: #ffffff;
  background: #984c0c; }

.bg-orange-800 {
  color: #ffffff;
  background: #653208; }

.bg-orange-900 {
  color: #ffffff;
  background: #331904; }

.bg-yellow-100 {
  color: #212529;
  background: #ffecd2; }

.bg-yellow-200 {
  color: #212529;
  background: #ffdaa5; }

.bg-yellow-300 {
  color: #212529;
  background: #ffc777; }

.bg-yellow-400 {
  color: #ffffff;
  background: #ffb54a; }

.bg-yellow-500 {
  color: #ffffff;
  background: #ffa21d; }

.bg-yellow-600 {
  color: #ffffff;
  background: #cc8217; }

.bg-yellow-700 {
  color: #ffffff;
  background: #996111; }

.bg-yellow-800 {
  color: #ffffff;
  background: #66410c; }

.bg-yellow-900 {
  color: #ffffff;
  background: #332006; }

.bg-green-100 {
  color: #212529;
  background: #e2f7d9; }

.bg-green-200 {
  color: #212529;
  background: #c5f0b4; }

.bg-green-300 {
  color: #212529;
  background: #a9e88e; }

.bg-green-400 {
  color: #ffffff;
  background: #8ce169; }

.bg-green-500 {
  color: #ffffff;
  background: #6fd943; }

.bg-green-600 {
  color: #ffffff;
  background: #59ae36; }

.bg-green-700 {
  color: #ffffff;
  background: #438228; }

.bg-green-800 {
  color: #ffffff;
  background: #2c571b; }

.bg-green-900 {
  color: #ffffff;
  background: #162b0d; }

.bg-teal-100 {
  color: #212529;
  background: #d2f4ea; }

.bg-teal-200 {
  color: #212529;
  background: #a6e9d5; }

.bg-teal-300 {
  color: #ffffff;
  background: #79dfc1; }

.bg-teal-400 {
  color: #ffffff;
  background: #4dd4ac; }

.bg-teal-500 {
  color: #ffffff;
  background: #20c997; }

.bg-teal-600 {
  color: #ffffff;
  background: #1aa179; }

.bg-teal-700 {
  color: #ffffff;
  background: #13795b; }

.bg-teal-800 {
  color: #ffffff;
  background: #0d503c; }

.bg-teal-900 {
  color: #ffffff;
  background: #06281e; }

.bg-cyan-100 {
  color: #212529;
  background: #d8f4f7; }

.bg-cyan-200 {
  color: #212529;
  background: #b2e9ef; }

.bg-cyan-300 {
  color: #212529;
  background: #8bdfe6; }

.bg-cyan-400 {
  color: #ffffff;
  background: #65d4de; }

.bg-cyan-500 {
  color: #ffffff;
  background: #3ec9d6; }

.bg-cyan-600 {
  color: #ffffff;
  background: #32a1ab; }

.bg-cyan-700 {
  color: #ffffff;
  background: #257980; }

.bg-cyan-800 {
  color: #ffffff;
  background: #195056; }

.bg-cyan-900 {
  color: #ffffff;
  background: #0c282b; }

.bg-gray-100 {
  color: #212529;
  background: #f8f9fa; }

.bg-gray-200 {
  color: #212529;
  background: #e9ecef; }

.bg-gray-300 {
  color: #212529;
  background: #dee2e6; }

.bg-gray-400 {
  color: #212529;
  background: #ced4da; }

.bg-gray-500 {
  color: #ffffff;
  background: #adb5bd; }

.bg-gray-600 {
  color: #ffffff;
  background: #6c757d; }

.bg-gray-700 {
  color: #ffffff;
  background: #495057; }

.bg-gray-800 {
  color: #ffffff;
  background: #343a40; }

.bg-gray-900 {
  color: #ffffff;
  background: #212529; }

/*====== more bootstrap colors end ======*/
.app-sidebar {
  background: #292A33;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  width: 255px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1026; }
  .app-sidebar .brand-link {
    padding: 1rem 1.5rem 1.5rem;
    display: block; }
    .app-sidebar .brand-link .b-brand {
      display: block; }
      .app-sidebar .brand-link .b-brand img {
        width: 100%;
        max-width: 100%; }
  .app-sidebar .navbar-content {
    position: relative;
    height: calc(100vh - 70px);
    padding: 10px 0; }
  .app-sidebar ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
  .app-sidebar .nav-icon {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle; }
    .app-sidebar .nav-icon i {
      font-size: 18px;
      color: #6c757d; }
    .app-sidebar .nav-icon > svg {
      width: 18px;
      height: 18px;
      color: #6c757d; }
  .app-sidebar .app-navbar > .nav-item > .nav-link {
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px 10px 10px 7px;
    border-radius: 5px; }
  .app-sidebar .app-navbar .nav-link {
    display: flex;
    align-items: center;
    padding: 13px 20px;
    color: #6c757d;
    position: relative;
    line-height: 1;
    margin: 7px 0;
    font-size: 14px;
    font-weight: 600; }
    .app-sidebar .app-navbar .nav-link .nav-text {
      flex: 1; }
    .app-sidebar .app-navbar .nav-link:active, .app-sidebar .app-navbar .nav-link:focus, .app-sidebar .app-navbar .nav-link:hover {
      text-decoration: none;
      color: #6c757d; }
  .app-sidebar .nav-caption {
    display: block;
    padding: 20px 20px 8px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-size: 11px;
    color: #6c757d;
    font-weight: 700; }
  .app-sidebar .nav-submenu .nav-link {
    display: block;
    font-weight: 500;
    margin: 4px 0;
    padding: 10px 30px 10px 45px; }
    .app-sidebar .nav-submenu .nav-link .nav-arrow {
      float: right;
      display: inline-block;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
  .app-sidebar .nav-submenu .nav-submenu .nav-link {
    padding: 10px 30px 10px 85px; }
  .app-sidebar .nav-submenu .nav-submenu .nav-submenu .nav-link {
    padding: 10px 30px 10px 105px; }
  .app-sidebar .nav-arrow svg {
    width: 18px;
    height: 18px;
    display: inline-block; }
  .app-sidebar .nav-submenu {
    position: relative; }
    .app-sidebar .nav-submenu .nav-item {
      position: relative; }
      .app-sidebar .nav-submenu .nav-item:before {
        content: "";
        position: absolute;
        left: 26px;
        top: 15px;
        width: 8px;
        height: 8px;
        border: 2px solid #fff;
        border-right-color: #6c757d;
        border-radius: 50%;
        z-index: 1;
        transform: rotate(45deg);
        transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
        color: #778290; }
      .app-sidebar .nav-submenu .nav-item.active:before {
        transform: rotate(230deg); }
      .app-sidebar .nav-submenu .nav-item:hover:before {
        transform: rotate(230deg); }
    .app-sidebar .nav-submenu .nav-submenu > .nav-item:before {
      left: 60px; }
    .app-sidebar .nav-submenu .nav-submenu .nav-submenu > .nav-item:before {
      left: 80px; }
  .app-sidebar .nav-item.disabled a {
    cursor: not-allowed;
    user-select: none; }
  .app-sidebar .nav-item.active > .nav-link, .app-sidebar .nav-item:focus > .nav-link, .app-sidebar .nav-item:hover > .nav-link {
    color: #fff; }
    .app-sidebar .nav-item.active > .nav-link .nav-icon i.material-icons-two-tone, .app-sidebar .nav-item:focus > .nav-link .nav-icon i.material-icons-two-tone, .app-sidebar .nav-item:hover > .nav-link .nav-icon i.material-icons-two-tone {
      background-color: #fff; }
    .app-sidebar .nav-item.active > .nav-link .nav-icon i,
    .app-sidebar .nav-item.active > .nav-link .nav-icon svg, .app-sidebar .nav-item:focus > .nav-link .nav-icon i,
    .app-sidebar .nav-item:focus > .nav-link .nav-icon svg, .app-sidebar .nav-item:hover > .nav-link .nav-icon i,
    .app-sidebar .nav-item:hover > .nav-link .nav-icon svg {
      color: #fff; }
  .app-sidebar .nav-item > .nav-submenu.nav-item.active > .nav-link, .app-sidebar .nav-item > .nav-submenu.nav-item:focus > .nav-link, .app-sidebar .nav-item > .nav-submenu.nav-item:hover > .nav-link {
    font-weight: 700;
    color: #51459d; }
    .app-sidebar .nav-item > .nav-submenu.nav-item.active > .nav-link .nav-icon i,
    .app-sidebar .nav-item > .nav-submenu.nav-item.active > .nav-link .nav-icon svg, .app-sidebar .nav-item > .nav-submenu.nav-item:focus > .nav-link .nav-icon i,
    .app-sidebar .nav-item > .nav-submenu.nav-item:focus > .nav-link .nav-icon svg, .app-sidebar .nav-item > .nav-submenu.nav-item:hover > .nav-link .nav-icon i,
    .app-sidebar .nav-item > .nav-submenu.nav-item:hover > .nav-link .nav-icon svg {
      color: #51459d; }
  .app-sidebar .nav-hasmenu.nav-provoke > .nav-submenu {
    display: block; }
  .app-sidebar .nav-hasmenu.nav-provoke > .nav-link > .nav-arrow {
    transform: rotate(90deg); }

.app-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2); }

.auth-wrapper ~ .theme-roller {
  display: none; }

.site-header {
  background: #fff;
  box-shadow: 0 10px 20px 0 rgba(50, 57, 61, 0.08);
  color: #525b69;
  min-height: 64px;
  position: fixed;
  left: 250px;
  right: 0;
  z-index: 1025; }
  .site-header .header-wrapper {
    display: flex;
    align-items: center;
    padding: 0 25px; }
  .site-header .header-menu-nav {
    margin-bottom: 0;
    display: flex;
    align-items: center; }
    .site-header .header-menu-nav .hdr-itm {
      display: flex;
      align-items: center;
      position: relative;
      padding: 10px 0; }
    .site-header .header-menu-nav .dropdown-toggle::after {
      content: unset; }
    .site-header .header-menu-nav .hdr-itm:not(:last-of-type) {
      margin: 0 15px 0 0; }
  .site-header .app-head-link {
    color: #51459d;
    display: inline-flex;
    align-items: center;
    padding: 0.6rem 0.7rem;
    position: relative;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid #DFECFD; }
    .site-header .app-head-link i {
      font-size: 18px; }
    .site-header .app-head-link svg {
      width: 16px;
      height: 16px; }
  .site-header .h-dots {
    position: absolute;
    width: 8px;
    height: 8px;
    top: 10px;
    right: 12px;
    padding: 0;
    border: 2px solid #fff;
    border-radius: 50%; }
  .site-header .app-head-link .avtar {
    width: 42px;
    height: 42px;
    font-size: 16px;
    border-radius: 10px;
    background: #dcdaeb;
    color: #51459d; }
  .site-header .user-dropdown .app-head-link {
    padding: 0;
    border-color: transparent; }
  .site-header .app-head-link .drop-arrow {
    margin-left: 5px;
    font-size: 15px; }
  .site-header .dropdown-menu {
    padding: 0 0;
    box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
    border: none; }
    .site-header .dropdown-menu .dropdown-item {
      padding: 0; }
      .site-header .dropdown-menu .dropdown-item .drp-link {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        color: #525b69;
        line-height: 1; }
        .site-header .dropdown-menu .dropdown-item .drp-link i {
          font-size: 16px;
          margin-right: 10px; }
        .site-header .dropdown-menu .dropdown-item .drp-link svg {
          width: 20px;
          height: 20px;
          margin-right: 10px; }
        .site-header .dropdown-menu .dropdown-item .drp-link .badge {
          margin-left: 5px; }
  .site-header .dropdown-menu {
    transform: none !important;
    top: 100% !important; }
  .site-header .ntf-dropdown .dropdown-menu {
    min-width: 400px; }
  .site-header .ntf-dropdown .notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 15px; }
  .site-header .ntf-dropdown .notification-body {
    padding: 0px 20px 15px;
    position: relative;
    max-height: calc(100vh - 360px);
    height: 100%; }
  .site-header .ntf-dropdown .notification-footer {
    padding: 15px 15px 15px; }
  .site-header .dropdown-divider {
    margin: 0; }
  .site-header .header-search {
    width: 65%; }
    .site-header .header-search .input-group input {
      padding: 0.575rem 3rem 0.575rem 1rem; }
    .site-header .header-search .input-group .search-btn {
      position: absolute;
      top: 7px;
      left: auto;
      right: 15px;
      z-index: 6; }
      .site-header .header-search .input-group .search-btn svg {
        display: inline-block; }

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition: filter 0.15s linear;
  width: 20px;
  height: 20px; }
  .hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    width: 18px;
    height: 2px;
    background-color: #51459d;
    border-radius: 4px;
    position: absolute;
    transition: transform 0.15s ease; }
    .hamburger .hamburger-inner::after, .hamburger .hamburger-inner::before {
      content: '';
      width: 18px;
      height: 2px;
      background-color: #51459d;
      border-radius: 4px;
      position: absolute;
      display: block;
      transition: transform 0.15s ease; }
    .hamburger .hamburger-inner::before {
      top: -6px; }
    .hamburger .hamburger-inner::after {
      bottom: -6px; }

.site-header .hdr-itm.mob-hamburger {
  display: none; }

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit; }

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0; }

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none; }

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0; }

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table; }

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none; }

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0; }

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all; }

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px; }

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: #95a4e1;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear; }

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px; }

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px; }

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0; }

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll; }

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none; }

.navbar-content {
  position: relative; }

.ps__rail-y {
  z-index: 5; }

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent; }

.ps__thumb-y {
  background: #95a4e1; }

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background: #a9b5e6; }

.theme-roller {
  position: fixed;
  right: -300px;
  top: 90px;
  z-index: 1025;
  transition: all 0.15s ease-in-out; }
  .theme-roller.active {
    right: 0; }
  .theme-roller .open-button {
    display: block;
    position: absolute;
    right: 100%;
    top: 0;
    transition: all 0.15s ease-in-out; }
    .theme-roller .open-button .btn {
      padding: 18px 17px;
      display: block;
      border-radius: 10px 0 0 10px;
      box-shadow: 0 9px 9px -1px rgba(166, 161, 201, 0.4); }
      .theme-roller .open-button .btn + .btn {
        margin-top: 8px; }
  .theme-roller .theme-roller-content {
    width: 300px;
    position: relative;
    top: 0;
    right: 0;
    background: #fff;
    overflow: hidden;
    border-radius: 0px 0px 0px 15px;
    box-shadow: 0 9px 9px -1px rgba(166, 161, 201, 0.4); }
    .theme-roller .theme-roller-content .presets-header {
      padding: 20px 30px;
      border-bottom: 1px solid #dddada; }
    .theme-roller .theme-roller-content .presets-list {
      padding: 20px 20px; }
  @media (max-width: 1024px) {
    .theme-roller {
      display: none; } }

.doc-img,
.themes-preference {
  display: block;
  position: relative;
  padding: 0;
  margin-top: 10px; }
  .doc-img > a,
  .themes-preference > a {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    display: inline-block;
    background: #f8f9fd;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.28); }
    .doc-img > a + a,
    .themes-preference > a + a {
      margin-left: 5px; }
    .doc-img > a span,
    .themes-preference > a span {
      width: 100%;
      position: absolute; }
      .doc-img > a span:after, .doc-img > a span:before,
      .themes-preference > a span:after,
      .themes-preference > a span:before {
        content: "";
        height: 100%;
        position: absolute; }
      .doc-img > a span:before,
      .themes-preference > a span:before {
        width: 32%;
        left: 0;
        background: #fff; }
      .doc-img > a span:after,
      .themes-preference > a span:after {
        width: 70%;
        right: 0;
        background: #f8f9fd; }
    .doc-img > a > span:nth-child(1),
    .themes-preference > a > span:nth-child(1) {
      height: 40%;
      top: 0; }
      .doc-img > a > span:nth-child(1):after,
      .themes-preference > a > span:nth-child(1):after {
        background: #fff; }
    .doc-img > a > span:nth-child(2),
    .themes-preference > a > span:nth-child(2) {
      height: 66%;
      bottom: 0; }
  .doc-img.header-color > a[data-value="bg-primary"] > span:nth-child(1):after,
  .themes-preference.header-color > a[data-value="bg-primary"] > span:nth-child(1):after {
    background: #51459d; }
  .doc-img.header-color > a[data-value="bg-success"] > span:nth-child(1):after,
  .themes-preference.header-color > a[data-value="bg-success"] > span:nth-child(1):after {
    background: #6fd943; }
  .doc-img.header-color > a[data-value="bg-info"] > span:nth-child(1):after,
  .themes-preference.header-color > a[data-value="bg-info"] > span:nth-child(1):after {
    background: #3ec9d6; }
  .doc-img.header-color > a[data-value="bg-warning"] > span:nth-child(1):after,
  .themes-preference.header-color > a[data-value="bg-warning"] > span:nth-child(1):after {
    background: #ffa21d; }
  .doc-img.header-color > a[data-value="bg-danger"] > span:nth-child(1):after,
  .themes-preference.header-color > a[data-value="bg-danger"] > span:nth-child(1):after {
    background: #ff3a6e; }
  .doc-img.header-color > a[data-value="bg-dark"] > span:nth-child(1):after,
  .themes-preference.header-color > a[data-value="bg-dark"] > span:nth-child(1):after {
    background: #1c232f; }
  .doc-img.brand-color > a[data-value="bg-primary"] > span:nth-child(1):before,
  .themes-preference.brand-color > a[data-value="bg-primary"] > span:nth-child(1):before {
    background: #51459d; }
  .doc-img.brand-color > a[data-value="bg-success"] > span:nth-child(1):before,
  .themes-preference.brand-color > a[data-value="bg-success"] > span:nth-child(1):before {
    background: #6fd943; }
  .doc-img.brand-color > a[data-value="bg-info"] > span:nth-child(1):before,
  .themes-preference.brand-color > a[data-value="bg-info"] > span:nth-child(1):before {
    background: #3ec9d6; }
  .doc-img.brand-color > a[data-value="bg-warning"] > span:nth-child(1):before,
  .themes-preference.brand-color > a[data-value="bg-warning"] > span:nth-child(1):before {
    background: #ffa21d; }
  .doc-img.brand-color > a[data-value="bg-danger"] > span:nth-child(1):before,
  .themes-preference.brand-color > a[data-value="bg-danger"] > span:nth-child(1):before {
    background: #ff3a6e; }
  .doc-img.brand-color > a[data-value="bg-dark"] > span:nth-child(1):before,
  .themes-preference.brand-color > a[data-value="bg-dark"] > span:nth-child(1):before {
    background: #1c232f; }
  .doc-img.themes-preference > a[data-value="theme-1"],
  .themes-preference.themes-preference > a[data-value="theme-1"] {
    background: linear-gradient(141.55deg, #7928ca 3.46%, #ff0080 99.86%), #7928ca; }
  .doc-img.themes-preference > a[data-value="theme-2"],
  .themes-preference.themes-preference > a[data-value="theme-2"] {
    background: linear-gradient(to right, #2c3e50, #4ca1af), #2c3e50; }
  .doc-img.themes-preference > a[data-value="theme-3"],
  .themes-preference.themes-preference > a[data-value="theme-3"] {
    background: linear-gradient(270deg, #37006F 0%, #8905D3 100%), #8905D3; }
  .doc-img.themes-preference > a[data-value="theme-4"],
  .themes-preference.themes-preference > a[data-value="theme-4"] {
    background: linear-gradient(141.55deg, rgba(104, 94, 229, 0) 3.46%, #685ee5 99.86%), #584ed2; }

.doc-img > a {
  width: 100px;
  height: 65px; }

.content-container {
  position: relative;
  top: 64px;
  margin-left: 250px;
  min-height: calc(100vh - 64px); }
  .content-container .page-content {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 30px; }
  .content-container .content-header + .row {
    padding-top: 25px; }
  .content-container .content-header + .page-content {
    padding-top: 25px; }

.content-header h1, .content-header .h1 {
  font-size: 1.8rem; }

.content-header .breadcrumb {
  margin-bottom: 0; }
  .content-header .breadcrumb .breadcrumb-item {
    line-height: 1; }
    .content-header .breadcrumb .breadcrumb-item::before {
      display: inline-block; }

.breadcrumb svg.feather {
  width: 16px;
  height: 16px;
  vertical-align: baseline;
  display: inline-block; }

.card {
  margin-bottom: 24px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s ease-in-out; }
  .card .card-header {
    padding: 20px 20px;
    border-bottom: 1px solid #dddada; }
    .card .card-header h4, .card .card-header .h4 {
      margin-bottom: 0; }
  .card .card-body {
    padding: 1rem; }
  .card.bg-primary {
    box-shadow: 0 9px 9px -1px rgba(81, 69, 157, 0.3); }
  .card.bg-secondary {
    box-shadow: 0 9px 9px -1px rgba(108, 117, 125, 0.3); }
  .card.bg-success {
    box-shadow: 0 9px 9px -1px rgba(111, 217, 67, 0.3); }
  .card.bg-info {
    box-shadow: 0 9px 9px -1px rgba(62, 201, 214, 0.3); }
  .card.bg-warning {
    box-shadow: 0 9px 9px -1px rgba(255, 162, 29, 0.3); }
  .card.bg-danger {
    box-shadow: 0 9px 9px -1px rgba(255, 58, 110, 0.3); }
  .card.bg-light {
    box-shadow: 0 9px 9px -1px rgba(248, 249, 250, 0.3); }
  .card.bg-dark {
    box-shadow: 0 9px 9px -1px rgba(28, 35, 47, 0.3); }

.card-group .card {
  box-shadow: none; }

.position-fixed.top-0.end-0 {
  top: 64px !important; }

.app-footer {
  position: relative;
  top: 60px;
  margin-left: 250px; }
  .app-footer .footer-wrapper {
    padding: 10px 15px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.kanban-wrapper {
  position: relative;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden; }

.kanban-wrapper > .col {
  width: 390px;
  flex: 0 0 auto; }

.notifier-container {
  z-index: 99999 !important; }

@media (max-width: 1024px) {
  .site-header .hdr-itm.mob-hamburger {
    display: inline-block !important; }
  .app-sidebar {
    left: -250px;
    box-shadow: none;
    transition: all 0.15s ease-in-out; }
    .app-sidebar.mob-sidebar-active {
      left: 0; }
  .page-content-wrapper .content-container {
    margin-left: 0; }
  .site-header {
    top: 0;
    left: 0 !important;
    transition: all 0.15s ease-in-out; }
  .app-footer {
    margin-left: 0 !important; }
  .app-sidebar.mob-sidebar-active .app-navbar-wrapper {
    position: relative;
    background: inherit;
    z-index: 5; } }

@media (max-width: 767px) {
  .content-container .page-content {
    padding-left: 15px;
    padding-right: 15px; }
  .site-header .header-wrapper {
    padding: 0 15px; }
  .site-header .ntf-dropdown .notification-body {
    max-height: calc(100vh - 220px); } }

@media (max-width: 575.98px) {
  .card.table-card .card-header.d-flex {
    flex-wrap: wrap; }
  .card.table-card .card-header.d-flex .btn-group {
    flex: 0 0 100%;
    margin-top: 15px; }
  .app-footer .footer-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  .site-header .header-menu-nav .hdr-itm {
    position: static; }
  .site-header .ntf-dropdown .dropdown-menu {
    left: 0 !important;
    right: 0 !important;
    min-width: 100% !important; } }

.mrr-card {
  position: relative; }
  .mrr-card .bottom-chart {
    bottom: 0; }

.widget-last-task .img-radius {
  margin-right: 10px;
  margin-bottom: 5px; }

.widget-last-task span.float-right {
  font-size: 11px; }

.client-map img {
  vertical-align: top;
  width: 90px; }

.client-map .client-detail {
  margin-bottom: 20px; }
  .client-map .client-detail img {
    border-radius: 5px; }

.client-map .client-profile {
  float: left; }

.client-map .client-contain {
  display: inline-block;
  margin-left: 20px; }
  .client-map .client-contain h5, .client-map .client-contain .h5 {
    display: block;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 10px; }

.client-map .client-card-box {
  border: 1px solid #dddada;
  border-radius: 5px; }
  .client-map .client-card-box .client-border {
    border-right: 1px solid #dddada; }
  .client-map .client-card-box .client-border-card {
    border-top: 1px solid #dddada; }

.marketing-card .table thead th {
  border-bottom: none;
  border-top: none; }

.marketing-card .table tbody tr:first-child td {
  border-top: none; }

.marketing-card .table tbody .marketing-header {
  background-color: rgba(248, 249, 253, 0.5); }

.marketing-card .table tbody td {
  vertical-align: middle; }
  .marketing-card .table tbody td p {
    margin-bottom: 0;
    display: inline-block; }
  .marketing-card .table tbody td .table-contain {
    display: inline-block;
    margin-left: 10px; }
  .marketing-card .table tbody td span {
    font-weight: 600; }
  .marketing-card .table tbody td h6, .marketing-card .table tbody td .h6 {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5; }
  .marketing-card .table tbody td .dropdown-toggle {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0; }
    .marketing-card .table tbody td .dropdown-toggle i {
      margin-right: 0; }
    .marketing-card .table tbody td .dropdown-toggle:after {
      display: none; }
  .marketing-card .table tbody td .social-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    top: -10px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .marketing-card .table tbody td .btn-action {
    position: relative;
    padding: 6px 18px 6px 32px; }
    .marketing-card .table tbody td .btn-action:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      left: 12px;
      top: 10px; }
    .marketing-card .table tbody td .btn-action.btn-bg-primary:after {
      background-color: #51459d; }
    .marketing-card .table tbody td .btn-action.btn-bg-danger:after {
      background-color: #ff3a6e; }
    .marketing-card .table tbody td .btn-action.btn-bg-success:after {
      background-color: #6fd943; }
    .marketing-card .table tbody td .btn-action.btn-bg-warning:after {
      background-color: #ffa21d; }
    .marketing-card .table tbody td .btn-action.btn-bg-info:after {
      background-color: #3ec9d6; }
    .marketing-card .table tbody td .btn-action.btn-bg-info:after {
      background-color: #3ec9d6; }

.marketing-card .marketing-card-footer .tab-cont > p {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-weight: 600;
  margin-top: -8px; }
  .marketing-card .marketing-card-footer .tab-cont > p i {
    font-size: 20px;
    margin: 0 5px; }

.testimonial-card .progress {
  height: 5px; }

.testimonial-card .review-block .cust-img {
  width: 40px;
  height: 40px; }

.testimonial-card .review-block > div {
  padding-top: 15px;
  padding-bottom: 9px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px; }
  .testimonial-card .review-block > div:hover {
    background-color: rgba(81, 69, 157, 0.1); }

.new-cust-card img {
  width: 40px; }

.new-cust-card h6, .new-cust-card .h6 {
  margin-bottom: 0; }

.new-cust-card .align-middle {
  position: relative; }
  .new-cust-card .align-middle .status {
    position: absolute;
    right: 0;
    top: 19px;
    font-size: 13px; }
    .new-cust-card .align-middle .status.active {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #6fd943; }

.review-card .review-block .cust-img {
  width: 50px;
  height: 50px; }

.review-card .review-block > div {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px; }
  .review-card .review-block > div:hover {
    background-color: rgba(81, 69, 157, 0.1); }

.latest-activity-card .card-body {
  padding-top: 0; }
  .latest-activity-card .card-body .latest-update-box {
    position: relative; }
    .latest-activity-card .card-body .latest-update-box .update-meta {
      z-index: 2;
      min-width: 170px; }
      .latest-activity-card .card-body .latest-update-box .update-meta .update-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        padding: 12px 13px;
        font-size: 16px;
        color: #fff;
        margin-left: 10px; }
      @media only screen and (max-width: 575px) {
        .latest-activity-card .card-body .latest-update-box .update-meta {
          z-index: 2;
          min-width: 100%;
          text-align: left !important;
          margin-bottom: 15px;
          border-top: 1px solid #f1f1f1;
          padding-top: 15px; } }

.task-card .task-list {
  position: relative; }
  .task-card .task-list:after {
    content: "";
    position: absolute;
    background: #dddada;
    height: 100%;
    width: 2px;
    top: 0;
    left: 10px;
    z-index: 1; }
  .task-card .task-list:before {
    content: "";
    position: absolute;
    background: #dddada;
    height: 15px;
    width: 15px;
    bottom: -14px;
    left: 3px;
    z-index: 2;
    border-radius: 50%; }
  .task-card .task-list li {
    margin-bottom: 30px;
    padding-left: 30px;
    position: relative; }
    .task-card .task-list li .task-icon {
      position: absolute;
      left: 3px;
      top: 1px;
      border-radius: 50%;
      padding: 2px;
      color: #fff;
      min-width: 15px;
      min-height: 15px;
      z-index: 2; }

.latest-posts-card a {
  transition: all 0.3s ease-in-out; }
  .latest-posts-card a:hover h6, .latest-posts-card a:hover .h6 {
    color: #51459d; }
  .latest-posts-card a ~ p {
    font-size: 11px; }

.latest-posts-card .latest-posts-img {
  width: 90px;
  height: 80px; }

.latest-update-card .card-body {
  padding-top: 0; }
  .latest-update-card .card-body .latest-update-box {
    position: relative; }
    .latest-update-card .card-body .latest-update-box:after {
      content: "";
      position: absolute;
      background: #ebebeb;
      height: 100%;
      width: 3px;
      top: 0;
      left: 82px;
      z-index: 1; }
    .latest-update-card .card-body .latest-update-box .update-meta {
      z-index: 2; }
      .latest-update-card .card-body .latest-update-box .update-meta .update-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        padding: 7px 8px;
        font-size: 16px;
        color: #fff; }
        .latest-update-card .card-body .latest-update-box .update-meta .update-icon.ring {
          border-width: 3px;
          width: 15px;
          height: 15px;
          padding: 0;
          display: block;
          background: #fff; }
      .latest-update-card .card-body .latest-update-box .update-meta i.update-icon {
        margin-left: 4px;
        margin-right: 4px; }
      .latest-update-card .card-body .latest-update-box .update-meta img.update-icon {
        padding: 0;
        width: 40px;
        height: 40px; }

.chat-card .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 10px;
  position: relative; }
  .chat-card .msg img {
    width: 60px;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 10px; }

.chat-card .received-chat .msg {
  background: #aba4d7;
  border-radius: 0 5px 5px 5px; }
  .chat-card .received-chat .msg:after {
    content: "";
    position: absolute;
    left: -7px;
    top: -7px;
    transform: rotate(45deg);
    border: 7px solid transparent;
    border-bottom-color: #aba4d7; }

.chat-card .send-chat {
  text-align: right; }
  .chat-card .send-chat .msg {
    background: #51459d;
    color: #fff;
    border-radius: 5px 0 5px 5px; }
    .chat-card .send-chat .msg:after {
      content: "";
      position: absolute;
      right: -7px;
      top: -7px;
      transform: rotate(45deg);
      border: 7px solid transparent;
      border-right-color: #51459d; }

.user-Messages-card .text-time {
  font-size: 11px; }

.user-Messages-card .u-img {
  position: relative; }
  .user-Messages-card .u-img .profile-img {
    width: 40px;
    height: 40px; }
  .user-Messages-card .u-img .tot-msg {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 0;
    right: -5px;
    background-color: #ff3a6e;
    color: #fff;
    font-size: 10px;
    border-radius: 50%;
    text-align: center; }

.user-activity-card .u-img {
  position: relative; }
  .user-activity-card .u-img .cover-img {
    width: 40px;
    height: 40px; }
  .user-activity-card .u-img .profile-img {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: -5px; }

.product-progress-card .progress {
  height: 6px; }

.product-progress-card .pp-cont {
  padding-left: 15px;
  padding-right: 15px;
  position: relative; }
  .product-progress-card .pp-cont:after {
    content: "";
    background: #d2d2d2;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -15px; }
  @media only screen and (max-width: 991px) {
    .product-progress-card .pp-cont {
      padding: 15px 0; }
      .product-progress-card .pp-cont:after {
        display: none; } }

.product-progress-card .pp-main > div:first-child > .pp-cont:after {
  display: none; }

.social-res-card .progress {
  height: 6px;
  border-radius: 4px; }
  .social-res-card .progress .progress-bar {
    border-radius: 4px; }

.analytic-card .analytic-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 17px 0;
  border-radius: 50%;
  background-color: #fff; }

.ticket-card .lbl-card {
  border-radius: 50px;
  padding: 5px 15px;
  display: inline-block; }

.proj-t-card {
  position: relative;
  overflow: hidden; }
  .proj-t-card .pt-badge {
    margin-bottom: 0;
    display: inline-block;
    padding: 60px 50px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    right: -35px;
    transition: all 0.3s ease-in-out; }

.proj-progress-card .progress {
  height: 6px;
  overflow: visible;
  margin-bottom: 10px; }

@media only screen and (max-width: 992px) {
  .proj-progress-card h6, .proj-progress-card .h6 {
    margin-top: 15px; }
  .proj-progress-card .progress {
    margin-bottom: 20px; } }

.prod-p-card i {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 11px 0;
  font-size: 29px; }

.comp-card i {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 17px 0;
  font-size: 18px; }

.social-widget-card {
  border-top: none;
  color: #fff; }
  .social-widget-card:hover i {
    opacity: 1;
    transform: scale(1.1); }
  .social-widget-card i {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 48px;
    opacity: 0.4;
    transition: all ease-in 0.3s; }

.widget-primary-card.flat-card, .flat-card.widget-purple-card {
  border-top: none;
  background-color: #51459d;
  color: #fff; }
  .widget-primary-card.flat-card h4, .flat-card.widget-purple-card h4, .widget-primary-card.flat-card .h4, .flat-card.widget-purple-card .h4,
  .widget-primary-card.flat-card h6,
  .flat-card.widget-purple-card h6,
  .widget-primary-card.flat-card .h6,
  .flat-card.widget-purple-card .h6 {
    color: #fff; }
  .widget-primary-card.flat-card .row-table:first-child, .flat-card.widget-purple-card .row-table:first-child {
    border-bottom: none; }
  .widget-primary-card.flat-card .row-table > [class*="col-"], .flat-card.widget-purple-card .row-table > [class*="col-"] {
    border-bottom: none;
    display: inline-block;
    vertical-align: middle; }
    .widget-primary-card.flat-card .row-table > [class*="col-"]:first-child, .flat-card.widget-purple-card .row-table > [class*="col-"]:first-child {
      background-color: darkslateblue;
      text-align: center; }
    .widget-primary-card.flat-card .row-table > [class*="col-"] .row, .flat-card.widget-purple-card .row-table > [class*="col-"] .row {
      display: flex;
      align-items: center; }

.widget-purple-card.flat-card {
  background-color: #6fd943; }
  .widget-purple-card.flat-card .row-table:first-child {
    border-bottom: none; }
  .widget-purple-card.flat-card .row-table > [class*="col-"] {
    border-bottom: none; }
    .widget-purple-card.flat-card .row-table > [class*="col-"]:first-child {
      background-color: #5fd52e; }

.flat-card .row-table {
  display: table;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0; }
  .flat-card .row-table .br {
    border-right: 1px solid #dddada; }
  .flat-card .row-table i {
    font-size: 30px; }
  .flat-card .row-table span {
    text-transform: uppercase;
    font-size: 12px; }
  .flat-card .row-table h5, .flat-card .row-table .h5 {
    display: block;
    margin-bottom: 0.3em;
    margin-right: 0; }
  .flat-card .row-table > [class*="col-"] {
    display: inline-block;
    float: none;
    table-layout: fixed;
    vertical-align: middle;
    width: calc(100% / 3); }
    .flat-card .row-table > [class*="col-"] .row {
      display: flex;
      align-items: center; }
  @media (max-width: 575.98px) {
    .flat-card .row-table {
      display: block; }
      .flat-card .row-table > [class*="col-"] {
        display: inline-block;
        width: calc(50% - 2px) !important;
        border-bottom: 1px solid #dddada; } }

.flat-card[class*="widget-"] .row-table {
  display: flex; }

.balance-card .progress {
  width: 100%;
  height: 8px;
  background-color: #f8f9fd;
  border-radius: 30px; }
  .balance-card .progress .progress-bar {
    border-radius: 30px; }

.card-progress .progress {
  height: 8px;
  width: 100%;
  position: relative;
  top: 8px;
  left: 0;
  border-radius: 0;
  overflow: inherit; }

.feed-card h6, .feed-card .h6 {
  margin-top: 7px;
  font-size: 14px;
  transition: all 0.3s ease-in-out; }
  .feed-card h6 > span, .feed-card .h6 > span {
    font-size: 11px; }
  .feed-card h6:hover, .feed-card .h6:hover {
    color: #51459d; }

.feed-card .feed-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 8px 9px; }

.feed-card .card-body {
  position: relative;
  padding-left: 25px; }
  .feed-card .card-body.p-t-0.p-b-0 {
    padding-left: 12px; }
  .feed-card .card-body .border-feed {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: all 0.3s ease-in-out;
    position: relative; }
    .feed-card .card-body .border-feed i {
      position: absolute;
      top: calc(50% - 20px);
      left: 0;
      right: 0;
      text-align: center;
      color: #fff;
      transition: all 0.3s ease-in-out; }
      .feed-card .card-body .border-feed i.material-icons-two-tone {
        background-color: #fff; }

.feed-card:hover .border-feed.bg-primary {
  background-color: rgba(81, 69, 157, 0.3) !important; }
  .feed-card:hover .border-feed.bg-primary i {
    color: #51459d; }
    .feed-card:hover .border-feed.bg-primary i.material-icons-two-tone {
      background-color: #51459d; }

.feed-card:hover .border-feed.bg-danger {
  background-color: rgba(255, 58, 110, 0.3) !important; }
  .feed-card:hover .border-feed.bg-danger i {
    color: #ff3a6e; }
    .feed-card:hover .border-feed.bg-danger i.material-icons-two-tone {
      background-color: #ff3a6e; }

.feed-card:hover .border-feed.bg-success {
  background-color: rgba(111, 217, 67, 0.3) !important; }
  .feed-card:hover .border-feed.bg-success i {
    color: #6fd943; }
    .feed-card:hover .border-feed.bg-success i.material-icons-two-tone {
      background-color: #6fd943; }

.feed-card:hover .border-feed.bg-warning {
  background-color: rgba(255, 162, 29, 0.3) !important; }
  .feed-card:hover .border-feed.bg-warning i {
    color: #ffa21d; }
    .feed-card:hover .border-feed.bg-warning i.material-icons-two-tone {
      background-color: #ffa21d; }

.total-request-card .progress {
  width: 85%;
  float: left;
  height: 5px;
  text-align: center;
  margin: 0 auto;
  background-color: #f8f9fd;
  border-radius: 30px; }
  .total-request-card .progress .progress-bar {
    border-radius: 30px; }

.total-request-card hr {
  width: 50px;
  border-top: 1px solid #dddada;
  margin-top: 20px;
  margin-bottom: 20px; }

.total-request-card i {
  position: relative;
  top: 5px; }

.widget-statstic-card {
  position: relative;
  overflow: hidden; }
  .widget-statstic-card .st-icon {
    color: #fff;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.05); }
    .widget-statstic-card .st-icon > svg {
      transition: all 0.3s ease-in-out;
      width: 23px;
      height: 23px; }
  .widget-statstic-card h2, .widget-statstic-card .h2 {
    font-weight: 600;
    display: inline-block; }
  .widget-statstic-card span {
    border-radius: 30px;
    padding: 5px 20px;
    color: #fff;
    font-weight: 600; }
  .widget-statstic-card:hover .st-icon > svg {
    width: 50px;
    height: 50px; }

.order-card {
  color: #fff;
  overflow: hidden; }
  .order-card .card-icon {
    position: absolute;
    right: -17px;
    top: 20px;
    font-size: 100px;
    opacity: 0.5; }
    .order-card .card-icon > svg {
      width: 100px;
      height: 100px; }

.statustic-card .card-body {
  position: relative; }
  .statustic-card .card-body .progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 5px;
    overflow: visible; }
    .statustic-card .card-body .progress .progress-bar {
      position: relative;
      overflow: visible;
      box-shadow: none; }
      .statustic-card .card-body .progress .progress-bar:before {
        content: "";
        height: 5px;
        width: 5px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        background: inherit; }
      .statustic-card .card-body .progress .progress-bar.bg-primary:before {
        animation: blue-blink-bar 0.5s linear infinite; }
      .statustic-card .card-body .progress .progress-bar.bg-success:before {
        animation: green-blink-bar 0.5s linear infinite; }
      .statustic-card .card-body .progress .progress-bar.bg-danger:before {
        animation: red-blink-bar 0.5s linear infinite; }
      .statustic-card .card-body .progress .progress-bar.bg-warning:before {
        animation: yellow-blink-bar 0.5s linear infinite; }

@keyframes blue-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(81, 69, 157, 0.1); }
  50% {
    box-shadow: 0 0 0 6px rgba(81, 69, 157, 0.3); } }

@keyframes green-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(111, 217, 67, 0.1); }
  50% {
    box-shadow: 0 0 0 6px rgba(111, 217, 67, 0.3); } }

@keyframes red-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 58, 110, 0.1); }
  50% {
    box-shadow: 0 0 0 6px rgba(255, 58, 110, 0.3); } }

@keyframes yellow-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 162, 29, 0.1); }
  50% {
    box-shadow: 0 0 0 6px rgba(255, 162, 29, 0.3); } }

.social-card {
  color: #fff;
  overflow: hidden; }
  .social-card .social-icon {
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    padding: 22px 23px; }
  .social-card .download-icon {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    height: 125px;
    width: 125px;
    right: -125px;
    padding: 47px 22px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out; }
    .social-card .download-icon > svg {
      width: 35px;
      height: 35px; }
  .social-card:hover .download-icon {
    right: -60px; }

.order-visitor-card {
  cursor: pointer; }
  .order-visitor-card:hover {
    background-color: #51459d; }
    .order-visitor-card:hover h4, .order-visitor-card:hover .h4,
    .order-visitor-card:hover h6,
    .order-visitor-card:hover .h6,
    .order-visitor-card:hover p {
      color: #fff; }

.widget-visitor-card {
  overflow: hidden;
  padding: 10px 0; }
  .widget-visitor-card i,
  .widget-visitor-card svg {
    font-size: 80px;
    color: #fff;
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: -10px;
    opacity: 0.3;
    left: -10px;
    transform: rotate(15deg);
    transition: all 0.3s ease-in-out; }
  .widget-visitor-card:hover svg {
    transform: rotate(0deg) scale(1.4);
    opacity: 0.5; }

.app-design .btn {
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px; }

.app-design .team-section img {
  width: 35px;
  border-radius: 5px; }

.app-design .progress-box p {
  margin-bottom: 0; }

.app-design .progress-box .progress {
  width: calc(100% - 80px);
  height: 8px;
  text-align: center;
  margin: 0 auto;
  background-color: #e5e5e5;
  border-radius: 30px;
  position: relative;
  overflow: inherit; }
  .app-design .progress-box .progress .progress-bar {
    border-radius: 30px; }
    .app-design .progress-box .progress .progress-bar label {
      position: absolute;
      top: -24px;
      right: 0;
      font-weight: 600;
      font-size: 13px; }

.user-card-full {
  overflow: hidden; }
  .user-card-full .user-profile-side {
    border-radius: 5px 0 0 5px; }
  .user-card-full .social-link li {
    display: inline-block; }
    .user-card-full .social-link li a {
      color: #293240;
      margin: 0 10px 0 0;
      transition: all 0.3s ease-in-out; }
      .user-card-full .social-link li a svg {
        width: 20px;
        height: 20px; }

.img-radius {
  border-radius: 50%; }

.quater-card .progress {
  height: 4px; }

.statustic-progress-card .progress {
  height: 5px; }

.trafic-card .progress {
  height: 7px; }
  .trafic-card .progress.blue {
    background-color: rgba(81, 69, 157, 0.5); }
  .trafic-card .progress.green {
    background-color: rgba(111, 217, 67, 0.5); }
  .trafic-card .progress.red {
    background-color: rgba(255, 58, 110, 0.5); }
  .trafic-card .progress.purple {
    background-color: rgba(62, 201, 214, 0.5); }
  .trafic-card .progress.yellow {
    background-color: rgba(255, 162, 29, 0.5); }

.total-card {
  overflow: hidden; }
  .total-card .text-start h4, .total-card .text-start .h4 {
    color: #fff;
    font-weight: 400;
    margin-bottom: 5px; }
  .total-card .text-start p {
    color: #fff;
    font-size: 15px; }

.table-card .row-table {
  display: flex;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0; }
  .table-card .row-table svg {
    margin: 0 20px; }
  .table-card .row-table > [class*="col-"] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle; }
    .table-card .row-table > [class*="col-"] .row {
      display: flex;
      align-items: center; }

.rides-bar .rides-icon {
  background: #51459d;
  border-radius: 50px;
  padding: 15px; }

.visitor .img-female {
  position: absolute;
  left: 10px;
  bottom: 0; }

.visitor .img-men {
  position: absolute;
  right: 10px;
  bottom: 0; }

.profit-bar .mon-icon {
  padding: 20px;
  border-radius: 50px;
  display: inline-block;
  float: right; }
  .profit-bar .mon-icon svg {
    width: 25px;
    height: 25px; }

.user-card .label {
  border-radius: 15px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 0;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.05); }

.leads-progress .progress,
.progress-gender .progress {
  justify-content: center;
  height: 10px;
  border-radius: 0; }

.Active-visitor .progress {
  height: 7px; }

.Active-visitor .card-active > div + div {
  border-left: 1px solid #dddada; }

@media screen and (max-width: 767px) {
  .Active-visitor .card-active [class*="col-"]:last-child {
    border-left: 0;
    margin-top: 20px; } }

.card-Revenue .rev-icon {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out; }

.Invoice-bar .invoice-lable label {
  background: #fff;
  border-radius: 15px;
  padding: 4px 20px; }

.Invoice-bar .inv-icon {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out; }

.Invoice-bar .progress {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 0;
  height: 7px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }

.location-sale .card-icon {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .location-sale .card-icon {
      display: none; } }

.card-Impression .imp-icon {
  width: 52px;
  height: 52px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.05); }

.card-customer .hct-icon {
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.05); }

@media screen and (max-width: 375px) {
  .card-customer .hct-icon {
    width: 80px;
    height: 80px;
    font-size: 50px; } }

.ticket-customer .tick-icon {
  position: absolute;
  opacity: 0.2;
  right: 32px;
  bottom: 30px; }

.customer-visitor .vis-icon {
  opacity: 0.2;
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 0; }

.card-social:hover i {
  font-size: 40px;
  transition: all 0.3s ease-in-out; }

.card-social .progress {
  height: 6px; }

.card-social .card-active > div + div {
  border-left: 1px solid #dddada; }

.Online-Order .progress,
.statistial-visit .progress {
  height: 6px; }

.affilate-offers .card-icon {
  opacity: 0.5; }

.affilate-offers .label {
  border-radius: 15px;
  margin: 0;
  padding: 8px 18px; }

.team-leader .slide li {
  width: 10px;
  height: 10px;
  background-color: #dcdaeb;
  margin: 0 6px;
  border-radius: 50px; }

.team-leader .slide .carousel-indicators {
  margin-bottom: -10px;
  margin-top: 10px; }
  .team-leader .slide .carousel-indicators .active {
    background-color: #51459d; }

.earning-date .bd-example {
  padding: 0;
  margin: 0;
  border: 0; }
  .earning-date .bd-example .nav-pills {
    background: transparent;
    padding: 0;
    box-shadow: none; }
    .earning-date .bd-example .nav-pills .nav-link {
      min-width: auto;
      padding: 6px 20px;
      color: #fff; }
    .earning-date .bd-example .nav-pills .nav-link.active {
      position: relative;
      background: #fff;
      box-shadow: none;
      color: #51459d; }
      .earning-date .bd-example .nav-pills .nav-link.active:after {
        content: "";
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        top: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 11px; }
  .earning-date .bd-example .tab-content {
    background: transparent;
    padding: 0;
    box-shadow: none; }

.sale-view .sale-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.project-task svg {
  opacity: 0.5; }

.project-task .label {
  border-radius: 15px;
  margin: 0;
  padding: 6px 16px; }

.project-task .progress {
  height: 6px; }

.summary-box .card-icon {
  opacity: 0.4; }

.card-event .label {
  border-radius: 15px;
  margin: 0;
  padding: 5px 16px; }

.card-event svg {
  position: absolute;
  bottom: 20px;
  right: 25px; }

.statistial-visit .label {
  padding: 4px 10px;
  border-radius: 40px; }

.Design-sprint .design-image,
.dashboard-kit .design-image {
  padding: 0;
  margin: 30px 0 0;
  list-style: none; }

.Design-sprint li,
.dashboard-kit li {
  display: inline-block;
  text-align: center;
  margin-right: 3px; }

.Design-sprint .btn,
.dashboard-kit .btn {
  border-radius: 50px;
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0; }

.Design-sprint i,
.dashboard-kit i {
  opacity: 0.3; }

.post-emoticon ul {
  padding: 0;
  margin: 25px 0 0;
  list-style: none; }

.post-emoticon li {
  display: inline-block; }

.ux-designer {
  position: relative;
  padding: 35px 30px; }
  .ux-designer .btn {
    border-radius: 50px;
    border: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 20px;
    top: -20px;
    padding: 0;
    margin: 0; }

.visa-top .visa img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }

.loction-user .row {
  padding: 35px 30px; }

.loction-user svg {
  opacity: 0.5; }

.loction-user .loction-progress {
  padding: 35px 30px; }
  .loction-user .loction-progress .progress {
    justify-content: center;
    height: 10px;
    border-radius: 0; }

.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 30px 0 0; }
  .task-list:after {
    content: "";
    position: absolute;
    background: #ecedef;
    height: 100%;
    width: 2px;
    top: 0;
    left: 30px;
    z-index: 1; }
  .task-list li {
    margin-bottom: 30px;
    padding-left: 55px;
    position: relative; }
    .task-list li:last-child {
      margin-bottom: 0; }
    .task-list li .task-icon {
      position: absolute;
      left: 22px;
      top: 13px;
      border-radius: 50%;
      padding: 2px;
      width: 17px;
      height: 17px;
      z-index: 2;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.project-main svg {
  opacity: 0.4; }

.Application-list .table td,
.code-table .table td {
  vertical-align: middle; }

.Application-list .table .label,
.code-table .table .label {
  padding: 4px 10px;
  border-radius: 15px; }

.Application-list thead th,
.code-table thead th {
  border: 0; }

.chat-sanders .card-header {
  background: linear-gradient(-135deg, #3f4d67 0%, #3f4d67 100%); }

.chat-sanders .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 15px;
  position: relative; }

.chat-sanders .scroll-wrapper {
  height: 305px; }

.chat-sanders .received-chat h5, .chat-sanders .received-chat .h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center; }

.chat-sanders .received-chat .msg {
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }
  .chat-sanders .received-chat .msg:after {
    content: "";
    position: absolute;
    left: -12px;
    bottom: 18px;
    transform: rotate(270deg);
    border: 6px solid transparent;
    border-bottom-color: #fff; }

.chat-sanders .send-chat h5, .chat-sanders .send-chat .h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center; }

.chat-sanders .send-chat .msg {
  background: #51459d;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }
  .chat-sanders .send-chat .msg:after {
    content: "";
    position: absolute;
    right: -11px;
    bottom: 18px;
    transform: rotate(450deg);
    border: 6px solid transparent;
    border-bottom-color: #51459d; }

.note-bar .friendlist-box {
  padding: 25px 35px;
  border-top: 1px solid #dddada; }
  .note-bar .friendlist-box:first-child {
    border: 0; }
  .note-bar .friendlist-box h6, .note-bar .friendlist-box .h6 {
    display: inline-block; }
  .note-bar .friendlist-box svg {
    opacity: 0.2;
    color: #293240; }

.widget-content .widget-lorem .media h5, .widget-content .widget-lorem .media .h5 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center; }

.social-media .progress {
  background: transparent;
  border-radius: 0;
  justify-content: center; }
  .social-media .progress h5, .social-media .progress .h5 {
    position: relative;
    top: -2px; }

.avtar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 40px;
  height: 40px; }
  .avtar.avtar-xs {
    width: 32px;
    height: 32px;
    font-size: 12px; }
  .avtar.avtar-s {
    width: 40px;
    height: 40px;
    font-size: 14px; }
  .avtar.avtar-l {
    width: 64px;
    height: 64px;
    font-size: 20px; }
  .avtar.avtar-xl {
    width: 80px;
    height: 80px;
    font-size: 24px; }
  .avtar.avtar-square {
    border-radius: 4px;
    border: 2px solid; }
  .avtar.avtar-icon {
    border: 2px solid #dddada; }
    .avtar.avtar-icon > img {
      width: 60%; }
  .avtar img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.icon-shape {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 26px;
  font-weight: 600; }

.table.table-align-center td,
.table.table-align-center th {
  vertical-align: middle; }

.table thead th {
  padding: 0.9rem 0.75rem; }

.table td,
.table th {
  vertical-align: middle; }

.table.table-borderless td,
.table.table-borderless th {
  border: none !important; }

body .table thead th {
  font-size: 12px; }

.table-hover tbody tr:hover {
  background-color: rgba(81, 69, 157, 0.03); }

.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th {
  vertical-align: middle; }

.table th.text-end[data-sortable] {
  padding-right: 16px; }
  .table th.text-end[data-sortable] .dataTable-sorter::before,
  .table th.text-end[data-sortable] .dataTable-sorter::after {
    right: -14px; }

.dataTable-table tfoot tr th,
.dataTable-table tfoot tr td,
.dataTable-table thead tr th,
.dataTable-table thead tr td,
.dataTable-table tbody tr th,
.dataTable-table tbody tr td {
  padding: 0.9rem 0.75rem; }

.card.table-card .card-body {
  padding: 0; }

.card.table-card table {
  margin-bottom: 0; }
  .card.table-card table tr:last-child td {
    border-bottom: none; }
  .card.table-card table tr:first-child td {
    border-top: none; }

.dataTable-wrapper.no-footer .dataTable-container {
  border-bottom: none; }

.dataTable-dropdown label {
  display: flex;
  align-items: center;
  white-space: nowrap; }
  .dataTable-dropdown label select.dataTable-selector {
    width: 75px;
    margin-right: 10px; }

.form-group {
  margin-bottom: 1.3rem; }
  .form-group label {
    font-weight: 600; }

.form-control.flatpickr-input[readonly], .flatpickr-input.custom-select[readonly],
.flatpickr-input.dataTable-selector[readonly],
.flatpickr-input.dataTable-input[readonly] {
  background: #ffffff; }

.input-group-text svg {
  width: 18px;
  height: 18px; }

select.form-control, select.custom-select,
select.dataTable-selector,
select.dataTable-input {
  appearance: none;
  background: #ffffff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat right 0.75rem center/18px 25px; }
  select.form-control[data-multiselectsplitter-firstselect-selector], select.custom-select[data-multiselectsplitter-firstselect-selector],
  select.dataTable-selector[data-multiselectsplitter-firstselect-selector],
  select.dataTable-input[data-multiselectsplitter-firstselect-selector], select.form-control[data-multiselectsplitter-secondselect-selector], select.custom-select[data-multiselectsplitter-secondselect-selector],
  select.dataTable-selector[data-multiselectsplitter-secondselect-selector],
  select.dataTable-input[data-multiselectsplitter-secondselect-selector] {
    background: none; }

.bootstrap-select > .dropdown-toggle.btn-light,
.bootstrap-select > .dropdown-toggle.btn-secondary,
.bootstrap-select > .dropdown-toggle.btn-default {
  border-color: #ced4da !important;
  box-shadow: none;
  background: #ffffff !important;
  color: #293240; }
  .bootstrap-select > .dropdown-toggle.btn-light:focus,
  .bootstrap-select > .dropdown-toggle.btn-secondary:focus,
  .bootstrap-select > .dropdown-toggle.btn-default:focus {
    outline: none !important; }

.form-check .form-check-input.input-primary:checked {
  border-color: #51459d;
  background-color: #51459d; }

.form-check .form-check-input.input-light-primary:checked {
  border-color: #dcdaeb;
  background-color: #dcdaeb; }
  .form-check .form-check-input.input-light-primary:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2351459d' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-primary:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2351459d'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-primary:focus[type="checkbox"], .form-check .form-check-input.input-primary:focus[type="radio"], .form-check .form-check-input.input-light-primary:focus[type="checkbox"], .form-check .form-check-input.input-light-primary:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(81, 69, 157, 0.25); }

.form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2351459d'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-secondary:checked {
  border-color: #6c757d;
  background-color: #6c757d; }

.form-check .form-check-input.input-light-secondary:checked {
  border-color: #e2e3e5;
  background-color: #e2e3e5; }
  .form-check .form-check-input.input-light-secondary:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236c757d' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-secondary:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%236c757d'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-secondary:focus[type="checkbox"], .form-check .form-check-input.input-secondary:focus[type="radio"], .form-check .form-check-input.input-light-secondary:focus[type="checkbox"], .form-check .form-check-input.input-light-secondary:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25); }

.form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236c757d'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-success:checked {
  border-color: #6fd943;
  background-color: #6fd943; }

.form-check .form-check-input.input-light-success:checked {
  border-color: #e2f7d9;
  background-color: #e2f7d9; }
  .form-check .form-check-input.input-light-success:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236fd943' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-success:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%236fd943'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-success:focus[type="checkbox"], .form-check .form-check-input.input-success:focus[type="radio"], .form-check .form-check-input.input-light-success:focus[type="checkbox"], .form-check .form-check-input.input-light-success:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(111, 217, 67, 0.25); }

.form-check.form-switch .form-check-input.input-light-success:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236fd943'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-info:checked {
  border-color: #3ec9d6;
  background-color: #3ec9d6; }

.form-check .form-check-input.input-light-info:checked {
  border-color: #d8f4f7;
  background-color: #d8f4f7; }
  .form-check .form-check-input.input-light-info:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233ec9d6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-info:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233ec9d6'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-info:focus[type="checkbox"], .form-check .form-check-input.input-info:focus[type="radio"], .form-check .form-check-input.input-light-info:focus[type="checkbox"], .form-check .form-check-input.input-light-info:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(62, 201, 214, 0.25); }

.form-check.form-switch .form-check-input.input-light-info:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233ec9d6'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-warning:checked {
  border-color: #ffa21d;
  background-color: #ffa21d; }

.form-check .form-check-input.input-light-warning:checked {
  border-color: #ffecd2;
  background-color: #ffecd2; }
  .form-check .form-check-input.input-light-warning:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffa21d' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-warning:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffa21d'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-warning:focus[type="checkbox"], .form-check .form-check-input.input-warning:focus[type="radio"], .form-check .form-check-input.input-light-warning:focus[type="checkbox"], .form-check .form-check-input.input-light-warning:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(255, 162, 29, 0.25); }

.form-check.form-switch .form-check-input.input-light-warning:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffa21d'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-danger:checked {
  border-color: #ff3a6e;
  background-color: #ff3a6e; }

.form-check .form-check-input.input-light-danger:checked {
  border-color: #ffd8e2;
  background-color: #ffd8e2; }
  .form-check .form-check-input.input-light-danger:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ff3a6e' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-danger:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ff3a6e'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-danger:focus[type="checkbox"], .form-check .form-check-input.input-danger:focus[type="radio"], .form-check .form-check-input.input-light-danger:focus[type="checkbox"], .form-check .form-check-input.input-light-danger:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(255, 58, 110, 0.25); }

.form-check.form-switch .form-check-input.input-light-danger:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff3a6e'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-light:checked {
  border-color: #f8f9fa;
  background-color: #f8f9fa; }

.form-check .form-check-input.input-light-light:checked {
  border-color: #fefefe;
  background-color: #fefefe; }
  .form-check .form-check-input.input-light-light:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-light:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f8f9fa'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-light:focus[type="checkbox"], .form-check .form-check-input.input-light:focus[type="radio"], .form-check .form-check-input.input-light-light:focus[type="checkbox"], .form-check .form-check-input.input-light-light:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.25); }

.form-check.form-switch .form-check-input.input-light-light:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f8f9fa'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-dark:checked {
  border-color: #1c232f;
  background-color: #1c232f; }

.form-check .form-check-input.input-light-dark:checked {
  border-color: #d2d3d5;
  background-color: #d2d3d5; }
  .form-check .form-check-input.input-light-dark:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%231c232f' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-dark:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231c232f'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-dark:focus[type="checkbox"], .form-check .form-check-input.input-dark:focus[type="radio"], .form-check .form-check-input.input-light-dark:focus[type="checkbox"], .form-check .form-check-input.input-light-dark:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(28, 35, 47, 0.25); }

.form-check.form-switch .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231c232f'/%3e%3c/svg%3e"); }

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-right: none; }

.form-v1 .form-label-group {
  position: relative;
  margin-bottom: 1rem; }

.form-v1 .form-label-group > input,
.form-v1 .form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem; }

.form-v1 .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  color: #495057;
  pointer-events: none;
  cursor: text;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out; }

.form-v1 .form-label-group input::-webkit-input-placeholder {
  color: transparent; }

.form-v1 .form-label-group input::-ms-input-placeholder {
  color: transparent; }

.form-v1 .form-label-group input::-moz-placeholder {
  color: transparent; }

.form-v1 .form-label-group input::placeholder {
  color: transparent; }

.form-v1 .form-label-group input:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem; }

.form-v1 .form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem; }

.form-v1 .form-label-group input:not(:-moz-placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777; }

.form-v1 .form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777; }

.datepicker {
  width: 265px;
  padding: 10px; }
  .datepicker.dropdown-menu {
    padding: 10px; }
  .datepicker.datepicker-orient-top {
    margin-top: 8px; }
  .datepicker table {
    width: 100%; }
  .datepicker td,
  .datepicker th {
    font-weight: regular;
    width: 35px;
    height: 35px; }
  .datepicker thead th {
    color: #060606; }
    .datepicker thead th.datepicker-switch, .datepicker thead th.next, .datepicker thead th.prev {
      font-weight: 500;
      color: #293240; }
      .datepicker thead th.datepicker-switch i, .datepicker thead th.next i, .datepicker thead th.prev i {
        font-size: 1.2rem;
        color: #293240; }
        .datepicker thead th.datepicker-switch i:before, .datepicker thead th.next i:before, .datepicker thead th.prev i:before {
          line-height: 0;
          vertical-align: middle; }
      .datepicker thead th.datepicker-switch:hover, .datepicker thead th.next:hover, .datepicker thead th.prev:hover {
        background: #f8f9fd !important; }
    .datepicker thead th.dow {
      color: #293240;
      font-weight: 500; }
  .datepicker tbody tr > td.day {
    color: #293240; }
    .datepicker tbody tr > td.day:hover {
      background: #f8f9fd;
      color: #293240; }
    .datepicker tbody tr > td.day.old {
      color: #293240; }
    .datepicker tbody tr > td.day.new {
      color: #293240; }
    .datepicker tbody tr > td.day.active, .datepicker tbody tr > td.day.active:hover, .datepicker tbody tr > td.day.selected, .datepicker tbody tr > td.day.selected:hover {
      background: #51459d;
      color: #fff; }
    .datepicker tbody tr > td.day.today {
      position: relative;
      background: #51459d !important;
      color: #fff !important; }
      .datepicker tbody tr > td.day.today:before {
        content: "";
        display: inline-block;
        border: solid transparent;
        border-width: 0 0 7px 7px;
        border-bottom-color: #ffffff;
        border-top-color: #293240;
        position: absolute;
        bottom: 4px;
        right: 4px; }
    .datepicker tbody tr > td.day.range {
      background: #f8f9fd; }
  .datepicker tbody tr > td span.hour,
  .datepicker tbody tr > td span.minute,
  .datepicker tbody tr > td span.month,
  .datepicker tbody tr > td span.year {
    color: #293240; }
    .datepicker tbody tr > td span.hour:hover,
    .datepicker tbody tr > td span.minute:hover,
    .datepicker tbody tr > td span.month:hover,
    .datepicker tbody tr > td span.year:hover {
      background: #f8f9fd; }
    .datepicker tbody tr > td span.hour.active, .datepicker tbody tr > td span.hour.active.focused:hover, .datepicker tbody tr > td span.hour.active:hover, .datepicker tbody tr > td span.hour.focused, .datepicker tbody tr > td span.hour.focused:hover,
    .datepicker tbody tr > td span.minute.active,
    .datepicker tbody tr > td span.minute.active.focused:hover,
    .datepicker tbody tr > td span.minute.active:hover,
    .datepicker tbody tr > td span.minute.focused,
    .datepicker tbody tr > td span.minute.focused:hover,
    .datepicker tbody tr > td span.month.active,
    .datepicker tbody tr > td span.month.active.focused:hover,
    .datepicker tbody tr > td span.month.active:hover,
    .datepicker tbody tr > td span.month.focused,
    .datepicker tbody tr > td span.month.focused:hover,
    .datepicker tbody tr > td span.year.active,
    .datepicker tbody tr > td span.year.active.focused:hover,
    .datepicker tbody tr > td span.year.active:hover,
    .datepicker tbody tr > td span.year.focused,
    .datepicker tbody tr > td span.year.focused:hover {
      background: #51459d;
      color: #fff; }
  .datepicker tfoot tr > th.clear, .datepicker tfoot tr > th.today {
    font-weight: 500; }
    .datepicker tfoot tr > th.clear:hover, .datepicker tfoot tr > th.today:hover {
      background: #f8f9fd; }
  .datepicker.datepicker-inline {
    border: 1px solid #dddada; }

.bootstrap-timepicker-widget {
  left: auto;
  width: 175px; }
  .bootstrap-timepicker-widget .bootstrap-timepicker-hour,
  .bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
  .bootstrap-timepicker-widget .bootstrap-timepicker-minute,
  .bootstrap-timepicker-widget .bootstrap-timepicker-second {
    border: none;
    box-shadow: none; }
  .bootstrap-timepicker-widget table td > a {
    border: 0; }
    .bootstrap-timepicker-widget table td > a i {
      font-size: 1.1rem; }

.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  color: #060606; }

.bootstrap-timepicker-widget table td > a:hover {
  background: #f8f9fd; }

.tagify__tag {
  --tag-bg: #f8f9fd;
  --tag-hover: rgba(81, 69, 157, 0.3);
  --tag-remove-bg: rgba(255, 58, 110, 0.3);
  --tag-remove-btn-bg--hover: #ff3a6e; }
  .tagify__tag.tagify__tag--primary {
    --tag-bg: rgba(81, 69, 157, 0.2); }
    .tagify__tag.tagify__tag--primary div .tagify__tag-text {
      color: #51459d; }
  .tagify__tag.tagify__tag--secondary {
    --tag-bg: rgba(108, 117, 125, 0.2); }
    .tagify__tag.tagify__tag--secondary div .tagify__tag-text {
      color: #6c757d; }
  .tagify__tag.tagify__tag--success {
    --tag-bg: rgba(111, 217, 67, 0.2); }
    .tagify__tag.tagify__tag--success div .tagify__tag-text {
      color: #6fd943; }
  .tagify__tag.tagify__tag--info {
    --tag-bg: rgba(62, 201, 214, 0.2); }
    .tagify__tag.tagify__tag--info div .tagify__tag-text {
      color: #3ec9d6; }
  .tagify__tag.tagify__tag--warning {
    --tag-bg: rgba(255, 162, 29, 0.2); }
    .tagify__tag.tagify__tag--warning div .tagify__tag-text {
      color: #ffa21d; }
  .tagify__tag.tagify__tag--danger {
    --tag-bg: rgba(255, 58, 110, 0.2); }
    .tagify__tag.tagify__tag--danger div .tagify__tag-text {
      color: #ff3a6e; }
  .tagify__tag.tagify__tag--light {
    --tag-bg: rgba(248, 249, 250, 0.2); }
    .tagify__tag.tagify__tag--light div .tagify__tag-text {
      color: #f8f9fa; }
  .tagify__tag.tagify__tag--dark {
    --tag-bg: rgba(28, 35, 47, 0.2); }
    .tagify__tag.tagify__tag--dark div .tagify__tag-text {
      color: #1c232f; }

.bootstrap-switch {
  border-color: #dddada; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
    background: #f8f9fd; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
    color: #fff;
    background: #51459d; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-secondary,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-secondary {
    color: #fff;
    background: #6c757d; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
    color: #fff;
    background: #6fd943; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
    color: #fff;
    background: #3ec9d6; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
    color: #fff;
    background: #ffa21d; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
    color: #fff;
    background: #ff3a6e; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-light,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-light {
    color: #fff;
    background: #f8f9fa; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-dark,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-dark {
    color: #fff;
    background: #1c232f; }

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn[class*="btn-"]:not(.btn-light) {
  color: #fff; }

.typeahead .twitter-typeahead {
  display: block !important; }

.scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto; }

.tt-menu {
  padding: 10px 0;
  font-size: 0.875rem;
  background: #fff;
  min-width: 10rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px; }
  .tt-menu .tt-suggestion {
    padding: 4px 20px;
    border-radius: 2px; }
    .tt-menu .tt-suggestion.active, .tt-menu .tt-suggestion:active, .tt-menu .tt-suggestion:focus, .tt-menu .tt-suggestion:hover {
      background: rgba(81, 69, 157, 0.03);
      color: #51459d; }

.sticky-action .card-header {
  background: #fff;
  z-index: 1; }

.sticky-action.sticky .card-header {
  top: 0;
  position: fixed;
  box-shadow: 0 1px 15px 1px rgba(57, 82, 107, 0.15);
  left: calc(250px + 30px);
  right: 30px; }
  @media (max-width: 1024px) {
    .sticky-action.sticky .card-header {
      top: calc(60px + 10px); } }

.dropzone {
  min-height: auto; }

.dropzone {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #dddada;
  border-radius: 10px; }
  .dropzone .dropzone-msg-title {
    color: #293240;
    margin: 0 0 5px;
    padding: 0;
    font-weight: 500;
    font-size: 1.2rem; }
  .dropzone .dropzone-msg-desc {
    color: #293240;
    font-weight: 400;
    font-size: 1rem; }
  .dropzone .dz-preview .dz-image {
    border-radius: 10px; }
  .dropzone.dropzone-primary {
    border-color: #51459d; }
  .dropzone.dropzone-secondary {
    border-color: #6c757d; }
  .dropzone.dropzone-success {
    border-color: #6fd943; }
  .dropzone.dropzone-info {
    border-color: #3ec9d6; }
  .dropzone.dropzone-warning {
    border-color: #ffa21d; }
  .dropzone.dropzone-danger {
    border-color: #ff3a6e; }
  .dropzone.dropzone-light {
    border-color: #f8f9fa; }
  .dropzone.dropzone-dark {
    border-color: #1c232f; }

.dz-started .dropzone-msg {
  display: none; }

.dropzone-multi {
  border: 0;
  padding: 0; }
  .dropzone-multi .dz-message {
    display: none; }
  .dropzone-multi .dropzone-panel .dropzone-remove-all,
  .dropzone-multi .dropzone-panel .dropzone-upload {
    display: none; }
  .dropzone-multi .dropzone-item {
    background: #f8f9fd;
    border-radius: 10px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px; }
    .dropzone-multi .dropzone-item .dropzone-progress {
      width: 20%; }
      .dropzone-multi .dropzone-item .dropzone-progress .progress {
        height: 0.5rem;
        transition: all 0.2s ease-in-out; }
    .dropzone-multi .dropzone-item .dropzone-file .dropzone-filename {
      font-size: 0.9rem;
      font-weight: 500;
      color: #293240;
      text-overflow: ellipsis;
      margin-right: 0.5rem; }
      .dropzone-multi .dropzone-item .dropzone-file .dropzone-filename b {
        font-size: 0.9rem;
        font-weight: 500;
        color: #293240; }
    .dropzone-multi .dropzone-item .dropzone-file .dropzone-error {
      margin-top: 0.25rem;
      font-size: 0.9rem;
      font-weight: 400;
      color: #ff3a6e;
      text-overflow: ellipsis; }
    .dropzone-multi .dropzone-item .dropzone-toolbar {
      margin-left: 1rem;
      display: flex;
      flex-wrap: nowrap; }
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel,
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete,
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
        height: 25px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start i {
          font-size: 0.8rem;
          color: #060606; }
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel:hover i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete:hover i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start:hover i {
          color: #51459d; }
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
        transition: all 0.2s ease-in-out; }

.uppy-Dashboard--modal {
  z-index: 1030; }
  .uppy-Dashboard--modal .uppy-Dashboard-overlay {
    z-index: 1030; }
  .uppy-Dashboard--modal .uppy-Dashboard-inner {
    z-index: 1031; }

.uppy-DragDrop--is-dragdrop-supported {
  border-color: #f8f9fd; }

.dash-uppy-thumbnail-container .dash-uppy-thumbnail img {
  width: 100px; }

.slider-selection {
  box-shadow: none; }

.dash-toggle-noUiSlider {
  height: 50px; }

.dash-toggle-noUiSlider.off .noUi-handle {
  border-color: #ff3a6e;
  background: #ff3a6e;
  box-shadow: none; }

.note-editor.card .card-header {
  padding: 0 5px 5px;
  border-bottom-color: #a9a9a9; }

#cke5-inline-demo .ck-content {
  margin-bottom: 1rem;
  padding: 2.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15); }
  #cke5-inline-demo .ck-content h3, #cke5-inline-demo .ck-content .h3 {
    margin-top: 0; }

#cke5-inline-demo header.ck-content {
  text-align: center; }
  #cke5-inline-demo header.ck-content h2:first-of-type, #cke5-inline-demo header.ck-content .h2:first-of-type {
    margin-top: 0; }
  #cke5-inline-demo header.ck-content h2 + h3, #cke5-inline-demo header.ck-content .h2 + h3, #cke5-inline-demo header.ck-content h2 + .h3, #cke5-inline-demo header.ck-content .h2 + .h3 {
    margin-top: 0;
    color: #293240;
    font-weight: 600; }

#cke5-inline-demo .demo-row {
  width: 100%;
  display: flex; }
  #cke5-inline-demo .demo-row .demo-row__half {
    width: 50%; }
    #cke5-inline-demo .demo-row .demo-row__half:first-of-type {
      padding-right: 0.5rem; }
    #cke5-inline-demo .demo-row .demo-row__half:last-of-type {
      padding-left: 0.5rem; }
  #cke5-inline-demo .demo-row p {
    margin-bottom: 0; }
  #cke5-inline-demo .demo-row h3, #cke5-inline-demo .demo-row .h3 {
    margin: 0;
    font-weight: 600; }

.switch-demo .custom-switch-v1 {
  margin-bottom: 4px; }

.custom-switch-v1.form-switch {
  padding-left: 2.9em; }
  .custom-switch-v1.form-switch .form-check-input {
    height: 20px;
    width: 35px;
    margin-left: -2.9em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); }
    .custom-switch-v1.form-switch .form-check-input[class*="input-light-"] {
      border: none; }
    .custom-switch-v1.form-switch .form-check-input:focus {
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.25); }
    .custom-switch-v1.form-switch .form-check-input:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .form-check-input.input-light-primary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%2351459d'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .form-check-input.input-grd-primary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"), linear-gradient(141.55deg, #51459d, #9d4591) !important;
    border-color: #51459d !important; }
  .custom-switch-v1.form-switch .form-check-input.input-light-secondary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%236c757d'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .form-check-input.input-grd-secondary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"), linear-gradient(141.55deg, #6c757d, #746c7d) !important;
    border-color: #6c757d !important; }
  .custom-switch-v1.form-switch .form-check-input.input-light-success:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%236fd943'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .form-check-input.input-grd-success:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"), linear-gradient(141.55deg, #6fd943, #43d9ad) !important;
    border-color: #6fd943 !important; }
  .custom-switch-v1.form-switch .form-check-input.input-light-info:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%233ec9d6'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .form-check-input.input-grd-info:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"), linear-gradient(141.55deg, #3ec9d6, #4b3ed6) !important;
    border-color: #3ec9d6 !important; }
  .custom-switch-v1.form-switch .form-check-input.input-light-warning:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffa21d'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .form-check-input.input-grd-warning:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"), linear-gradient(141.55deg, #ffa21d, #7aff1d) !important;
    border-color: #ffa21d !important; }
  .custom-switch-v1.form-switch .form-check-input.input-light-danger:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ff3a6e'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .form-check-input.input-grd-danger:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"), linear-gradient(141.55deg, #ff3a6e, #ffcb3a) !important;
    border-color: #ff3a6e !important; }
  .custom-switch-v1.form-switch .form-check-input.input-light-light:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23f8f9fa'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .form-check-input.input-grd-light:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"), linear-gradient(141.55deg, #f8f9fa, #f9f8fa) !important;
    border-color: #f8f9fa !important; }
  .custom-switch-v1.form-switch .form-check-input.input-light-dark:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%231c232f'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .form-check-input.input-grd-dark:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"), linear-gradient(141.55deg, #1c232f, #281c2f) !important;
    border-color: #1c232f !important; }

.custom-switch-v1 .custom-control-label::before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 0.8rem;
  top: 0;
  left: -2.55rem; }

.custom-switch-v1 .custom-control-label::after {
  top: calc(0.15625rem - 2px);
  left: calc(-2.25rem - 4px);
  height: 19px;
  width: 19px;
  border-radius: 0.7rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1); }

.custom-switch-v1 .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.95rem); }

.row[data-multiselectsplitter-wrapper-selector] select {
  margin-bottom: 8px; }

.choices {
  position: relative;
  margin-bottom: 5px;
  font-size: 16px; }
  .choices:focus {
    outline: none; }
  .choices:last-child {
    margin-bottom: 0; }
  .choices.is-disabled .choices__inner,
  .choices.is-disabled .choices__input {
    background-color: #e9ecef;
    cursor: not-allowed;
    user-select: none; }
  .choices.is-disabled .choices__item {
    cursor: not-allowed; }
  .choices [hidden] {
    display: none !important; }

.choices[data-type*="select-one"] {
  cursor: pointer; }
  .choices[data-type*="select-one"] .choices__inner {
    padding-bottom: 7.5px; }
  .choices[data-type*="select-one"] .choices__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ced4da;
    background-color: #ffffff;
    margin: 0; }
  .choices[data-type*="select-one"] .choices__button {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
    padding: 0;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.5; }
    .choices[data-type*="select-one"] .choices__button:focus, .choices[data-type*="select-one"] .choices__button:hover {
      opacity: 1; }
    .choices[data-type*="select-one"] .choices__button:focus {
      box-shadow: 0 0 0 2px #51459d; }
  .choices[data-type*="select-one"] .choices__item[data-value=""] .choices__button {
    display: none; }
  .choices[data-type*="select-one"]:after {
    content: "";
    height: 0;
    width: 0;
    border-style: solid;
    border-color: #293240 transparent transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none; }
  .choices[data-type*="select-one"].is-open:after {
    border-color: transparent transparent #293240 transparent;
    margin-top: -7.5px; }
  .choices[data-type*="select-one"][dir="rtl"]:after {
    left: 11.5px;
    right: auto; }
  .choices[data-type*="select-one"][dir="rtl"] .choices__button {
    right: auto;
    left: 0;
    margin-left: 25px;
    margin-right: 0; }

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text; }

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin: 0 -4px 0 8px;
  padding-left: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.35);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0; }
  .choices[data-type*="select-multiple"] .choices__button:focus, .choices[data-type*="select-multiple"] .choices__button:hover,
  .choices[data-type*="text"] .choices__button:focus,
  .choices[data-type*="text"] .choices__button:hover {
    opacity: 1; }

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #ffffff;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden; }
  .is-focused .choices__inner,
  .is-open .choices__inner {
    border-width: 2px;
    border-color: #51459d; }
  .is-open .choices__inner {
    border-radius: 6px 6px 0 0; }
  .is-flipped.is-open .choices__inner {
    border-radius: 0 0 6px 6px; }

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%; }
  [dir="rtl"] .choices__list--single {
    padding-right: 4px;
    padding-left: 16px; }
  .choices__list--single .choices__item {
    width: 100%; }

.choices__list--multiple {
  display: inline; }
  .choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #51459d;
    border: 1px solid darkslateblue;
    color: #ffffff;
    word-break: break-all;
    box-sizing: border-box; }
    .choices__list--multiple .choices__item[data-deletable] {
      padding-right: 5px; }
    [dir="rtl"] .choices__list--multiple .choices__item {
      margin-right: 0;
      margin-left: 3.75px; }
    .choices__list--multiple .choices__item.is-highlighted {
      background-color: darkslateblue;
      border: 1px solid #3f357a; }
    .is-disabled .choices__list--multiple .choices__item {
      background-color: #9facb9;
      border: 1px solid #8293a4; }

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility; }
  .choices__list--dropdown.is-active {
    visibility: visible; }
  .is-open .choices__list--dropdown {
    border-color: #a2aeb9; }
  .is-flipped .choices__list--dropdown {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0; }
  .choices__list--dropdown .choices__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position; }
  .choices__list--dropdown .choices__item {
    position: relative;
    padding: 10px;
    font-size: 14px; }
    [dir="rtl"] .choices__list--dropdown .choices__item {
      text-align: right; }
  @media (min-width: 640px) {
    .choices__list--dropdown .choices__item--selectable {
      padding-right: 100px; }
      .choices__list--dropdown .choices__item--selectable:after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
      [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
        text-align: right;
        padding-left: 100px;
        padding-right: 10px; }
        [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
          right: auto;
          left: 10px; } }
  .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: rgba(81, 69, 157, 0.1); }
    .choices__list--dropdown .choices__item--selectable.is-highlighted:after {
      opacity: 0.5; }

.choices__item {
  cursor: default; }

.choices__item--selectable {
  cursor: pointer; }

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5; }

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #ebeef0;
  color: gray; }

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; }
  .choices__button:focus {
    outline: none; }

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #ffffff;
  font-size: 14px;
  margin-bottom: 0px;
  border: 0;
  color: #293240;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px; }
  .choices__input:focus {
    outline: 0; }
  [dir="rtl"] .choices__input {
    padding-right: 2px;
    padding-left: 0; }

.choices__placeholder {
  opacity: 0.5; }

.typeahead {
  position: relative; }
  .typeahead > ul {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.17);
    display: none;
    float: left;
    font-size: 14px;
    left: 0;
    list-style: none;
    margin: 2px 0 0;
    min-width: 170px;
    padding: 5px 0;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: 1000; }
    .typeahead > ul > li > a {
      clear: both;
      color: #333;
      cursor: default;
      display: block;
      font-weight: 400;
      line-height: 1.42857143;
      padding: 3px 20px;
      white-space: nowrap; }
    .typeahead > ul > li.active > a,
    .typeahead > ul > li > a:hover {
      background-color: #3f9bfa;
      color: #ffffff;
      text-decoration: none; }

.validate-me .error {
  border-color: #ff3a6e; }

.error-message {
  color: #ff3a6e; }

.btn {
  font-size: 14px; }
  .btn i {
    font-size: 18px; }
  .btn svg {
    width: 18px;
    height: 18px; }
  .btn[class*="btn-light-"] {
    box-shadow: none; }
  .btn[class*="btn-outline-"]:not(:hover) {
    box-shadow: none; }
  .btn.btn-shadow {
    box-shadow: 0 6px 7px -1px rgba(80, 86, 175, 0.3); }
  .btn.btn-sm i, .btn-group-sm > .btn i {
    font-size: 14px; }
  .btn[class*="btn-gradient-"] {
    border: none !important; }

.btn-light-primary {
  background: #dcdaeb;
  color: #51459d;
  border-color: #dcdaeb; }
  .btn-light-primary:hover {
    background: #51459d;
    color: #fff;
    border-color: #51459d; }
  .btn-light-primary.focus, .btn-light-primary:focus {
    background: #51459d;
    color: #fff;
    border-color: #51459d; }
  .btn-light-primary:not(:disabled):not(.disabled).active, .btn-light-primary:not(:disabled):not(.disabled):active,
  .show > .btn-light-primary.dropdown-toggle {
    background: #51459d;
    color: #fff;
    border-color: #51459d; }

.btn-check:active + .btn-light-primary,
.btn-check:checked + .btn-light-primary {
  background: #51459d;
  color: #fff;
  border-color: #51459d; }

.btn-light-secondary {
  background: #e2e3e5;
  color: #6c757d;
  border-color: #e2e3e5; }
  .btn-light-secondary:hover {
    background: #6c757d;
    color: #fff;
    border-color: #6c757d; }
  .btn-light-secondary.focus, .btn-light-secondary:focus {
    background: #6c757d;
    color: #fff;
    border-color: #6c757d; }
  .btn-light-secondary:not(:disabled):not(.disabled).active, .btn-light-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-light-secondary.dropdown-toggle {
    background: #6c757d;
    color: #fff;
    border-color: #6c757d; }

.btn-check:active + .btn-light-secondary,
.btn-check:checked + .btn-light-secondary {
  background: #6c757d;
  color: #fff;
  border-color: #6c757d; }

.btn-light-success {
  background: #e2f7d9;
  color: #6fd943;
  border-color: #e2f7d9; }
  .btn-light-success:hover {
    background: #6fd943;
    color: #fff;
    border-color: #6fd943; }
  .btn-light-success.focus, .btn-light-success:focus {
    background: #6fd943;
    color: #fff;
    border-color: #6fd943; }
  .btn-light-success:not(:disabled):not(.disabled).active, .btn-light-success:not(:disabled):not(.disabled):active,
  .show > .btn-light-success.dropdown-toggle {
    background: #6fd943;
    color: #fff;
    border-color: #6fd943; }

.btn-check:active + .btn-light-success,
.btn-check:checked + .btn-light-success {
  background: #6fd943;
  color: #fff;
  border-color: #6fd943; }

.btn-light-info {
  background: #d8f4f7;
  color: #3ec9d6;
  border-color: #d8f4f7; }
  .btn-light-info:hover {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6; }
  .btn-light-info.focus, .btn-light-info:focus {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6; }
  .btn-light-info:not(:disabled):not(.disabled).active, .btn-light-info:not(:disabled):not(.disabled):active,
  .show > .btn-light-info.dropdown-toggle {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6; }

.btn-check:active + .btn-light-info,
.btn-check:checked + .btn-light-info {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6; }

.btn-light-warning {
  background: #ffecd2;
  color: #ffa21d;
  border-color: #ffecd2; }
  .btn-light-warning:hover {
    background: #ffa21d;
    color: #fff;
    border-color: #ffa21d; }
  .btn-light-warning.focus, .btn-light-warning:focus {
    background: #ffa21d;
    color: #fff;
    border-color: #ffa21d; }
  .btn-light-warning:not(:disabled):not(.disabled).active, .btn-light-warning:not(:disabled):not(.disabled):active,
  .show > .btn-light-warning.dropdown-toggle {
    background: #ffa21d;
    color: #fff;
    border-color: #ffa21d; }

.btn-check:active + .btn-light-warning,
.btn-check:checked + .btn-light-warning {
  background: #ffa21d;
  color: #fff;
  border-color: #ffa21d; }

.btn-light-danger {
  background: #ffd8e2;
  color: #ff3a6e;
  border-color: #ffd8e2; }
  .btn-light-danger:hover {
    background: #ff3a6e;
    color: #fff;
    border-color: #ff3a6e; }
  .btn-light-danger.focus, .btn-light-danger:focus {
    background: #ff3a6e;
    color: #fff;
    border-color: #ff3a6e; }
  .btn-light-danger:not(:disabled):not(.disabled).active, .btn-light-danger:not(:disabled):not(.disabled):active,
  .show > .btn-light-danger.dropdown-toggle {
    background: #ff3a6e;
    color: #fff;
    border-color: #ff3a6e; }

.btn-check:active + .btn-light-danger,
.btn-check:checked + .btn-light-danger {
  background: #ff3a6e;
  color: #fff;
  border-color: #ff3a6e; }

.btn-light-light {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe; }
  .btn-light-light:hover {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa; }
  .btn-light-light.focus, .btn-light-light:focus {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa; }
  .btn-light-light:not(:disabled):not(.disabled).active, .btn-light-light:not(:disabled):not(.disabled):active,
  .show > .btn-light-light.dropdown-toggle {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa; }

.btn-check:active + .btn-light-light,
.btn-check:checked + .btn-light-light {
  background: #f8f9fa;
  color: #fff;
  border-color: #f8f9fa; }

.btn-light-dark {
  background: #d2d3d5;
  color: #1c232f;
  border-color: #d2d3d5; }
  .btn-light-dark:hover {
    background: #1c232f;
    color: #fff;
    border-color: #1c232f; }
  .btn-light-dark.focus, .btn-light-dark:focus {
    background: #1c232f;
    color: #fff;
    border-color: #1c232f; }
  .btn-light-dark:not(:disabled):not(.disabled).active, .btn-light-dark:not(:disabled):not(.disabled):active,
  .show > .btn-light-dark.dropdown-toggle {
    background: #1c232f;
    color: #fff;
    border-color: #1c232f; }

.btn-check:active + .btn-light-dark,
.btn-check:checked + .btn-light-dark {
  background: #1c232f;
  color: #fff;
  border-color: #1c232f; }

.btn-gradient-primary {
  background: linear-gradient(141.55deg, #51459d, #9a459d);
  color: #ffffff; }
  .btn-gradient-primary:hover, .btn-gradient-primary.focus, .btn-gradient-primary:focus {
    background: linear-gradient(141.55deg, #9a459d, #51459d);
    color: #ffffff; }
  .btn-gradient-primary:not(:disabled):not(.disabled).active, .btn-gradient-primary:not(:disabled):not(.disabled):active,
  .show > .btn-gradient-primary.dropdown-toggle {
    background: linear-gradient(141.55deg, #9a459d, #51459d);
    color: #ffffff; }

.btn-check:active + .btn-gradient-primary,
.btn-check:checked + .btn-gradient-primary {
  background: linear-gradient(141.55deg, #9a459d, #51459d);
  color: #ffffff; }

.btn-gradient-secondary {
  background: linear-gradient(141.55deg, #6c757d, #716c7d);
  color: #ffffff; }
  .btn-gradient-secondary:hover, .btn-gradient-secondary.focus, .btn-gradient-secondary:focus {
    background: linear-gradient(141.55deg, #716c7d, #6c757d);
    color: #ffffff; }
  .btn-gradient-secondary:not(:disabled):not(.disabled).active, .btn-gradient-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-gradient-secondary.dropdown-toggle {
    background: linear-gradient(141.55deg, #716c7d, #6c757d);
    color: #ffffff; }

.btn-check:active + .btn-gradient-secondary,
.btn-check:checked + .btn-gradient-secondary {
  background: linear-gradient(141.55deg, #716c7d, #6c757d);
  color: #ffffff; }

.btn-gradient-success {
  background: linear-gradient(141.55deg, #6fd943, #43d994);
  color: #ffffff; }
  .btn-gradient-success:hover, .btn-gradient-success.focus, .btn-gradient-success:focus {
    background: linear-gradient(141.55deg, #43d994, #6fd943);
    color: #ffffff; }
  .btn-gradient-success:not(:disabled):not(.disabled).active, .btn-gradient-success:not(:disabled):not(.disabled):active,
  .show > .btn-gradient-success.dropdown-toggle {
    background: linear-gradient(141.55deg, #43d994, #6fd943);
    color: #ffffff; }

.btn-check:active + .btn-gradient-success,
.btn-check:checked + .btn-gradient-success {
  background: linear-gradient(141.55deg, #43d994, #6fd943);
  color: #ffffff; }

.btn-gradient-info {
  background: linear-gradient(141.55deg, #3ec9d6, #3e4ad6);
  color: #ffffff; }
  .btn-gradient-info:hover, .btn-gradient-info.focus, .btn-gradient-info:focus {
    background: linear-gradient(141.55deg, #3e4ad6, #3ec9d6);
    color: #ffffff; }
  .btn-gradient-info:not(:disabled):not(.disabled).active, .btn-gradient-info:not(:disabled):not(.disabled):active,
  .show > .btn-gradient-info.dropdown-toggle {
    background: linear-gradient(141.55deg, #3e4ad6, #3ec9d6);
    color: #ffffff; }

.btn-check:active + .btn-gradient-info,
.btn-check:checked + .btn-gradient-info {
  background: linear-gradient(141.55deg, #3e4ad6, #3ec9d6);
  color: #ffffff; }

.btn-gradient-warning {
  background: linear-gradient(141.55deg, #ffa21d, #a0ff1d);
  color: #ffffff; }
  .btn-gradient-warning:hover, .btn-gradient-warning.focus, .btn-gradient-warning:focus {
    background: linear-gradient(141.55deg, #a0ff1d, #ffa21d);
    color: #ffffff; }
  .btn-gradient-warning:not(:disabled):not(.disabled).active, .btn-gradient-warning:not(:disabled):not(.disabled):active,
  .show > .btn-gradient-warning.dropdown-toggle {
    background: linear-gradient(141.55deg, #a0ff1d, #ffa21d);
    color: #ffffff; }

.btn-check:active + .btn-gradient-warning,
.btn-check:checked + .btn-gradient-warning {
  background: linear-gradient(141.55deg, #a0ff1d, #ffa21d);
  color: #ffffff; }

.btn-gradient-danger {
  background: linear-gradient(141.55deg, #ff3a6e, #ffaa3a);
  color: #ffffff; }
  .btn-gradient-danger:hover, .btn-gradient-danger.focus, .btn-gradient-danger:focus {
    background: linear-gradient(141.55deg, #ffaa3a, #ff3a6e);
    color: #ffffff; }
  .btn-gradient-danger:not(:disabled):not(.disabled).active, .btn-gradient-danger:not(:disabled):not(.disabled):active,
  .show > .btn-gradient-danger.dropdown-toggle {
    background: linear-gradient(141.55deg, #ffaa3a, #ff3a6e);
    color: #ffffff; }

.btn-check:active + .btn-gradient-danger,
.btn-check:checked + .btn-gradient-danger {
  background: linear-gradient(141.55deg, #ffaa3a, #ff3a6e);
  color: #ffffff; }

.btn-gradient-light {
  background: linear-gradient(141.55deg, #f8f9fa, #f9f8fa);
  color: #212529; }
  .btn-gradient-light:hover, .btn-gradient-light.focus, .btn-gradient-light:focus {
    background: linear-gradient(141.55deg, #f9f8fa, #f8f9fa);
    color: #212529; }
  .btn-gradient-light:not(:disabled):not(.disabled).active, .btn-gradient-light:not(:disabled):not(.disabled):active,
  .show > .btn-gradient-light.dropdown-toggle {
    background: linear-gradient(141.55deg, #f9f8fa, #f8f9fa);
    color: #212529; }

.btn-check:active + .btn-gradient-light,
.btn-check:checked + .btn-gradient-light {
  background: linear-gradient(141.55deg, #f9f8fa, #f8f9fa);
  color: #212529; }

.btn-gradient-dark {
  background: linear-gradient(141.55deg, #1c232f, #251c2f);
  color: #ffffff; }
  .btn-gradient-dark:hover, .btn-gradient-dark.focus, .btn-gradient-dark:focus {
    background: linear-gradient(141.55deg, #251c2f, #1c232f);
    color: #ffffff; }
  .btn-gradient-dark:not(:disabled):not(.disabled).active, .btn-gradient-dark:not(:disabled):not(.disabled):active,
  .show > .btn-gradient-dark.dropdown-toggle {
    background: linear-gradient(141.55deg, #251c2f, #1c232f);
    color: #ffffff; }

.btn-check:active + .btn-gradient-dark,
.btn-check:checked + .btn-gradient-dark {
  background: linear-gradient(141.55deg, #251c2f, #1c232f);
  color: #ffffff; }

.btn-example-page .btn {
  margin: 0.25rem 0.125rem; }

.btn-example-page .btn-group > .btn {
  margin: 0; }

.btn-badge-position {
  margin-bottom: -20px; }
  .btn-badge-position .btn {
    margin-right: 20px;
    margin-bottom: 20px; }

.dropdown-toggle.arrow-none:after {
  display: none; }

.dropdown-menu {
  padding: 15px 0;
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  border: none; }

@keyframes fadein {
  from {
    transform: translate3d(0, 8px, 0);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.dropdown .dropdown-item.active i.material-icons-two-tone, .dropdown .dropdown-item:active i.material-icons-two-tone, .dropdown .dropdown-item:focus i.material-icons-two-tone, .dropdown .dropdown-item:hover i.material-icons-two-tone {
  background-color: #343a40; }

.dropdown .dropdown-item i {
  font-size: 18px;
  margin-right: 10px; }
  .dropdown .dropdown-item i.material-icons-two-tone {
    vertical-align: bottom;
    font-size: 22px;
    background-color: #525b69; }

.dropdown .dropdown-item svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  fill: #f2f2f2; }

.dropdown .dropdown-item .float-right svg {
  width: 14px;
  height: 14px; }

.progress {
  overflow: visible;
  background: #e8ebf8; }
  .progress .progress-bar {
    border-radius: 10px; }
    .progress .progress-bar.bg-primary {
      box-shadow: 0 8px 8px -1px rgba(81, 69, 157, 0.3); }
    .progress .progress-bar.bg-secondary {
      box-shadow: 0 8px 8px -1px rgba(108, 117, 125, 0.3); }
    .progress .progress-bar.bg-success {
      box-shadow: 0 8px 8px -1px rgba(111, 217, 67, 0.3); }
    .progress .progress-bar.bg-info {
      box-shadow: 0 8px 8px -1px rgba(62, 201, 214, 0.3); }
    .progress .progress-bar.bg-warning {
      box-shadow: 0 8px 8px -1px rgba(255, 162, 29, 0.3); }
    .progress .progress-bar.bg-danger {
      box-shadow: 0 8px 8px -1px rgba(255, 58, 110, 0.3); }
    .progress .progress-bar.bg-light {
      box-shadow: 0 8px 8px -1px rgba(248, 249, 250, 0.3); }
    .progress .progress-bar.bg-dark {
      box-shadow: 0 8px 8px -1px rgba(28, 35, 47, 0.3); }

.modal.fade.modal-animate {
  /* Animation Effect 1: Fade in and scale up */
  /* Animation Effect 2: Slide from the right */
  /* Animation Effect 3: Slide from the bottom */
  /* Animation Effect 4: Newspaper */
  /* Animation Effect 5: fall */
  /* Animation Effect 6: side fall */
  /* Animation Effect 7:  slide and stick to top */
  /* Animation Effect 8: 3D flip horizontal */
  /* Animation Effect 9: 3D flip vertical */
  /* Animation Effect 10: 3D sign */
  /* Animation Effect 11: Super scaled */
  /* Animation Effect 12:  Just me */
  /* Animation Effect 13: 3D slit */
  /* Animation Effect 14:  3D Rotate from bottom */
  /* Animation Effect 15:  3D Rotate in from left */
  /* Animation Effect 16:  Blur */
  /* Animation Effect 17:  Slide in from bottom with perspective on container */
  /* Animation Effect 18:  Slide from right with perspective on container */
  /* Animation Effect 19:  Slip in from the top with perspective on container */ }
  .modal.fade.modal-animate .modal-dialog {
    display: flex;
    align-items: center;
    height: calc(100% - 3.5rem); }
  .modal.fade.modal-animate.anim-fade-in-scale .modal-dialog {
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.3s; }
  .modal.fade.modal-animate.anim-fade-in-scale.show .modal-dialog {
    transform: none;
    opacity: 1; }
  .modal.fade.modal-animate.anim-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9); }
  .modal.fade.modal-animate.anim-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1; }
  .modal.fade.modal-animate.anim-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.3s; }
  .modal.fade.modal-animate.anim-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1; }
  .modal.fade.modal-animate.anim-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0;
    transition: all 0.5s; }
  .modal.fade.modal-animate.anim-newspaper.show .modal-dialog {
    transform: none;
    opacity: 1; }
  .modal.fade.modal-animate.anim-fall {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-fall .modal-dialog {
      transform-style: preserve-3d;
      transform: translateZ(600px) rotateX(20deg);
      opacity: 0;
      transition: all 0.5s; }
    .modal.fade.modal-animate.anim-fall.show .modal-dialog {
      transition: all 0.3s ease-in;
      transform: translateZ(0px) rotateX(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-side-fall {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-side-fall .modal-dialog {
      transform-style: preserve-3d;
      transform: translate(30%) translateZ(600px) rotate(10deg);
      opacity: 0;
      transition: all 0.3s ease-in; }
    .modal.fade.modal-animate.anim-side-fall.show .modal-dialog {
      transform: translate(0%) translateZ(0) rotate(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-sticky-up .modal-dialog {
    align-items: flex-start;
    margin-top: 0;
    transform: translateY(-200%);
    transition: all 0.3s;
    opacity: 0; }
  .modal.fade.modal-animate.anim-sticky-up.show .modal-dialog {
    transform: translateY(0%);
    border-radius: 0 0 3px 3px;
    opacity: 1; }
  .modal.fade.modal-animate.anim-3d-flip-horizontal {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-flip-horizontal .modal-dialog {
      transform-style: preserve-3d;
      transform: rotateY(-70deg);
      transition: all 0.3s;
      opacity: 0; }
    .modal.fade.modal-animate.anim-3d-flip-horizontal.show .modal-dialog {
      transform: rotateY(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-3d-flip-vertical {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-flip-vertical .modal-dialog {
      transform-style: preserve-3d;
      transform: rotateX(-70deg);
      transition: all 0.3s;
      opacity: 0; }
    .modal.fade.modal-animate.anim-3d-flip-vertical.show .modal-dialog {
      transform: rotateX(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-3d-sign {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-sign .modal-dialog {
      transform-style: preserve-3d;
      transform: rotateX(-60deg);
      transform-origin: 50% 0;
      opacity: 0;
      transition: all 0.3s; }
    .modal.fade.modal-animate.anim-3d-sign.show .modal-dialog {
      transform: rotateX(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all 0.3s; }
  .modal.fade.modal-animate.anim-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1; }
  .modal.fade.modal-animate.anim-just-me {
    background: #fff; }
    .modal.fade.modal-animate.anim-just-me .modal-dialog {
      transform: scale(0.8);
      opacity: 0;
      transition: all 0.3s;
      background: transparent; }
      .modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content {
        border: none; }
        .modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header {
          background: transparent !important; }
          .modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header .btn-close.btn-close-white {
            filter: none; }
          .modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header .text-white.modal-title {
            color: #525b69 !important; }
    .modal.fade.modal-animate.anim-just-me.show .modal-dialog {
      transform: scale(1);
      opacity: 1; }
  .modal.fade.modal-animate.anim-3d-slit {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-slit .modal-dialog {
      transform-style: preserve-3d;
      transform: translateZ(-3000px) rotateY(90deg);
      opacity: 0; }
    .modal.fade.modal-animate.anim-3d-slit.show .modal-dialog {
      animation: slit 0.7s forwards ease-out; }
  .modal.fade.modal-animate.anim-3d-rotate-bottom {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-rotate-bottom .modal-dialog {
      transform-style: preserve-3d;
      transform: translateY(100%) rotateX(90deg);
      transform-origin: 0 100%;
      opacity: 0;
      transition: all 0.3s ease-out; }
    .modal.fade.modal-animate.anim-3d-rotate-bottom.show .modal-dialog {
      transform: translateY(0%) rotateX(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-3d-rotate-InLeft {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-rotate-InLeft .modal-dialog {
      transform-style: preserve-3d;
      transform: translateZ(100px) translateX(-30%) rotateY(90deg);
      transform-origin: 0 100%;
      opacity: 0;
      transition: all 0.3s; }
    .modal.fade.modal-animate.anim-3d-rotate-InLeft.show .modal-dialog {
      transform: translateZ(0px) translateX(0%) rotateY(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-blur {
    backdrop-filter: blur(8px); }
    .modal.fade.modal-animate.anim-blur .modal-dialog {
      transform: scale(0.7);
      opacity: 0;
      transition: all 0.3s; }
    .modal.fade.modal-animate.anim-blur.show .modal-dialog {
      transform: none;
      opacity: 1; }
  .modal.fade.modal-animate.anim-let-me-in .modal-dialog {
    opacity: 0;
    transform: translateY(200%);
    transition: all 0.3s 0.2s; }
  .modal.fade.modal-animate.anim-let-me-in.show .modal-dialog {
    transform: translateY(0);
    opacity: 1; }
  .modal.fade.modal-animate.anim-make-way .modal-dialog {
    opacity: 0;
    transform: translateX(200%);
    transition: all 0.3s 0.2s; }
  .modal.fade.modal-animate.anim-make-way.show .modal-dialog {
    transform: translateY(0);
    opacity: 1; }
  .modal.fade.modal-animate.anim-slip-from-top .modal-dialog {
    opacity: 0;
    transform: translateY(-200%);
    transition: all 0.5s 0.1s; }
  .modal.fade.modal-animate.anim-slip-from-top.show .modal-dialog {
    transform: translateY(0);
    opacity: 1; }

body {
  /* Animation Effect 17:  Slide in from bottom with perspective on container */
  /* Animation Effect 18:  Slide from right with perspective on container */
  /* Animation Effect 19:  Slip in from the top with perspective on container */ }
  body.anim-let-me-in {
    perspective: 600px;
    overflow: hidden;
    height: 100vh; }
    body.anim-let-me-in .header-user-list,
    body.anim-let-me-in .page-header,
    body.anim-let-me-in .header-chat,
    body.anim-let-me-in .menu-styler {
      display: none; }
    body.anim-let-me-in .app-sidebar,
    body.anim-let-me-in .site-header,
    body.anim-let-me-in .content-container,
    body.anim-let-me-in .modal-backdrop {
      transition: transform 0.3s; }
    body.anim-let-me-in.modal-open .app-sidebar,
    body.anim-let-me-in.modal-open .site-header,
    body.anim-let-me-in.modal-open .content-container,
    body.anim-let-me-in.modal-open .modal-backdrop {
      transform: rotateX(-3deg);
      transform-origin: 50% 0%;
      transform-style: preserve-3d; }
  body.anim-make-way {
    perspective: 600px;
    overflow: hidden;
    height: 100vh; }
    body.anim-make-way .header-user-list,
    body.anim-make-way .page-header,
    body.anim-make-way .header-chat,
    body.anim-make-way .menu-styler {
      display: none; }
    body.anim-make-way .app-sidebar,
    body.anim-make-way .site-header,
    body.anim-make-way .content-container,
    body.anim-make-way .modal-backdrop {
      transition: all 0.5s; }
    body.anim-make-way.modal-open .app-sidebar,
    body.anim-make-way.modal-open .site-header,
    body.anim-make-way.modal-open .content-container,
    body.anim-make-way.modal-open .modal-backdrop {
      transform-style: preserve-3d;
      transform-origin: 0% 50%;
      animation: rotateRightSideFirst 0.5s forwards ease-in; }
  body.anim-slip-from-top {
    perspective: 600px;
    overflow: hidden;
    height: 100vh; }
    body.anim-slip-from-top .header-user-list,
    body.anim-slip-from-top .header-chat,
    body.anim-slip-from-top .page-header,
    body.anim-slip-from-top .menu-styler {
      display: none; }
    body.anim-slip-from-top .app-sidebar,
    body.anim-slip-from-top .site-header,
    body.anim-slip-from-top .content-container,
    body.anim-slip-from-top .modal-backdrop {
      transition: all 0.5s;
      transform-style: preserve-3d;
      transform-origin: 50% 100%;
      animation: OpenTop 1.5s forwards ease-in; }
    body.anim-slip-from-top.modal-open .site-header {
      opacity: 0; }

@keyframes slit {
  50% {
    transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    animation-timing-function: ease-in; }
  100% {
    transform: translateZ(0) rotateY(0deg);
    opacity: 1; } }

@keyframes rotateRightSideFirst {
  50% {
    transform: translateZ(-50px) rotateY(5deg);
    animation-timing-function: ease-out; }
  100% {
    transform: translateZ(-200px); } }

@keyframes OpenTop {
  50% {
    transform: rotateX(10deg);
    animation-timing-function: ease-out; } }

.table td,
.table th {
  border-top: 1px solid #dddada;
  border-bottom: 0;
  white-space: nowrap;
  padding: 0.7rem 0.75rem; }

.table thead th {
  border-bottom: 1px solid #dddada;
  font-size: 13px;
  color: #060606;
  background: #f8f9fd;
  text-transform: uppercase; }

.table tbody + tbody {
  border-top: 2px solid #dddada; }

.table.table-dark thead th {
  background-color: #11171a; }

.table.dataTable[class*="table-"] thead th {
  background: #f8f9fd; }

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #dddada; }

.table > :not(:first-child) {
  border-top: none; }

.table-bordered {
  border: 1px solid #dddada; }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dddada; }

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: rgba(81, 69, 157, 0.03); }

.table-hover tbody tr:hover {
  background-color: rgba(81, 69, 157, 0.03); }

.table .thead-dark th {
  color: #fff;
  background-color: #1c232f;
  border-color: #090b0f; }

.table-dark {
  color: #fff;
  background-color: #1c232f; }
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #090b0f; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: #181e29; }
  .table-dark.table-hover tbody tr:hover {
    background-color: #12171f; }
  .table-dark thead th {
    color: #fff; }

table.dataTable.fixedHeader-floating {
  top: calc(64px + 55px) !important; }
  @media screen and (max-width: 992px) {
    table.dataTable.fixedHeader-floating {
      display: none !important; } }

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important; } }

.footable .pagination > .active > a,
.footable .pagination > .active > span {
  background-color: #51459d;
  border-color: #51459d; }
  .footable .pagination > .active > a:focus, .footable .pagination > .active > a:hover,
  .footable .pagination > .active > span:focus,
  .footable .pagination > .active > span:hover {
    background-color: #51459d;
    border-color: #51459d; }

.footable .pagination > li > a,
.footable .pagination > li > span {
  color: #222; }

.footable-details.table,
.footable.table {
  margin-bottom: 0; }

table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0; }

table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0; }

.table-columned > tbody > tr > td {
  border: 0;
  border-left: 1px solid #dddada; }

.table-columned > tbody > tr > th {
  border: 0; }

.table.table-xl td,
.table.table-xl th {
  padding: 1.25rem 0.8rem; }

.table.table-lg td,
.table.table-lg th {
  padding: 0.9rem 0.8rem; }

.table.table-de td,
.table.table-de th {
  padding: 0.75rem 0.8rem; }

.table.table-sm td,
.table.table-sm th {
  padding: 0.6rem 0.8rem; }

.table.table-xs td,
.table.table-xs th {
  padding: 0.4rem 0.8rem; }

#row-delete .selected,
#row-select .selected {
  background-color: #51459d;
  color: #fff; }

table.dataTable tbody > tr > .selected, table.dataTable tbody > tr.selected {
  background: #51459d; }
  table.dataTable tbody > tr > .selected table.dataTable tbody > tr > .selected td.select-checkbox:after,
  table.dataTable tbody > tr > .selected table.dataTable tbody > tr.selected.select-checkbox:after,
  table.dataTable tbody > tr > .selected th.select-checkbox:after, table.dataTable tbody > tr.selected table.dataTable tbody > tr > .selected td.select-checkbox:after,
  table.dataTable tbody > tr.selected table.dataTable tbody > tr.selected.select-checkbox:after,
  table.dataTable tbody > tr.selected th.select-checkbox:after {
    text-shadow: 1px 1px #e4f2fe, -1px -1px #e4f2fe, 1px -1px #e4f2fe, -1px 1px #e4f2fe; }

div.dataTables_wrapper div.dataTables_length select {
  padding-right: 2rem; }

table.dataTable tbody .selected td.select-checkbox:before,
table.dataTable tbody .selected th.select-checkbox:before {
  border-color: #fff; }

table.dataTable tbody > tr > .selected td.select-checkbox:after,
table.dataTable tbody > tr > .selected th.select-checkbox:after,
table.dataTable tbody > tr.selected td.select-checkbox:after,
table.dataTable tbody > tr.selected th.select-checkbox:after {
  content: '\2714';
  margin-top: -11px;
  margin-left: -4px;
  text-align: center; }

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: #aba4d7;
  color: #51459d;
  border: 2px solid #51459d;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21; }
  div.dt-button-info h2, div.dt-button-info .h2 {
    padding: 0.5em;
    margin: 0;
    font-weight: normal;
    color: #fff;
    border-bottom: 1px solid #51459d;
    background-color: #51459d; }
  div.dt-button-info > div {
    padding: 1em; }

.dt-buttons {
  margin-bottom: 5px; }

table.dataTable tbody td.focus,
table.dataTable tbody th.focus {
  box-shadow: inset 0 0 1px 2px #51459d; }

div.dtk-focus-alt table.dataTable tbody td.focus,
div.dtk-focus-alt table.dataTable tbody th.focus {
  box-shadow: inset 0 0 1px 2px #ffa21d;
  background: rgba(255, 162, 29, 0.15);
  color: #ffa21d; }

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  top: 13px; }

table.dataTable tbody > tr > .selected td.select-checkbox:after,
table.dataTable tbody > tr > .selected th.select-checkbox:after,
table.dataTable tbody > tr.selected td.select-checkbox:after,
table.dataTable tbody > tr.selected th.select-checkbox:after {
  margin-top: -21px;
  margin-left: -7px; }

.slider.slider-horizontal .slider-handle.triangle,
.slider.slider-horizontal .slider-tick.triangle {
  border-bottom-color: #51459d; }

.slider.slider-vertical .slider-handle.triangle,
.slider.slider-vertical .slider-tick.triangle {
  border-left-color: #51459d;
  border-right-color: #51459d; }

.slider.slider-disabled .slider-handle {
  background-image: linear-gradient(to bottom, #dfdfdf 0, #bebebe 100%);
  background-repeat: repeat-x; }

.slider.slider-disabled .slider-track {
  background-image: linear-gradient(to bottom, #f8f9fd 0, #f8f9fd 100%);
  background-repeat: repeat-x; }

.slider-track {
  background-image: linear-gradient(to bottom, #f8f9fd 0, #f8f9fd 100%);
  background-repeat: repeat-x; }

.slider-selection {
  background-image: linear-gradient(to bottom, #9b92cf 0, #9b92cf 100%);
  background-repeat: repeat-x; }
  .slider-selection.tick-slider-selection {
    background-image: linear-gradient(to bottom, #8a81c7 0, #8a81c7 100%);
    background-repeat: repeat-x; }

.slider-handle {
  background-color: #51459d;
  background-image: linear-gradient(to bottom, #51459d 0, #51459d 100%);
  background-repeat: repeat-x; }

.slider-tick {
  background-image: linear-gradient(to bottom, #f9f9f9 0, #f5f5f5 100%);
  background-repeat: repeat-x; }
  .slider-tick.in-selection {
    background-image: linear-gradient(to bottom, #8a81c7 0, #8a81c7 100%);
    background-repeat: repeat-x; }

#ex7-enabled {
  position: relative;
  top: 4px;
  height: 18px;
  width: 18px; }

#RGB {
  height: 10px;
  background: gray; }

#RC .slider-selection {
  background: #FF8282; }

#RC .slider-handle {
  background: red; }

#GC .slider-selection {
  background: #428041; }

#GC .slider-handle {
  background: green; }

#BC .slider-selection {
  background: #8283FF; }

#BC .slider-handle {
  border-bottom-color: blue; }

#B,
#G,
#R {
  width: 300px; }

.slider-handle.custom {
  background: transparent none; }
  .slider-handle.custom::before {
    line-height: 15px;
    font-size: 28px;
    content: '\2605';
    color: #726204; }

#slider12a .slider-track-high,
#slider12c .slider-track-high {
  background: #6fd943; }

#slider12b .slider-track-low {
  background: #ff3a6e; }

#slider12c .slider-track-low {
  background: #ff3a6e; }

#slider12c .slider-selection {
  background: #ffa21d; }

#slider22 .slider-rangeHighlight {
  background: #f70616; }
  #slider22 .slider-rangeHighlight.category1 {
    background: #FF9900; }
  #slider22 .slider-rangeHighlight.category2 {
    background: #99CC00; }

.vtree a.vtree-leaf-label {
  color: #293240; }
  .vtree a.vtree-leaf-label:hover, .vtree a.vtree-leaf-label:focus, .vtree a.vtree-leaf-label:active {
    color: #51459d; }

.daterangepicker {
  font-family: "Open Sans", sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 25px rgba(81, 69, 157, 0.15);
  background-color: #ffffff; }
  .daterangepicker .calendar-table {
    border: 1px solid #ffffff;
    background-color: #ffffff; }
    .daterangepicker .calendar-table td,
    .daterangepicker .calendar-table th {
      color: #6c757d; }
    .daterangepicker .calendar-table .next span,
    .daterangepicker .calendar-table .prev span {
      border-color: #adb5bd; }
  .daterangepicker td.in-range {
    background-color: #a8a0d5;
    color: #495057; }
  .daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
    background-color: #ffffff;
    color: #293240;
    opacity: 0.5; }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #51459d;
    color: #fff; }
  .daterangepicker td.available:hover,
  .daterangepicker th.available:hover {
    background-color: #51459d;
    color: #fff; }
    .daterangepicker td.available:hover > span,
    .daterangepicker th.available:hover > span {
      border-color: #fff; }
  .daterangepicker:after {
    border-bottom: 6px solid #ffffff; }
  .daterangepicker:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.15); }
  .daterangepicker .ranges ul {
    padding-top: 15px;
    padding-bottom: 15px; }
    @media (min-width: 564px) {
      .daterangepicker .ranges ul {
        width: 160px; } }
    .daterangepicker .ranges ul li {
      margin: 5px 10px;
      transition: all 0.2s ease-in-out; }
      .daterangepicker .ranges ul li.active {
        background-color: #51459d; }
      .daterangepicker .ranges ul li:hover {
        box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);
        background-color: #51459d;
        color: #fff; }
  .daterangepicker .drp-buttons {
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .daterangepicker.show-ranges .drp-calendar.left {
    border-left: 1px solid rgba(0, 0, 0, 0.15); }

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2); }

.datepicker-view .week {
  color: #51459d; }

.datepicker-cell.selected {
  background-color: #51459d;
  color: #fff; }
  .datepicker-cell.selected:hover {
    background-color: #51459d;
    color: #fff; }

.datepicker-cell.disabled {
  color: #dcdaeb;
  background-color: #f8f9fd; }

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: #dcdaeb;
  color: #51459d;
  border-radius: 0; }
  .datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
    background-color: #dcdaeb;
    color: #51459d; }
  .datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
    background-color: #51459d;
    color: #fff; }

.datepicker-input.in-edit {
  border-color: #51459d; }

.slow .switch-group {
  transition: left 0.7s;
  -webkit-transition: left 0.7s; }

.fast .switch-group {
  transition: left 0.1s;
  -webkit-transition: left 0.1s; }

.quick .switch-group {
  transition: none;
  -webkit-transition: none; }

.table.switch-table th,
.table.switch-table td {
  vertical-align: middle;
  padding: 0.45rem .25rem; }
  .table.switch-table th .btn,
  .table.switch-table td .btn {
    margin-bottom: 0; }

.switch.btn-outline-primary .switch-handle {
  background-color: #51459d !important;
  border-color: #51459d !important; }

.switch.btn-outline-secondary .switch-handle {
  background-color: #6c757d !important;
  border-color: #6c757d !important; }

.switch.btn-outline-success .switch-handle {
  background-color: #6fd943 !important;
  border-color: #6fd943 !important; }

.switch.btn-outline-info .switch-handle {
  background-color: #3ec9d6 !important;
  border-color: #3ec9d6 !important; }

.switch.btn-outline-warning .switch-handle {
  background-color: #ffa21d !important;
  border-color: #ffa21d !important; }

.switch.btn-outline-danger .switch-handle {
  background-color: #ff3a6e !important;
  border-color: #ff3a6e !important; }

.switch.btn-outline-light .switch-handle {
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important; }

.switch.btn-outline-dark .switch-handle {
  background-color: #1c232f !important;
  border-color: #1c232f !important; }

.dataTables_wrapper .dataTables_length {
  padding: 15px; }
  .dataTables_wrapper .dataTables_length select {
    padding: 0.575rem 1rem;
    font-size: 0.875rem; }

.dataTables_wrapper .dataTables_filter {
  padding: 15px; }
  .dataTables_wrapper .dataTables_filter input {
    padding: 0.575rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5; }

.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
  padding: 15px; }

.dataTables_wrapper .row {
  align-items: center; }

.card.table-card .dataTables_wrapper tr:last-child td {
  border-bottom: 1px solid #dddada; }

.user-group img {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 2;
  transition: all 0.1s ease-in-out;
  border: 2px solid #ffffff; }
  .user-group img + img {
    margin-left: -14px; }
  .user-group img:hover {
    z-index: 5; }

.btn-addnew-project {
  border: 1px solid #dddada;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 24px);
  justify-content: center; }
  .btn-addnew-project .proj-add-icon {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px; }

.fc-view {
  margin-top: 30px; }

.none-border .modal-footer {
  border-top: none; }

.fc-toolbar {
  margin: 15px 0 5px; }
  .fc-toolbar h2, .fc-toolbar .h2 {
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-transform: uppercase; }

.fc-day-grid-event .fc-time {
  font-weight: 700; }

.fc-day {
  background: transparent; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0; }

.fc th.fc-widget-header {
  background: #51459d;
  color: #fff;
  border-color: #51459d;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase; }

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: #e9e7e7; }

.fc-button,
.fc-today-button {
  background: #e9e7e7;
  border: none;
  color: #343a40;
  text-shadow: none;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0.375rem 0.75rem;
  padding: 0.375rem 0.75rem !important;
  height: auto !important; }

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem; }

.fc-state-hover {
  background: #e9ecef; }

.fc-state-highlight {
  background: #dee2e6; }

.fc-state-active,
.fc-state-disabled,
.fc-state-down {
  background-color: #51459d;
  color: #ffffff;
  text-shadow: none; }

.fc-cell-overlay {
  background: #dee2e6; }

.fc-unthemed td.fc-today {
  background: #f8f9fa; }

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
  background: #f8f9fa; }

.fc-event,
.fc-event:not([href]) {
  color: #fff;
  border-color: #51459d;
  border-left-width: 4px;
  padding: 5px 10px;
  border-radius: 2px;
  margin-bottom: 5px;
  cursor: move; }
  .fc-event:not([class*="bg-"]),
  .fc-event:not([href]):not([class*="bg-"]) {
    background-color: #51459d; }

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #ffffff; }

.fc-basic-view td.fc-week-number span {
  padding-right: 8px; }

.fc-basic-view td.fc-day-number {
  padding-right: 8px; }

.fc-basic-view .fc-content {
  color: #ffffff; }

.fc-time-grid-event .fc-content {
  color: #ffffff; }

.fc .fc-button-group > *,
.fc-today-button {
  margin-bottom: 4px;
  margin-left: 10px;
  border-radius: 2px;
  font-size: 1rem; }

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #e9e7e7;
  border-radius: 2px;
  margin: 5px;
  font-size: 12px;
  border: 1px solid #e4e2e2; }

.fc-scroller {
  overflow: visible !important;
  height: auto !important; }

@media (max-width: 575.98px) {
  .fc-toolbar .fc-center,
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right {
    float: none;
    display: block;
    clear: both;
    margin: 10px 0; }
  .fc .fc-toolbar > * > * {
    float: none; }
  .fc-today-button {
    display: none; } }

.fc-popover {
  background: #ffffff; }

.fc-h-event .fc-event-main {
  color: inherit;
  color: var(--fc-event-text-color, inherit); }

.fc .fc-timegrid-slot {
  height: 2.5em; }

.fc-timeGridDay-view .fc-v-event .fc-event-main-frame {
  flex-direction: row;
  align-items: center; }
  .fc-timeGridDay-view .fc-v-event .fc-event-main-frame > div + div {
    margin-left: 10px; }

.fc-event,
.fc-event:not([href]) {
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-radius: 3px; }
  .fc-event.event-primary,
  .fc-event:not([href]).event-primary {
    background: #dcdaeb;
    border-color: #51459d;
    color: #51459d; }
    .fc-event.event-primary.fc-v-event .fc-event-main,
    .fc-event:not([href]).event-primary.fc-v-event .fc-event-main {
      color: #51459d; }
    .fc-event.event-primary .fc-daygrid-event-dot,
    .fc-event:not([href]).event-primary .fc-daygrid-event-dot {
      border-color: #51459d; }
  .fc-event.event-secondary,
  .fc-event:not([href]).event-secondary {
    background: #e2e3e5;
    border-color: #6c757d;
    color: #6c757d; }
    .fc-event.event-secondary.fc-v-event .fc-event-main,
    .fc-event:not([href]).event-secondary.fc-v-event .fc-event-main {
      color: #6c757d; }
    .fc-event.event-secondary .fc-daygrid-event-dot,
    .fc-event:not([href]).event-secondary .fc-daygrid-event-dot {
      border-color: #6c757d; }
  .fc-event.event-success,
  .fc-event:not([href]).event-success {
    background: #e2f7d9;
    border-color: #6fd943;
    color: #6fd943; }
    .fc-event.event-success.fc-v-event .fc-event-main,
    .fc-event:not([href]).event-success.fc-v-event .fc-event-main {
      color: #6fd943; }
    .fc-event.event-success .fc-daygrid-event-dot,
    .fc-event:not([href]).event-success .fc-daygrid-event-dot {
      border-color: #6fd943; }
  .fc-event.event-info,
  .fc-event:not([href]).event-info {
    background: #d8f4f7;
    border-color: #3ec9d6;
    color: #3ec9d6; }
    .fc-event.event-info.fc-v-event .fc-event-main,
    .fc-event:not([href]).event-info.fc-v-event .fc-event-main {
      color: #3ec9d6; }
    .fc-event.event-info .fc-daygrid-event-dot,
    .fc-event:not([href]).event-info .fc-daygrid-event-dot {
      border-color: #3ec9d6; }
  .fc-event.event-warning,
  .fc-event:not([href]).event-warning {
    background: #ffecd2;
    border-color: #ffa21d;
    color: #ffa21d; }
    .fc-event.event-warning.fc-v-event .fc-event-main,
    .fc-event:not([href]).event-warning.fc-v-event .fc-event-main {
      color: #ffa21d; }
    .fc-event.event-warning .fc-daygrid-event-dot,
    .fc-event:not([href]).event-warning .fc-daygrid-event-dot {
      border-color: #ffa21d; }
  .fc-event.event-danger,
  .fc-event:not([href]).event-danger {
    background: #ffd8e2;
    border-color: #ff3a6e;
    color: #ff3a6e; }
    .fc-event.event-danger.fc-v-event .fc-event-main,
    .fc-event:not([href]).event-danger.fc-v-event .fc-event-main {
      color: #ff3a6e; }
    .fc-event.event-danger .fc-daygrid-event-dot,
    .fc-event:not([href]).event-danger .fc-daygrid-event-dot {
      border-color: #ff3a6e; }
  .fc-event.event-light,
  .fc-event:not([href]).event-light {
    background: #fefefe;
    border-color: #f8f9fa;
    color: #f8f9fa; }
    .fc-event.event-light.fc-v-event .fc-event-main,
    .fc-event:not([href]).event-light.fc-v-event .fc-event-main {
      color: #f8f9fa; }
    .fc-event.event-light .fc-daygrid-event-dot,
    .fc-event:not([href]).event-light .fc-daygrid-event-dot {
      border-color: #f8f9fa; }
  .fc-event.event-dark,
  .fc-event:not([href]).event-dark {
    background: #d2d3d5;
    border-color: #1c232f;
    color: #1c232f; }
    .fc-event.event-dark.fc-v-event .fc-event-main,
    .fc-event:not([href]).event-dark.fc-v-event .fc-event-main {
      color: #1c232f; }
    .fc-event.event-dark .fc-daygrid-event-dot,
    .fc-event:not([href]).event-dark .fc-daygrid-event-dot {
      border-color: #1c232f; }

.product-slider .carousel-indicators {
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px; }
  .product-slider .carousel-indicators [data-bs-target] {
    margin-left: 8px;
    margin-right: 8px; }
  .product-slider .carousel-indicators img {
    width: 100%; }

.product-slider img {
  border-radius: 15px; }

.progress {
  height: 6px; }

.authentication-signin .auth-content {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }
  .authentication-signin .auth-content > .row {
    width: 100%; }

@media only screen and (max-width: 767px) {
  .authentication-signin .auth-content {
    padding: 30px 10px; } }

text {
  fill: #efefef; }

.apexcharts-legend-text {
  color: #293240 !important; }

.apexcharts-xaxis line {
  stroke: #dddada !important; }

.apexcharts-gridline,
.apexcharts-xaxis-tick {
  stroke: #dddada; }

.site-header .app-head-link.active, .site-header .app-head-link:active, .site-header .app-head-link:focus, .site-header .app-head-link:hover {
  background: rgba(255, 255, 255, 0.15);
  color: #fff; }

.card .dropzone {
  background: #f2f2f2; }

.bd-example-modal,
.invoice-total.table {
  background: #f8f9fd; }

.task-board-left .task-right-header-revision,
.task-board-left .task-right-header-status,
.task-board-left .task-right-header-users {
  border-color: #dddada; }

#task-container li,
.fc-button,
.fc-today-button {
  border-color: #dddada;
  background: #f2f2f2;
  color: #293240; }

.fc-state-active,
.fc-state-disabled,
.fc-state-down {
  background: #51459d;
  color: #ffffff; }

.filter-bar .navbar,
.sticky-action .card-header {
  background: #ffffff; }

.border-light {
  border-color: #dddada !important; }

.theme-roller .theme-roller-content {
  color: #293240; }
  .theme-roller .theme-roller-content h6, .theme-roller .theme-roller-content .h6 {
    color: #060606; }
  .theme-roller .theme-roller-content .custom-control-label::before {
    background: #fff;
    border-color: #adb5bd; }
  .theme-roller .theme-roller-content .custom-switch .custom-control-label::after {
    background: #adb5bd; }
  .theme-roller .theme-roller-content .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #51459d;
    background-color: #51459d; }
  .theme-roller .theme-roller-content .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background: #fff; }
  .theme-roller .theme-roller-content .form-check-input:not(:checked) {
    background-color: #fff; }

.card-footer.bg-light {
  background: #f7f7f7 !important; }

.dropdown .dropdown-item svg {
  fill: rgba(155, 158, 162, 0.11); }

.tab-wizard.nav .nav-item .nav-link {
  background: rgba(255, 255, 255, 0.4); }

.tab-wizard.nav .nav-link.active,
.tab-wizard.nav .show > .nav-link {
  background: #ffffff; }

.text-dark {
  color: #d9d9da !important; }

.uppy-Dashboard-inner,
.uppy-DashboardTab,
.uppy-DragDrop-container {
  background: #ffffff;
  border-color: #dddada; }

.tox .tox-tbtn {
  color: #293240; }

.tox .tox-menubar,
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: #ffffff; }

.ql-snow .ql-stroke {
  stroke: #293240; }

.ql-snow .ql-picker {
  color: #293240; }

.ql-container.ql-snow,
.ql-toolbar.ql-snow,
.trumbowyg-box,
.trumbowyg-editor {
  border-color: #dddada; }

.note-toolbar.card-header {
  background: #fff;
  border-bottom-color: #ddd; }

.trumbowyg-button-pane {
  background: #ffffff !important; }

.trumbowyg-button-pane button.trumbowyg-active,
.trumbowyg-button-pane button:not(.trumbowyg-disable):focus,
.trumbowyg-button-pane button:not(.trumbowyg-disable):hover {
  background: #f2f2f2 !important; }

.trumbowyg-box svg,
.trumbowyg-modal svg {
  fill: #293240 !important;
  color: #293240; }

.trumbowyg-button-pane .trumbowyg-button-group::after {
  background: #dddada !important; }

.trumbowyg-button-pane::after {
  background: #dddada !important; }

.trumbowyg-button-pane {
  border-bottom: 1px solid #dddada !important; }

button:focus {
  outline: none; }

.trumbowyg-box,
.trumbowyg-editor {
  border-color: #dddada !important; }

.site-header {
  background: #292a33; }

.badge.bg-light-primary {
  background: rgba(81, 69, 157, 0.2);
  color: #51459d;
  border-color: rgba(81, 69, 157, 0.2); }

.icon-svg-primary {
  fill: rgba(81, 69, 157, 0.2);
  stroke: #51459d; }

.btn-light-primary {
  background: rgba(81, 69, 157, 0.2);
  border-color: rgba(81, 69, 157, 0.2); }

.badge.bg-light-secondary {
  background: rgba(108, 117, 125, 0.2);
  color: #6c757d;
  border-color: rgba(108, 117, 125, 0.2); }

.icon-svg-secondary {
  fill: rgba(108, 117, 125, 0.2);
  stroke: #6c757d; }

.btn-light-secondary {
  background: rgba(108, 117, 125, 0.2);
  border-color: rgba(108, 117, 125, 0.2); }

.badge.bg-light-success {
  background: rgba(111, 217, 67, 0.2);
  color: #6fd943;
  border-color: rgba(111, 217, 67, 0.2); }

.icon-svg-success {
  fill: rgba(111, 217, 67, 0.2);
  stroke: #6fd943; }

.btn-light-success {
  background: rgba(111, 217, 67, 0.2);
  border-color: rgba(111, 217, 67, 0.2); }

.badge.bg-light-info {
  background: rgba(62, 201, 214, 0.2);
  color: #3ec9d6;
  border-color: rgba(62, 201, 214, 0.2); }

.icon-svg-info {
  fill: rgba(62, 201, 214, 0.2);
  stroke: #3ec9d6; }

.btn-light-info {
  background: rgba(62, 201, 214, 0.2);
  border-color: rgba(62, 201, 214, 0.2); }

.badge.bg-light-warning {
  background: rgba(255, 162, 29, 0.2);
  color: #ffa21d;
  border-color: rgba(255, 162, 29, 0.2); }

.icon-svg-warning {
  fill: rgba(255, 162, 29, 0.2);
  stroke: #ffa21d; }

.btn-light-warning {
  background: rgba(255, 162, 29, 0.2);
  border-color: rgba(255, 162, 29, 0.2); }

.badge.bg-light-danger {
  background: rgba(255, 58, 110, 0.2);
  color: #ff3a6e;
  border-color: rgba(255, 58, 110, 0.2); }

.icon-svg-danger {
  fill: rgba(255, 58, 110, 0.2);
  stroke: #ff3a6e; }

.btn-light-danger {
  background: rgba(255, 58, 110, 0.2);
  border-color: rgba(255, 58, 110, 0.2); }

.badge.bg-light-light {
  background: rgba(248, 249, 250, 0.2);
  color: #f8f9fa;
  border-color: rgba(248, 249, 250, 0.2); }

.icon-svg-light {
  fill: rgba(248, 249, 250, 0.2);
  stroke: #f8f9fa; }

.btn-light-light {
  background: rgba(248, 249, 250, 0.2);
  border-color: rgba(248, 249, 250, 0.2); }

.badge.bg-light-dark {
  background: rgba(28, 35, 47, 0.2);
  color: #1c232f;
  border-color: rgba(28, 35, 47, 0.2); }

.icon-svg-dark {
  fill: rgba(28, 35, 47, 0.2);
  stroke: #1c232f; }

.btn-light-dark {
  background: rgba(28, 35, 47, 0.2);
  border-color: rgba(28, 35, 47, 0.2); }

.latest-update-card .card-body .latest-update-box:after {
  background: #f2f2f2; }

*[class*="bg-"] text {
  fill: #fff; }

.close {
  color: #293240;
  text-shadow: none; }
  .close:hover {
    color: #293240; }

body .title h2, body .title .h2 {
  color: #293240; }
  body .title h2 span, body .title .h2 span {
    color: #060606; }

body .theme-alt-bg,
body .price-section::after {
  background: white !important; }

body .faq .accordion .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: 0 6px 30px rgba(6, 6, 6, 0.3) !important; }

select.form-control, select.custom-select,
select.dataTable-selector,
select.dataTable-input {
  background: #ffffff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%239b9ea2' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat right 0.75rem center/18px 25px; }

.datepicker-dropdown:after {
  border-bottom-color: #ffffff; }

.datepicker-dropdown.datepicker-orient-top:after {
  border-top-color: #ffffff; }

.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  background: transparent; }

.bootstrap-timepicker-widget table td a {
  color: #293240; }

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  border-top-color: #ffffff; }

.select2-dropdown {
  background: #ffffff; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  background: #ffffff;
  color: #293240; }

.select2-container--default .select2-selection--multiple {
  background: #ffffff; }

.select2-container--default .select2-selection--single {
  background: #ffffff; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #293240; }

.irs--flat .irs-line,
.irs--flat .irs-max,
.irs--flat .irs-min {
  background: #f2f2f2; }

.noUi-target {
  background: #f2f2f2;
  border-color: #f0f0f0;
  box-shadow: inset 0 1px 1px #ebebeb, 0 3px 6px -5px white; }

.noUi-handle {
  border: 1px solid white;
  background: white;
  box-shadow: inset 0 0 1px white, inset 0 1px 7px white, 0 3px 6px -3px white; }

.noUi-tooltip {
  border-color: #dddada;
  background: #f8f9fd;
  color: #293240; }

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  background: white;
  color: #293240; }

.bootstrap-switch .bootstrap-switch-label {
  background: white; }

.tt-menu {
  background: #ffffff; }

.uppy-DragDrop-arrow {
  fill: #293240; }

.sw-theme-arrows .step-content,
.sw-theme-arrows .sw-toolbar,
.sw-theme-arrows > ul.step-anchor,
.sw-theme-circles .step-content,
.sw-theme-circles .sw-toolbar,
.sw-theme-circles > ul.step-anchor,
.sw-theme-dots .step-content,
.sw-theme-dots .sw-toolbar,
.sw-theme-dots > ul.step-anchor {
  background: transparent; }

td.highlight {
  background: transparent; }

.apexcharts-grid-row,
polygon {
  fill: transparent; }

.apexcharts-svg line {
  stroke: #dddada; }

.apexcharts-svg polygon {
  stroke: white; }

.highcharts-root .highcharts-background {
  fill: transparent; }

.highcharts-root .highcharts-grid-line {
  stroke: rgba(221, 218, 218, 0.5); }

.highcharts-root .highcharts-title {
  color: #293240;
  fill: #293240 !important; }

.highcharts-root .highcharts-legend-item text {
  fill: #293240 !important; }

.user-card .user-about-block img {
  box-shadow: 0 0 0 5px #ffffff; }

.q-view .content {
  background: #ffffff; }

.hover-blk {
  background: whitesmoke; }

.ticket-block .ticket-type-icon {
  color: #293240; }

.ticket-block .col.border-right {
  border-color: #dddada !important; }

.ticket-block .hd-body {
  border-color: #dddada; }

.hd-detail.bg-light,
.topic-name + .bg-light {
  background: #fafafa !important; }

.card.background-pattern .bg-white {
  background: whitesmoke !important; }

.site-header .dash-cart-menu .drp-cart .cart-head,
.site-header .dash-cart-menu .drp-cart .cart-item.table-responsive {
  background: rgba(255, 255, 255, 0.05); }

.user-profile-list table tr td {
  background: #ffffff; }

.user-profile {
  background: #ffffff; }

.dash-container.sidebar-layouts .dash-content .sidebar-links .nav-item .nav-link.active, .dash-container.sidebar-layouts .dash-content .sidebar-links .nav-item .nav-link:hover,
.dash-container.sidebar-layouts .dash-content .sidebar-links .nav-item a.active,
.dash-container.sidebar-layouts .dash-content .sidebar-links .nav-item a:hover,
.dash-container.sidebar-layouts .dash-content .sidebar-links li .nav-link.active,
.dash-container.sidebar-layouts .dash-content .sidebar-links li .nav-link:hover,
.dash-container.sidebar-layouts .dash-content .sidebar-links li a.active,
.dash-container.sidebar-layouts .dash-content .sidebar-links li a:hover {
  background: #f8f9fd;
  color: #293240;
  border-color: #f8f9fd; }

.highlight {
  background: transparent; }
  .highlight pre code {
    color: #293240; }

code[class*="language-"],
pre[class*="language-"] {
  color: #293240; }
  code[class*="language-"] > code,
  pre[class*="language-"] > code {
    background: #ffffff;
    box-shadow: -1px 0 0 0 #358ccb, 0 0 0 1px #dddada; }

.token.entity,
.token.operator,
.token.url,
.token.variable {
  background: transparent; }

.swal2-popup {
  background: white !important; }
  .swal2-popup .swal2-title {
    color: #333e50; }
  .swal2-popup .swal2-content {
    color: #293240; }
  .swal2-popup .swal2-footer {
    border-top-color: #dddada; }

.dash-tab-control.wizard > .steps a {
  background: rgba(255, 255, 255, 0.5); }

.dash-tab-control.wizard > .steps .current a {
  background: #ffffff; }

.dash-tab-control.wizard > .steps .done a:hover {
  background: rgba(255, 255, 255, 0.5); }

body .app-sidebar.app-light-sidebar {
  background: #292a33; }
  body .app-sidebar.app-light-sidebar .nav-link .nav-icon i,
  body .app-sidebar.app-light-sidebar .nav-link .nav-icon svg {
    color: #6c757d; }
  body .app-sidebar.app-light-sidebar .nav-link .nav-icon .material-icons-two-tone {
    background-color: #525b69; }
  body .app-sidebar.app-light-sidebar .nav-link:active, body .app-sidebar.app-light-sidebar .nav-link:focus, body .app-sidebar.app-light-sidebar .nav-link:hover {
    color: #51459d; }
  body .app-sidebar.app-light-sidebar .nav-item.active > .nav-link,
  body .app-sidebar.app-light-sidebar .nav-item:hover > .nav-link {
    color: #51459d; }
    body .app-sidebar.app-light-sidebar .nav-item.active > .nav-link .nav-icon i.material-icons-two-tone,
    body .app-sidebar.app-light-sidebar .nav-item:hover > .nav-link .nav-icon i.material-icons-two-tone {
      background-color: #51459d; }
  body .app-sidebar.app-light-sidebar .app-navbar > .nav-item.active > .nav-link, body .app-sidebar.app-light-sidebar .app-navbar > .nav-item:focus > .nav-link, body .app-sidebar.app-light-sidebar .app-navbar > .nav-item:hover > .nav-link {
    background: #292a33;
    color: #fff; }

body .site-header .drp-company .theme-avtar {
  background-color: #343641 !important; }

body .site-header .app-head-link {
  border-color: #ffffff26;
  color: #ffffff; }
  body .site-header .app-head-link .h-badge.dots {
    border-color: #292a33; }

body .site-header:not(.transprent-bg) {
  background-color: #292a33;
  box-shadow: -6.3821px 8.50947px 48.9295px rgba(0, 0, 0, 0.1); }

@media (max-width: 1024px) {
  body .site-header .header-wrapper,
  body .site-header.mob-header-active .header-wrapper {
    background-color: #292a33; }
  body .site-header .app-mob-drp,
  body .site-header .app-mob-drp.mob-drp-active {
    background-color: #292a33; } }

body .bg-light {
  background: #1c232f !important; }

body .text-dark-black {
  color: #060606; }

body .card .card {
  box-shadow: 0 6px 30px rgba(6, 8, 16, 0.3); }

body .dataTable-pagination a:hover,
body .dataTable-pagination .active a,
body .dataTable-pagination .active a:focus,
body .dataTable-pagination .active a:hover {
  background: white; }

body .CodeMirror,
body .editor-toolbar a {
  color: #808191 !important; }

body .CodeMirror,
body .accordion-button:not(.collapsed),
body .invoice-total {
  background: transparent; }

body .CodeMirror,
body .editor-toolbar {
  border-color: #ffffff1f !important; }

body .choices__inner,
body .choices__input {
  background: transparent; }

body .table thead th {
  background: #30313b !important;
  color: #ffffff; }

body .table thead th,
body .table tbody td {
  border-color: #ffffff1f; }

body .dataTable-sorter::before {
  border-top-color: #ffffff; }

body .dataTable-sorter::after {
  border-bottom-color: #ffffff; }

select.form-control, select.custom-select,
select.dataTable-selector,
select.dataTable-input, select.custom-select, select.dataTable-selector, select.dataTable-input {
  background-color: transparent; }

.card .card-header {
  border-color: #ffffff1f; }

.apexcharts-legend-text {
  color: #67748e !important; }

.app-footer .footer-wrapper {
  background: #292a33; }
